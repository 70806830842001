import {
  Box,
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import FetchWarningBlock from 'component/common/FetchWarningBlcok';
import { numberFormatter } from 'lib/number';
import React, { FC } from 'react';
import { BuyOrSell } from './BuyDialog';

interface BuyDialogStepConfirmProps {
  account: string;
  isin: string;
  bondName: string;
  price: number;
  faceValue: number;
  buyOrSell: BuyOrSell;
  onClose: () => void;
  onConfirm: () => void;
  isMobileWidth: boolean;
  classes: { content: string };
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      fontWeight: 'normal',
    },
    textBold: {
      fontWeight: 'bold',
    },
    action: {
      justifyContent: 'center',
    },
  }),
);

const BuyDialogStepConfirm: FC<BuyDialogStepConfirmProps> = ({
  buyOrSell,
  isin,
  account,
  bondName,
  price,
  faceValue,
  onClose,
  onConfirm,
  isMobileWidth,
  classes: propsClasses,
}) => {
  const classes = useStyles();
  const word = buyOrSell === BuyOrSell.Sell ? '贖回' : '申購';

  return (
    <>
      <DialogContent className={propsClasses.content}>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            帳號：
          </Typography>
          <Typography component="span">{account}</Typography>
        </Box>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            ISIN：
          </Typography>
          <Typography component="span">{isin}</Typography>
        </Box>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            商品名稱：
          </Typography>
          <Typography component="span">{bondName}</Typography>
        </Box>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            {word}面額：
          </Typography>
          <Typography className={classes.textBold} component="span">
            {numberFormatter(faceValue)}
          </Typography>
        </Box>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            {word}價格：
          </Typography>
          <Typography className={classes.textBold} component="span">
            {price}
          </Typography>
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <FetchWarningBlock url="/maintain/warning/buyConfirm" />
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={onClose}
          fullWidth={isMobileWidth}
        >
          取消
        </Button>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          fullWidth={isMobileWidth}
          onClick={onConfirm}
        >
          確認送出
        </Button>
      </DialogActions>
    </>
  );
};

export default BuyDialogStepConfirm;
