import WordingSkeleton from 'component/skeleton/WordingSkeleton';
import React, { FC } from 'react';
import Fetch from './Fetch';
import WarningBlock from './WarningBlock';

interface Props {
  url: string;
}

const FetchWarningBlock: FC<Props> = ({ url }) => (
  <Fetch url={url} loadingComponent={<WordingSkeleton />}>
    <WarningBlock />
  </Fetch>
);

export default FetchWarningBlock;
