import React, { useState } from 'react';
import { Box, Drawer, Paper, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark,
      textAlign: 'center',
      padding: '14px 0',
      fontSize: 15,
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    paper: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark,
      textAlign: 'center',
      padding: '14px 0',
      fontSize: 15,
    },
  }),
);

export function SinopacFooter(props: { divRef: React.RefObject<HTMLDivElement> }) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (isXS) {
      setOpen(true);
    }
  };

  return (
    <>
      <div ref={props.divRef} className={classes.root} onClick={handleClick}>
        {isXS ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography>永豐金證券</Typography>
            <AddIcon />
          </Box>
        ) : (
          <>
            <Typography>永豐金證券股份有限公司台北市重慶南路一段二號7、18、20樓 統一編號:23113343</Typography>
            <Typography>TEL：0800-038-123 | 02-6630-8899 (台股營業日：AM 8：00至PM 9：00)</Typography>
            <Typography>107年金管證總字第0006號（永豐金證券） 105年金管期總字第010號（委任期貨商永豐期貨）</Typography>
          </>
        )}
      </div>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Paper className={classes.paper} square>
          <Box fontSize={13}>
            <Typography variant="inherit" gutterBottom display="block">
              永豐金證券股份有限公司台北市重慶南路一段二號7、18、20樓 統一編號:23113343
            </Typography>
            <Typography variant="inherit" gutterBottom display="block">
              TEL：0800-038-123 | 02-6630-8899 <br />
              (台股營業日：AM 8：00至PM 9：00)
            </Typography>
            <Typography variant="inherit" display="block">
              107年金管證總字第0006號（永豐金證券）
              <br />
              105年金管期總字第010號（委任期貨商永豐期貨）
            </Typography>
          </Box>
        </Paper>
      </Drawer>
    </>
  );
}
