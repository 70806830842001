import STATUS_CODE from 'constant/StatusCode';
import React, { FC } from 'react';
import StatusCodeCheckDialog, {
  StatusCodeCheckDialogWrapperProps,
} from 'site/bond/component/dialog/StatusCodeCheckDialog';

const ElectronicBuyTradingDisableDialog: FC<StatusCodeCheckDialogWrapperProps> = ({ statusCode }) => (
  <StatusCodeCheckDialog
    statusCode={statusCode}
    validStatusCode={STATUS_CODE.ELECTRONIC_TRADING_BUY_DISABLE}
    warning="不可電子交易，請洽所屬營業員/理財專員，將為您服務。"
  />
);

export default ElectronicBuyTradingDisableDialog;
