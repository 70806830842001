import React, { FC, useState } from 'react';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditableDialog from 'site/bond/component/dialog/EditableDialog';
import DeleteWatchListDialogContent from 'site/bond/component/page/WatchList/DeleteWatchListDialogContent';
import { List } from 'immutable';

interface DeleteWatchListButtonProps {
  className: string;
  listId: number;
  isinList: List<string>;
  onUpdate: () => void;
}

export const DeleteWatchListButton: FC<DeleteWatchListButtonProps> = ({ listId, isinList, className, onUpdate }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const handleRenderConfirm = ({ onConfirm, isMobileWidth }) => (
    <DeleteWatchListDialogContent
      onClose={handleClose}
      onConfirm={() => {
        onConfirm(`/watchList/${listId}/delete`, { body: { isinList: isinList.toArray() }, onSuccess: onUpdate });
      }}
      isMobileWidth={isMobileWidth}
    />
  );

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="inherit"
        className={className}
        onClick={handleClick}
        disabled={isinList.size === 0}
      >
        <DeleteIcon />
        刪除勾選商品
      </Button>
      {open && <EditableDialog onClose={handleClose} renderConfirm={handleRenderConfirm} title="觀察名單" />}
    </>
  );
};
