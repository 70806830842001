import { Box, Button, CircularProgress, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Push } from 'connected-react-router';
import { match } from 'react-router';
import STATUS_CODE from 'constant/StatusCode';
import { fetchGet, fetchPost, urlSearchToObject } from 'lib/url';
import React, { FC, useEffect, useRef, useState } from 'react';
import { UserState } from 'site/bond/reducer/userReducer';
import DialogMessage from '../dialog/DialogMessage';
import Knowledge from './Knowledge';
import LoginHeader from './LoginHeader';
import LoginTrustMessageDialog from 'site/bond/component/page/Login/LoginTrustMessageDialog';
import Sensors from 'site/bond/lib/sensor';
import { ca } from 'site/bond/component/WebCA/caComponent';
import WebCACert from '../WebCA/WebCACert';
import ReCAPTCHAV2 from 'react-google-recaptcha-enterprise';
import WebCASign from '../WebCA/WebCASign';
import crypto from 'crypto'


const make_md5 = (content) => {
  let md5 = crypto.createHash('md5');
  let hashContent = md5.update(content).digest('hex');
  return hashContent;
}

const recaptchaRef = React.createRef<ReCAPTCHAV2>();
interface FetchResult {
  result?: { pi: boolean; dvp: boolean; account: string; identification: string; name: string }[];
}

export interface LoginDispatchToProps {
  push: Push;
  login: (args0: UserState) => void;
}
export interface LoginOwnProps {
  match?: match<{ action: string }>;
  // 鎖住登入的詳細內容
  lockContent?: string;
}

enum Message {
  RREffectiveDateExpired = '投資人風險屬性分析問卷調查表(KYC)尚未填寫或已逾期，申購前須完成，請至新理財網 -> 簽署中心->複委託風險屬性分析(KYC)填寫表單，請於下午14:30之前填寫完成，次一日方可生效，謝謝。',
  AgreementUnsigned = '首次交易前須完成債券風險預告書簽署，請洽所屬營業員/理財專員，將為您服務',
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: 318,
      height: 50,
      border: 0,
      borderRadius: 5,
      fontSize: 22,
      fontWeight: 'bold',
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: 'rgb(185, 185, 185)',
      },
    },
    loadingWrapper: {
      marginLeft: '10px !important',
    },

    reCAPTCHAintroduction: {
      fontSize: '12px',
      color: '#fff',
      opacity: 0.9,
      lineHeight: 'initial',
    },
  }),
);

function isStatusCodeOk(statusCode: number) {
  return [
    statusCode === STATUS_CODE.SUCCESS,
    statusCode === STATUS_CODE.F_LOGIN_AGREEMENT_UNSIGNED,
    statusCode === STATUS_CODE.F_LOGIN_RR_EFFECTIVE_DATE_EXPIRED,
    statusCode === STATUS_CODE.F_LOGIN_UNSIGNED_AND_EXPIRED,
  ].some(Boolean);
}

const Login: FC<LoginDispatchToProps & LoginOwnProps> = ({ match, login, push, lockContent = null }) => {
  const reCAPTCHkeyV3 = '6LeCLZsdAAAAAE0C1bkCTsrqiSJYPTq2bvgnj0Z3';
  const reCAPTCHkeyV2 = '6LdWMJsdAAAAAO1na3S5Tjw2sor4wuWPx8lhFaLF';
  const classes = useStyles();
  const [userName, setUserName] = useState('');

  const passwordRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    status: '',
    statusCode: -1,
    actionType: 1,
  });
  const trustAction = match ? match.params.action : '';
  const [openTrustMessageDialog, setOpenTrustMessageDialog] = useState(Boolean(trustAction));
  const [webCAOpen, setWebCAOpen] = useState(false);
  const [birthDayChkOpen, setBirthDayChkOpen] = useState(false);
  const [birthday, setBirthday] = useState('');
  const [birthdayTestTimes, setBirthdayTestTimes] = useState(0);
  const [v2Token, setV2Token] = useState('');
  //預設用V3版
  const [gCAPTCHAV, setGCAPTCHAV] = useState(3);

  function goBackOrPush() {
    const redirect = urlSearchToObject('redirect');
    push(redirect ? decodeURIComponent(redirect) : '/');
  }

  //重設畫面
  const reSetData = async () => {
    setUserName('');
    setBirthday('');
    setBirthdayTestTimes(0);
    setWebCAOpen(false);
    setBirthDayChkOpen(false);
    setDialog({
      open: false,
      actionType: 1,
      status: '',
      statusCode: -1,
    });

    //清空密碼欄
    if (passwordRef.current != null) {
      passwordRef.current.value = '';
    }
    //狀態變成登出
    await fetchGet('/login/signout');
    Sensors.logout();
    login({
      auth: false,
      name: '',
      pi: false,
      dvp: false,
      account: '',
      identification: '',
      permissionList: undefined,
    });
    push('/login');
  };

  //憑證申請成功，先登出
  const OnWebCASuccess = async () => {
    await reSetData();
    //console.log('[OnWebCASuccess]憑證申請成功，請重新登入');
    setDialog({
      open: true,
      actionType: 1,
      status: '憑證申請成功，請重新登入',
      statusCode: 0,
    });
  };

  //驗章程序
  const VerifySignature = (signature?: string, plainText?: string, certSN?: string) => {
    setLoading(true);
    //let signature = userName + Date.now();
    if (signature == undefined || plainText == undefined || certSN == undefined) {
      // eslint-disable-next-line no-alert
      alert('取得簽名錯誤');
      return;
    }
    fetchPost<FetchResult>({
      pathname: '/WebCa/VerifySignature',
      body: {
        signature,
        plainText,
        certSN,
      },
    }).then(v => {
      //handleSingComplete(v.statusCode === STATUS_CODE.SUCCESS, signature);
      setLoading(false);
      //驗章成功
      if (v.statusCode === STATUS_CODE.SUCCESS) {
        //登入成功時，先檢查有無憑證
        goBackOrPush();
        return;
      } else {
        //清除憑證(為了測試暫時不清除)
        ca.clearLS();
        localStorage.clear();
        setDialog({
          open: true,
          actionType: 2,
          statusCode: STATUS_CODE.F_LOGIN_CHECK_CERT_SIGN_FAIL,
          status: '驗章失敗，請重新申請憑證',
        });
      }
    });
  };

  //簽章完成
  const handleSingComplete = (isSuccess: boolean, signature?: string, plainText?: string, certSN?: string) => {
    setLoading(false);
    if (isSuccess && signature && plainText && certSN) {
      //有憑證，取得簽名，開始進行驗章程序
      //goBackOrPush();
      VerifySignature(signature, plainText, certSN);
    } else {
      //清除憑證(為了測試暫時不清除)
      ca.clearLS();
      localStorage.clear();
      //取得簽名失敗
      setDialog({
        open: true,
        actionType: 2,
        statusCode: STATUS_CODE.F_LOGIN_CHECK_CERT_SIGN_FAIL,
        status: '檢查憑證狀態失敗：憑證已失效，請重新申請憑證。',
      });
    }
  };

  const checkCA = () => {
    //檢查憑證
    const { suggestAction, isCertExist } = ca.checkCert(userName);
    if (isCertExist && suggestAction === 'None') {
      //有憑證，取得簽名
      setLoading(true);
      setDialog({
        open: false,
        actionType: 2,
        statusCode: STATUS_CODE.F_LOGIN_CHECK_CERT_SIGN,
        status: '簽章',
      });
    } else {
      setDialog({
        open: true,
        actionType: 2,
        statusCode: STATUS_CODE.F_LOGIN_HAVE_NO_CERT,
        status: '沒有憑證',
      });
    }
  };

  const setLoginInfo = v => {
    if (isStatusCodeOk(v.statusCode) && 'result' in v && v.result && v.result.length === 1) {
      Sensors.signIn(v.result[0].identification);
      login({
        auth: true,
        ...v.result[0],
      });
    }
  };

  const submit = () => {
    if (userName === '' || passwordRef.current === null) return;
    let password = '';
    password = passwordRef.current.value;
    //密碼要加密
    password = make_md5(password);

    window['grecaptcha']['enterprise'].ready(() => {
      window['grecaptcha']['enterprise']
        .execute(reCAPTCHkeyV3, {
          action: 'Login',
        })
        .then(async reCAPTCHAToken => {
          //setV3Token(reCAPTCHAToken);
          let checkCode = '';
          if (gCAPTCHAV == 3) {
            checkCode = reCAPTCHAToken;
          } else if (gCAPTCHAV == 2) {
            checkCode = v2Token;
          }
          const body = {
            username: userName,
            password: password,
            myCheckCode: checkCode,
            reCAPTCHAVer: gCAPTCHAV,
          };

          setLoading(true);
          fetchPost<FetchResult>({
            pathname: '/login',
            body,
          }).then(v => {
            setLoading(false);
            // 設定登入狀態
            setLoginInfo(v);
            if (v.statusCode === STATUS_CODE.SUCCESS) {
              //登入成功時，先檢查有無憑證
              checkCA();
              return;
            }
            //疑似機器人，改用圖形辨識
            if (v.statusCode === STATUS_CODE.F_LOGIN_CHECK_RECAPTCHA_FAIL) {
              setGCAPTCHAV(2);
            }
            setDialog({
              open: true,
              actionType: 1,
              ...v,
            });
            //若recaptchaRef有產生就reset
            if (recaptchaRef.current) recaptchaRef.current.reset();
          });
        });
    });
  };

  const handleBithdayConfirm = () => {
    setLoading(true);
    if (userName === '' || passwordRef.current === null) return;
    const body = {
      username: userName,
      password: passwordRef.current.value,
      birthday: birthday,
    };
    //這邊去驗證生日
    fetchPost<FetchResult>({
      pathname: '/login/VerifyBirthday',
      body,
    }).then(async v => {
      setLoading(false);
      //生日驗證成功
      if (v.statusCode === STATUS_CODE.SUCCESS) {
        goBackOrPush();
        return;
      } else if (v.statusCode === STATUS_CODE.F_LOGIN_CHECK_BIRTHDAT_ERROR) {
        //生日驗證失敗
        let tryTimes = birthdayTestTimes + 1;
        if (tryTimes > 2) {
          //驗證失敗三次，請重新輸入帳密
          await reSetData();
          v.statusCode = STATUS_CODE.F_LOGIN_CHECK_BIRTHDAT_ERROR3;
          setDialog({
            open: true,
            actionType: 1,
            ...v,
          });
          return;
        }
        setBirthdayTestTimes(tryTimes);
      }

      setDialog({
        open: true,
        actionType: 1,
        ...v,
      });
    });
  };

  const handleClose = async () => {
    setDialog({
      open: false,
      actionType: 1,
      status: '',
      statusCode: -1,
    });
    if (isStatusCodeOk(dialog.statusCode)) {
      checkCA();
      return;
    } else if (
      dialog.statusCode == STATUS_CODE.F_LOGIN_HAVE_NO_CERT ||
      dialog.statusCode == STATUS_CODE.F_LOGIN_CHECK_CERT_SIGN_FAIL
    ) {
      //無憑證按下不申請憑證， 20211221直接登出
      //setBirthDayChkOpen(true);
      await reSetData();
    }
  };

  const handleConfirm = () => {
    if (
      dialog.statusCode == STATUS_CODE.F_LOGIN_HAVE_NO_CERT ||
      dialog.statusCode == STATUS_CODE.F_LOGIN_CHECK_CERT_SIGN_FAIL
    ) {
      //無憑證按下要申請憑證
      setWebCAOpen(true);
    } else {
      handleClose();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.currentTarget.value.trim().toUpperCase());
  };
  const handleBirdhtayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let birthday = e.currentTarget.value;
    if (e.currentTarget.value.length >= 8) {
      birthday = birthday.substr(0, 8);
    }
    setBirthday(birthday);
  };

  const handleSingFailed = async () => {
    await reSetData();
  };

  useEffect(() => {
    //監聽視窗事件
    async function onClose(event) {
      event.preventDefault();
      //event.returnValue = false; // Seems require this for Chrome
      await reSetData();
    }
    window.addEventListener('beforeunload', onClose);
    return () => {
      window.removeEventListener('beforeunload', onClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Knowledge>
        <LoginHeader />
        <section className="kv">
          <div className="slogan_wrapper">
            <h1>微利時代投資法則</h1>
            <h2>永豐金海外債券投資</h2>
            <h3>-您的嚮導．您的旅伴．您的貼身管家-</h3>
            <div className="triangle" />
          </div>
          <div className="login_window">
            <input type="text" placeholder="身分證" value={userName} onChange={handleOnChange} required />
            <input
              required
              type="password"
              placeholder="密碼"
              ref={passwordRef}
              onKeyDown={e => {
                if (e.keyCode == 13) submit();
              }}
            />
            {gCAPTCHAV == 2 ? (
              <ReCAPTCHAV2
                ref={recaptchaRef}
                sitekey={reCAPTCHkeyV2}
                onChange={token => {
                  setV2Token(token);
                }}
              />
            ) : (
              <></>
            )}

            <Button color="primary" variant="contained" className={classes.button} onClick={submit} disabled={loading}>
              <span>立即開始</span>
              {loading && (
                <Box display="flex" alignItems="center" className={classes.loadingWrapper}>
                  <CircularProgress color="inherit" size={22} />
                </Box>
              )}
            </Button>
            <p>
              <a href="https://www.sinotrade.com.tw/newweb/Service_ForgetPassword">忘記密碼</a>
            </p>
            <p>
              <a href="https://www.sinotrade.com.tw/EX/Event/ReOpenAccount.aspx?strProd=0052&amp;strWeb=0043">
                不是客戶? 請派專員與我聯絡
              </a>
            </p>
            <p>
              <span className={classes.reCAPTCHAintroduction}>
                此頁面受到 Google reCAPTCHA 保護，
                <br />
                以確認您不是機器人，進一步了解
                <br />
                <a href="https://policies.google.com/privacy">《隱私權聲明》</a>與
                <a href="https://policies.google.com/terms">《服務條款》</a>
              </span>
            </p>
          </div>
        </section>
      </Knowledge>

      <LoginTrustMessageDialog
        action={trustAction}
        open={openTrustMessageDialog}
        onClose={() => setOpenTrustMessageDialog(false)}
      />

      {Boolean(lockContent) && (
        <DialogMessage onClose={() => { }} open hiddenAction>
          <Box minHeight={50}>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: lockContent || '',
              }}
            />
          </Box>
        </DialogMessage>
      )}
      {webCAOpen && <WebCACert identification={userName} onFailed={handleSingFailed} onSuccess={OnWebCASuccess} />}
      {dialog.statusCode == STATUS_CODE.F_LOGIN_CHECK_CERT_SIGN && (
        <WebCASign identification={userName} onComplete={handleSingComplete} />
      )}
      <DialogMessage
        onClose={reSetData}
        onConfirm={handleBithdayConfirm}
        open={birthDayChkOpen}
        title="生日驗證"
        actionType={1}
      >
        <span>為保障您的電子交易安全，請輸入西元出生年月日共8碼進行驗證</span>
        <br />
        <input type="number" placeholder="西元出生年月日" value={birthday} onChange={handleBirdhtayChange} />
        <br />
        <span>(EX:2001年2月3日，請輸入20010203，法人戶請輸入營利事業登記證登記日)</span>
      </DialogMessage>
      <DialogMessage
        onClose={handleClose}
        onConfirm={handleConfirm}
        open={dialog.open}
        title="登入訊息"
        variant={dialog.statusCode === STATUS_CODE.F_LOGIN_ACCOUNT_LOCK ? 'error' : 'warning'}
        actionType={dialog.actionType}
      >
        {(() => {
          switch (dialog.statusCode) {
            case STATUS_CODE.F_LOGIN_FIRST_LOGIN_MUST_CHANGE_PASSWORD:
              return <a href={process.env['REACT_APP_FBF_CHANGE_PASSWORD']}>第一次登入請先至此網站變更密碼</a>;
            case STATUS_CODE.F_LOGIN_ACCOUNT_LOCK:
              return (
                <span>
                  {dialog.status} 任何問題請參考
                  <a href="https://www.sinotrade.com.tw/newweb/Service_ForgetPassword">連結</a>
                </span>
              );
            case STATUS_CODE.F_LOGIN_IS_US:
              return '美國人限制不得交易海外債券';
            case STATUS_CODE.F_LOGIN_IS_DVP:
              return '請洽交易室(02)2388-8700';
            case STATUS_CODE.F_LOGIN_AGREEMENT_UNSIGNED:
              return Message.AgreementUnsigned;
            case STATUS_CODE.F_LOGIN_RR_EFFECTIVE_DATE_EXPIRED:
              return Message.RREffectiveDateExpired;
            case STATUS_CODE.F_LOGIN_UNSIGNED_AND_EXPIRED:
              return (
                <>
                  <Typography gutterBottom>{Message.AgreementUnsigned}</Typography>
                  <Typography gutterBottom>{Message.RREffectiveDateExpired}</Typography>
                </>
              );
            case STATUS_CODE.F_LOGIN_HAVE_NO_CERT:
              return (
                <>
                  <span>為保障您的電子交易安全，登入時將檢查電子憑證，是否載入憑證?</span>
                </>
              );
            case STATUS_CODE.F_LOGIN_CHECK_CERT_SIGN_FAIL:
              return (
                <>
                  <span>檢查憑證狀態失敗：憑證已失效，請重新申請憑證。</span>
                </>
              );
            case STATUS_CODE.F_LOGIN_CHECK_BIRTHDAT_ERROR3:
              return '生日驗證錯誤三次，請重新登入';
            case STATUS_CODE.F_LOGIN_CHECK_RECAPTCHA_FAIL:
              return '請輸入圖形驗證碼';
            default:
              return dialog.status;
          }
        })()}
      </DialogMessage>
    </>
  );
};

export default Login;
