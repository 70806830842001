import { createStyles, Fade, makeStyles } from '@material-ui/core';
import mphoto5 from 'asset/image/bond/knowledge/m-photo-5.png';
import photo5 from 'asset/image/bond/knowledge/photo-5.png';
import React, { FC, useState } from 'react';
import './knowledge.scss';
import QuestionDialog from './QuestionDialog';

const useStyles = makeStyles(() =>
  createStyles({
    pointer: {
      cursor: 'pointer',
    },
  }),
);

const Knowledge: FC = ({ children }) => {
  const classes = useStyles();
  const [tab, settab] = useState(0);
  const [dialog, setDialog] = useState({ open: false, idx: -1 });

  const handleChangeTab = (tab: number) => () => settab(tab);
  const handleOpenDialog = (idx: number) => () => setDialog({ open: true, idx });

  return (
    <div id="knowledge-page">
      <div id="Document">
        <article className="article">
          {children}
          <section className="block" data-block="1">
            <div className="container">
              <div className="col-left">
                <div className="photo"></div>
              </div>
              <div className="col-left">
                <h3 className="style1">
                  <span className="text font-18">商品特色</span>
                  FEATURE
                  <span className="num">01</span>
                </h3>
                <ul className="square">
                  <li>
                    享有固定配息收益
                    <br />
                    投資人持有債券期間，將依商品發行條件定期獲得利息收入
                  </li>
                  <li>
                    到期返還本金面額
                    <br />
                    除永續債或另有約定外，只要發行機構無違約情事發生，到期時發行機構將返還100%面額資金
                  </li>
                  <li>
                    具有潛在資本利得
                    <br />
                    債券價格受到利率、市場環境、信用利差等因素影響而變動，投資人可選擇於活絡的次級市場賣出獲取資本利得收入
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="block" data-block="2">
            <div className="container">
              <div className="flex flex-center">
                <h3 className="style1">
                  <span className="text font-24">債券發行者與投資者關係</span>
                  <span className="num">02</span>
                </h3>
                <div className="photo"></div>
              </div>
            </div>
          </section>
          <section className="block" data-block="3">
            <div className="container">
              <div className="flex flex-row-reverse flex-center">
                <h3 className="style1">
                  <span className="text font-24">投資人持有債券之現金流說明</span>
                  <span className="num">03</span>
                </h3>
                <div className="photo"></div>
              </div>
            </div>
          </section>
          <section className="block" data-block="4">
            <div id="introduce-anchor" />
            <div className="container">
              <h3 className="style1 text-center" id="block1">
                <span className="text font-24">商品介紹</span>Description<span className="num"></span>
              </h3>
              <ul className="flex wrap">
                <li>
                  <i className="icon-1"></i>
                  <div>
                    <h4>什麼是海外債券</h4>
                    <p>
                      債券發行者(外國政府、政府機構、公司或其他的機構組織)為籌措資金而向投資者發行，且將會承諾按約定利率支付利息，並按約定條件償還本金的債務憑證...
                    </p>
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#con"
                      className="d1"
                      onClick={handleOpenDialog(0)}
                    >
                      Read More <i className="icon-arrow-g"></i>
                    </a>
                  </div>
                </li>
                <li>
                  <i className="icon-2"></i>
                  <div>
                    <h4>債券投資的獲利來源</h4>
                    <p>投資債券的獲利來源，為持有期間的債券利息收入、價格上漲的資本利得...</p>
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#con"
                      className="d2"
                      onClick={handleOpenDialog(1)}
                    >
                      Read More <i className="icon-arrow-g"></i>
                    </a>
                  </div>
                </li>
                <li>
                  <i className="icon-3"></i>
                  <div>
                    <h4>債券的分類</h4>
                    <p>依發行機構：可分為政府公債、超國際債、公司債、金融債等...</p>
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#con"
                      className="d3"
                      onClick={handleOpenDialog(2)}
                    >
                      Read More <i className="icon-arrow-g"></i>
                    </a>
                  </div>
                </li>
                <li>
                  <i className="icon-4"></i>
                  <div>
                    <h4>債券投資的風險</h4>
                    <p>
                      投資債券具有風險，依投資標的及所投資交易之市場而有差異，以下列舉常見的債券風險，因無法囊括所有投資風險及可能影響市場行情之全部因素...
                    </p>
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#con"
                      className="d4"
                      onClick={handleOpenDialog(3)}
                    >
                      Read More <i className="icon-arrow-g"></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </section>
          <section className="block" data-block="5">
            <div id="strategy-anchor" />
            <div className="container">
              <h3 className="style1 text-center" id="block2">
                <span className="text font-24">投資組合策略</span>SOLUTIONS<span className="num"></span>
              </h3>
              <h4 className="text-center">
                投資人可依個人風險偏好及預期報酬，
                <br />
                <span className="blue">建構適合自己的投資組合!</span>
              </h4>
              <div className="photo text-center">
                <img src={photo5} className="hidden-mobile" />
                <img src={mphoto5} className="hidden-desktop hidden-pad" />
              </div>
            </div>
          </section>

          <section className="block" data-block="6">
            <div id="faq-anchor" />
            <div className="container">
              <h3 className="style1 text-center" id="block3">
                <span className="text font-24">常見問題</span>Question<span className="num"></span>
              </h3>
              <div className="row">
                <ul className="menu">
                  <li className={tab === 0 ? 'active' : ''}>
                    <a className={classes.pointer} onClick={handleChangeTab(0)}>
                      商品篇
                    </a>
                  </li>
                  <li className={tab === 1 ? 'active' : ''}>
                    <a className={classes.pointer} onClick={handleChangeTab(1)}>
                      交易篇
                    </a>
                  </li>
                  <li className={tab === 2 ? 'active' : ''}>
                    <a className={classes.pointer} onClick={handleChangeTab(2)}>
                      帳務篇
                    </a>
                  </li>
                </ul>
                <div className="tab_container">
                  <Fade in={tab === 0} mountOnEnter unmountOnExit>
                    <ul className="list square" id="tab1">
                      <li className="active">
                        <span className="black">何謂債券面額？</span>
                        <p>債券發行時所設定的票面金額，代表發行機構借入並承諾於到期時償付給債券持有人的金額。</p>
                      </li>
                      <li>
                        <span className="black">什麼是票面利率？</span>
                        <p>票面利率係指債券發行條件上載明定期支付利息的利率。</p>
                      </li>
                      <li>
                        <span className="black">什麼是殖利率？</span>
                        <p className="text-indet">
                          <span>(1)</span>
                          <span>
                            殖利率則是投資人買進債券後，一直持有至債券到期為止，這段期間的年平均報酬率，故殖利率又稱「到期殖利率」(YTM，Yield
                            to
                            Maturity)。殖利率與債券價格存有反向關連的關係。當殖利率上揚，則債券價格下跌；反之，當殖利率下跌，則債券價格上漲。
                          </span>
                        </p>
                        <p className="text-indet mt-0">
                          <span>(2)</span>
                          <span>
                            若到期殖利率高於票面利率，則債券價格形成折價；若到期殖利率低於票面利率，則債券價格形成溢價；若到期殖利率等於票面利率，則債券價格形成平價。
                          </span>
                        </p>
                      </li>
                      <li>
                        <span className="black">何謂前手息？</span>
                        <p>
                          若投資人於兩付息日之間買入債券，前一付息日到債券交割日之間所產生的利息，將由前一手持有人(賣方)所享有，此時投資人(買方)須在交割時先支付這筆利息給賣方，這筆利息即稱為應計利息或前手息，等到下一付息日，發行機構再將利息支付給買方。
                        </p>
                      </li>
                      <li>
                        <span className="black">債券信用評級分類？</span>
                        <table style={{ width: '100%' }} cellPadding="0" cellSpacing="0" className="blue-table">
                          <tbody>
                            <tr>
                              <th style={{ width: '20%' }} align="center">
                                穆迪 Moody&quot;s
                              </th>
                              <th style={{ width: '20%' }} align="center">
                                標準普爾 S&amp;P
                              </th>
                              <th style={{ width: '20%' }} align="center">
                                惠譽 Fitch
                              </th>
                              <th style={{ width: '40%' }} align="center" colSpan={2}>
                                定義
                              </th>
                            </tr>
                            <tr>
                              <td align="center">Aaa</td>
                              <td align="center">AAA</td>
                              <td align="center">AAA</td>
                              <td style={{ width: '20%' }} align="center">
                                品質極佳
                              </td>
                              <td style={{ width: '20%' }} align="center" rowSpan={4} className="last">
                                投資<span className="m-br">等級</span>
                              </td>
                            </tr>
                            <tr>
                              <td align="center">Aa</td>
                              <td align="center">AA</td>
                              <td align="center">AA</td>
                              <td align="center">品質佳</td>
                            </tr>
                            <tr>
                              <td align="center">A</td>
                              <td align="center">A</td>
                              <td align="center">A</td>
                              <td align="center">品質優於平均</td>
                            </tr>
                            <tr>
                              <td align="center">Baa</td>
                              <td align="center">BBB</td>
                              <td align="center">BBB</td>
                              <td align="center">品質中等</td>
                            </tr>
                            <tr>
                              <td align="center">Ba</td>
                              <td align="center">BB</td>
                              <td align="center">BB</td>
                              <td style={{ width: '20%' }} align="center">
                                有些投機成分
                              </td>
                              <td style={{ width: '20%' }} align="center" rowSpan={6} className="last">
                                非投資<span className="m-br">等級</span>
                              </td>
                            </tr>
                            <tr>
                              <td align="center">B</td>
                              <td align="center">B</td>
                              <td align="center">B</td>
                              <td align="center">投機品質</td>
                            </tr>
                            <tr>
                              <td align="center">Caa</td>
                              <td align="center">CCC</td>
                              <td align="center">CCC</td>
                              <td align="center">品質不佳</td>
                            </tr>
                            <tr>
                              <td align="center">Ca</td>
                              <td align="center">CC</td>
                              <td align="center">CC</td>
                              <td align="center">高度投機品質</td>
                            </tr>
                            <tr>
                              <td align="center">C</td>
                              <td align="center">C</td>
                              <td align="center">C</td>
                              <td align="center">接近違約品質</td>
                            </tr>
                            <tr>
                              <td align="center">-</td>
                              <td align="center">D</td>
                              <td align="center">D</td>
                              <td align="center">倒帳等級</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        <span className="black">申購債券的投資人資格與評等相關規定為何？</span>
                        <p>
                          專業投資人：如為中央政府債券，其國家主權評等應約當在標準普爾BB級以上；公司債或金融債等其他債券約當在標準普爾BB以上之等級。
                          <br />
                          非專業投資人：如為中央政府債券，其國家主權評等應約當在標準普爾A級以上。公司債或金融債等其他債券約當在標準普爾A-級以上之等級。
                        </p>
                      </li>
                    </ul>
                  </Fade>
                  <Fade in={tab === 1} mountOnEnter unmountOnExit>
                    <ul className="list square" id="tab2">
                      <li className="active">
                        <span className="black">下單申購前有哪些要注意的事項？</span>
                        <p className="text-indet">
                          <span>(1)</span>
                          <span>
                            需開立永豐金證券複委託帳戶與永豐銀行外幣自有帳戶，且簽署風險預告書(首次)，於下單前將投資款項匯至其開立在永豐銀行所約定的外幣自有帳戶，才可下單交易。
                          </span>
                        </p>
                        <p className="text-indet mt-0">
                          <span>(2)</span>
                          <span>每年需填寫投資人風險屬性分析問卷調查表。</span>
                        </p>
                      </li>
                      <li>
                        <span className="black">海外債券交割金額為何？</span>
                        <p>
                          申購海外債券的總交割金額為應付本金（買進面額×買進價格〈XX%〉）加上前手息。
                          <br />
                          範例：若以102%購買面額10萬美金的債券，交付價金為102%*10萬美金+前手息。
                        </p>
                      </li>
                    </ul>
                  </Fade>
                  <Fade in={tab === 2} mountOnEnter unmountOnExit>
                    <ul className="list square" id="tab3">
                      <li className="active">
                        <span className="black">海外債券買進交割注意事項？</span>
                        <p>
                          假設交易日為T日，則T+1日扣款，T+2日交割債券<i className="font-12">(註)</i>。<br />
                          註：部分債券交割時程或有異動，依實際交易標的與市場規範為準。
                        </p>
                      </li>
                      <li>
                        <span className="black">海外債券配息或贖回，錢何時入帳？</span>
                        <p>假設配息或贖回日為T日，則T+4~5款項會入帳。</p>
                      </li>
                    </ul>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
        </article>
        <QuestionDialog
          {...dialog}
          onClose={() => setDialog({ open: false, idx: -1 })}
          onChange={idx => setDialog({ open: true, idx })}
        />
      </div>
    </div>
  );
};

export default Knowledge;
