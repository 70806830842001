import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: 16,
      lineHeight: 2,
      textAlign: 'center',
      borderRadius: '0 0 3px 3px',
      marginTop: -2,

      '& p': {
        margin: 0,

        '& > span': {
          color: `${theme.palette.primary.contrastText} !important`,
        },
      },
    },
  }),
);

interface BondInvestmentGradeWarning {
  data?: { result: string[] };
}

export const BondInvestmentGradeWarning: FC<BondInvestmentGradeWarning> = ({ data }) => {
  const classes = useStyles();

  return data && data.result.length === 1 ? (
    // 維護編輯區塊，html 格式
    // eslint-disable-next-line
    <div className={classes.root} dangerouslySetInnerHTML={{ __html: decodeURIComponent(data.result[0]) }} />
  ) : null;
};
