import React, { FC } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';

export interface UserNameAndAccountStateToProps {
  name: string;
  account: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: 13,
    },
  }),
);

export const UserNameAndAccount: FC<UserNameAndAccountStateToProps> = ({ name, account }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={1} className={classes.root}>
      <Grid item>{account}</Grid>
      <Grid item>{name}</Grid>
    </Grid>
  );
};
