//GA Code

const generateGAScript = () => {
  (function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      // @ts-ignore
      (i[r].l = 1 * new Date());
    // @ts-ignore
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    // @ts-ignore
    a.async = 1;
    // @ts-ignore
    a.src = g;
    // @ts-ignore
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
  // @ts-ignore
  ga('create', 'UA-61612424-1', 'auto');
  // @ts-ignore
  ga('send', 'pageview');
};

export default generateGAScript;
