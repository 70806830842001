import {
  AppBar,
  Box,
  createStyles,
  Dialog,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Toolbar,
} from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, Close as CloseIcon } from '@material-ui/icons';
import Fetch from 'component/common/Fetch';
import SearchIcon from 'component/icon/SearchIcon';
import React, { FC, useState } from 'react';
import { BondSuggestionProps } from './BondSuggestion';
import BondSuggestionList from './BondSuggestionList';
import BondSuggestionError from './BondSuggestionError';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      '& > .MuiInputLabel-root': {
        color: theme.palette.text.secondary,
      },
      '& .MuiInput-underline:before': {
        borderBottomStyle: 'solid',
      },
      '& .MuiInputBase-root': {
        color: theme.palette.text.primary,
      },
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
        theme.overrides && theme.overrides.MuiOutlinedInput
          ? {
              ...theme.overrides.MuiOutlinedInput.notchedOutline,
            }
          : {},
    },
    listItem: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px `,
      borderBottom: '1px solid #e0e0e0',
    },
    startInputAdornment: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    EndInputAdornment: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
  }),
);

const BondSuggestionMobile: FC<BondSuggestionProps> = ({ data, onChange, api, searchPayload, searchRule }) => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState('');

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const handleClick = (bond, bondName) => {
    onChange(bond, bondName);
    setOpen(false);
  };
  return (
    <>
      <TextField
        variant="outlined"
        label={`請輸入商品${searchRule}或ISIN`}
        className={classes.input}
        disabled
        value={searchPayload.word}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog fullScreen open={open}>
        <AppBar position="fixed" color="inherit" elevation={0}>
          <Toolbar disableGutters>
            <TextField
              autoFocus
              fullWidth
              value={keyword}
              onChange={handleChangeText}
              placeholder="請輸入關鍵字搜尋商品"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.startInputAdornment}>
                    <IconButton
                      edge="start"
                      onClick={() => {
                        setKeyword('');
                        setOpen(false);
                      }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" className={classes.EndInputAdornment}>
                    <IconButton
                      edge="end"
                      onClick={() => {
                        setKeyword('');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Toolbar>
        </AppBar>
        <Box mt={8} display="flex" flex="1 0 auto">
          <Box width="100%">
            {api && (
              <Fetch url={api} disableAnimate errorComponent={<BondSuggestionError className={classes.listItem} />}>
                <BondSuggestionList classes={classes} keyword={keyword} onClick={handleClick} searchRule={searchRule} />
              </Fetch>
            )}
            {data && (
              <BondSuggestionList
                data={data}
                classes={classes}
                keyword={keyword}
                onClick={handleClick}
                searchRule={searchRule}
              />
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default BondSuggestionMobile;
