import React, { FC } from 'react';
import { Box, DialogContent } from '@material-ui/core';
import CircleProgress from 'component/progress/CircleProgress';

interface DialogStepLoadingProps {
  classes: { content: string };
}

const DialogStepLoading: FC<DialogStepLoadingProps> = ({ classes }) => (
  <DialogContent className={classes.content}>
    <Box my="2px">
      <CircleProgress />
    </Box>
  </DialogContent>
);
export default DialogStepLoading;
