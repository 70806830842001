import { createStyles, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import clsx from 'clsx';

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-block',
      width: 80,
      height: 80,
      border: '4px solid #d43f3a',
      borderRadius: '50%',
      position: 'relative',
      boxSizing: 'content-box',
      animation: '$animateErrorIcon 0.5s',
    },
    xMark: {
      position: 'relative',
      display: 'block',
      animation: '$animateXMark 0.5s',
    },
    line: {
      position: 'absolute',
      height: 5,
      width: 47,
      backgroundColor: '#d9534f',
      display: 'block',
      top: 37,
      borderRadius: 2,
    },
    left: {
      transform: 'rotate(45deg)',
      left: 17,
    },
    right: {
      transform: 'rotate(-45deg)',
      left: 16,
    },
    '@keyframes animateXMark': {
      '0%': {
        transform: 'scale(0.4)',
        marginTop: 26,
        opacity: 0,
      },
      '50%': {
        transform: 'scale(0.4)',
        marginTop: 26,
        opacity: 0,
      },
      '80%': {
        transform: 'scale(1.15)',
        marginTop: -6,
      },
      '100%': {
        transform: 'scale(1)',
        marginTop: 0,
        opacity: 1,
      },
    },
    '@keyframes animateErrorIcon': {
      '0%': {
        transform: 'rotateX(100deg)',
        opacity: 0,
      },
      '100%': {
        transform: 'rotateX(0deg)',
        opacity: 1,
      },
    },
  }),
);

const ErrorIcon: FC = () => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <span className={classes.xMark}>
        <span className={clsx(classes.line, classes.left)} />
        <span className={clsx(classes.line, classes.right)} />
      </span>
    </div>
  );
};

export default ErrorIcon;
