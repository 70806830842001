import { Box, Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Fetch from 'component/common/Fetch';
import WarningBlock from 'component/common/WarningBlock';
import WordingSkeleton from 'component/skeleton/WordingSkeleton';
import moment, { Moment } from 'moment';
import React, { FC, useState } from 'react';
import StartEndDatePicker from 'component/picker/StartEndDatePicker';
import ErrorContent from 'site/bond/component/error/ErrorContent';
import NoDataIcon from 'component/icon/NoDataIcon';
import BondSuggestionRWD from 'component/suggestion/BondSuggestion/BondSuggestionRWD';
import { BondSuggestionSearchRule } from 'component/suggestion/BondSuggestion/BondSuggestion';
import OrderFromContent from 'site/management/component/page/bond/Order/OrderFromContent';
import { IsBondSite } from 'constant/site';
import XlsxButton from '../../button/XlsxButton';
import SystemType from 'constant/SystemType';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    fieldGrid: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    input: {
      width: '100%',
    },
    hide: {
      display: 'none !important',
    },
  }),
);

const ErrorComponent: FC = () => (
  <ErrorContent>
    <NoDataIcon />
  </ErrorContent>
);

interface State {
  startDate: MaterialUiPickersDate;
  endDate: MaterialUiPickersDate;
  word: string;
  isin: string;
  orderType: string;
  systemType: number;
}

// interface ParamState extends Stat {}

interface Props {
  bondListAPI: string;
  contentAPI: string;
  warningAPI: string;
  children: JSX.Element;
  startDate?: Moment;
  acceptableStatusCode?: number[];
  showOrderFromContent?: boolean;
}

interface CsvProps {
  theader: string[];
  tbody: string[];
}

const SearchDatesAndBondPage: FC<Props> = ({
  bondListAPI,
  contentAPI,
  warningAPI,
  children,
  startDate = moment().subtract(1, 'year'),
  acceptableStatusCode,
  showOrderFromContent = false,
}) => {
  showOrderFromContent = !IsBondSite ? showOrderFromContent : false;
  const basepath = sessionStorage.getItem('basepath') || '';
  const classes = useStyles();
  const [fields, setfields] = useState<State>({
    startDate,
    endDate: moment(),
    word: '',
    isin: '',
    orderType: '',
    systemType: SystemType[basepath],
  });

  const [param, setParam] = useState({
    startDate: fields.startDate,
    endDate: fields.endDate,
    word: fields.word,
    ts: Date.now(),
    orderType: fields.orderType,
    systemType: fields.systemType,
  });

  const [csvValue, setCsv] = useState<CsvProps>({
    theader: [],
    tbody: [],
  });

  const handleChangeDates = (startDate: MaterialUiPickersDate, endDate: MaterialUiPickersDate) => {
    setfields({ ...fields, startDate, endDate });
  };

  const handleChangeBond = (bond, word) => {
    setfields({ ...fields, isin: bond ? bond.isin : '', word });
  };

  const handleChange = propName => (event: React.ChangeEvent<HTMLInputElement>) => {
    setfields({ ...fields, [propName]: event.target.value });
  };

  const handleLoadingComplete = (data: object | string) => {
    if (data) {
      //console.log('[handleLoadingComplete]');
      //console.log(data);
      if (JSON.stringify(csvValue.theader) != JSON.stringify(data['columns'])) {
        setCsv({ ...csvValue, theader: data['columns'] });
      }
      if (JSON.stringify(csvValue.tbody) != JSON.stringify(data['data'])) {
        setCsv({ ...csvValue, tbody: data['data'] });
      }
    }
  };

  return (
    <>
      <Box my={2}>
        <Grid container justify="space-between" spacing={1}>
          <Grid item xs={12} sm={10}>
            <Grid container spacing={1} alignItems="center">
              {/* 輸入日期*/}
              <Grid item className={classes.fieldGrid}>
                <StartEndDatePicker start={fields.startDate} end={fields.endDate} onChange={handleChangeDates} />
              </Grid>
              {/* 輸入商品代碼文字框*/}
              <Grid item className={classes.fieldGrid}>
                <Box display="flex" alignItems="center">
                  <BondSuggestionRWD
                    searchRule={BondSuggestionSearchRule.BondCodeAndIsin}
                    disabledVerify
                    api={bondListAPI}
                    onChange={handleChangeBond}
                    searchPayload={{ word: fields.word, isin: fields.isin }}
                  />
                </Box>
              </Grid>
              {/* 委託方式*/}
              <Grid item className={showOrderFromContent ? classes.fieldGrid : classes.hide}>
                <Box display="flex" alignItems="center">
                  <OrderFromContent values={{ orderType: fields.orderType }} onChange={handleChange} />
                </Box>
              </Grid>
              {/* 查詢按鈕*/}
              <Grid item className={classes.fieldGrid}>
                <Box display="flex" alignItems="center">
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setParam({
                        startDate: fields.startDate,
                        endDate: fields.endDate,
                        word: fields.word,
                        ts: Date.now(),
                        orderType: fields.orderType,
                        systemType: fields.systemType,
                      });
                    }}
                  >
                    查詢
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box display="flex" justifyContent="flex-end">
              <Box m={1}>
                <XlsxButton saveName="匯出" theader={csvValue.theader} tBody={csvValue.tbody}>
                  匯出
                </XlsxButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box my={2}>
        <Fetch
          url={contentAPI}
          param={param}
          acceptableStatusCode={acceptableStatusCode}
          onLoaded={handleLoadingComplete}
        >
          {children}
        </Fetch>
      </Box>
      <Box my={2}>
        <Fetch url={warningAPI} loadingComponent={<WordingSkeleton />} errorComponent={<ErrorComponent />}>
          <WarningBlock />
        </Fetch>
      </Box>
    </>
  );
};

export default SearchDatesAndBondPage;
