import React, { FC, useMemo } from 'react';
import { BOND_FIELDS } from 'config/field';
import Immutable, { List } from 'immutable';
import ComplexTable from 'component/table/ComplexTable';
import DataType from 'constant/datatype';
import { ButtonCell, CheckboxCell } from 'component/common/fxTable/Cell';
import { Checkbox, createStyles, makeStyles } from '@material-ui/core';
import { Push } from 'connected-react-router';

const useStyles = makeStyles(() =>
  createStyles({
    checkboxHeader: {
      '& .public_fixedDataTableCell_cellContent': {
        padding: 0,
      },
    },
  }),
);

const useCheckboxStyles = makeStyles(() =>
  createStyles({
    root: {
      color: '#fff',
    },
    checked: {
      color: '#fff !important',
    },
  }),
);

const useButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
);

export interface WatchLIstTableDispatchProps {
  push: Push;
}

export interface WatchListTableOwnProps {
  data?: {
    result: { detail: unknown[]; summary: unknown[] }[];
  };
  onCheck: (isin: string, checked: boolean) => void;
  onCheckAll: (isinList: List<string>) => void;
  selectedIsinList: List<string>;
}

const checkBoxCellRender = (onChange: (isin: string, checked: boolean) => void) => {
  return function Check(data) {
    return (
      <CheckboxCell
        data={data}
        onChange={(...args) => {
          const { 0: rowIndex, 2: checked } = args;

          onChange(data.getIn([rowIndex, 'isin']), checked);
        }}
      />
    );
  };
};

const buttonCellRender = (push: Push) => {
  return function Check(data) {
    const classes = useButtonStyles();

    const handleClick = (isin: string) => {
      push(`/buy/${isin}`);
    };

    return <ButtonCell buttonClassName={classes.root} keyName="isin" data={data} onClick={handleClick} />;
  };
};

const createColumns = (onCheck, checkboxClasses, classes, push, handleCheckAll) => {
  return [
    {
      width: 50,
      columnKey: 'checkbox',
      dataType: DataType.Action,
      sortable: false,
      headerClassName: classes.checkboxHeader,
      header: <Checkbox classes={checkboxClasses} onChange={handleCheckAll} />,
      cellRender: checkBoxCellRender(onCheck),
    },
    {
      header: '債券名稱',
      columnKey: 'bondName',
      dataType: DataType.String,
      dynamicWidth: true,
      width: 200,
    },
    BOND_FIELDS.CURRENCY,
    { ...BOND_FIELDS.COUPON, width: 100 },
    BOND_FIELDS.COUPON_FREQUENCY_DESC,
    BOND_FIELDS.DURATION_YEARS,
    { ...BOND_FIELDS.REF_YTM, width: 140 },
    { ...BOND_FIELDS.REF_PRICE_DATE, header: '日期' },
    BOND_FIELDS.REF_PRICE,
    { ...BOND_FIELDS.WEEK_ROI, width: 100 },
    { ...BOND_FIELDS.MONTH_ROI, width: 100 },
    {
      width: 100,
      header: '交易',
      columnKey: 'button',
      dataType: DataType.Action,
      sortable: false,
      cellRender: buttonCellRender(push),
    },
  ];
};

export const WatchListTable: FC<WatchListTableOwnProps & WatchLIstTableDispatchProps> = ({
  data,
  onCheck,
  selectedIsinList,
  onCheckAll,
  push,
}) => {
  const classes = useStyles();
  const checkboxClasses = useCheckboxStyles();
  const result = useMemo(
    () =>
      Immutable.fromJS(data)
        .get('result')
        .map(el => el.set('checkbox', selectedIsinList.contains(el.get('isin'))).set('button', '交易')),
    [data, selectedIsinList],
  );

  const handleCheckAll = useMemo(
    () => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onCheckAll(checked ? result.map(el => el.get('isin')) : List<string>());
    },
    [onCheckAll, result],
  );

  const columns = useMemo(() => {
    return createColumns(onCheck, checkboxClasses, classes, push, handleCheckAll);
  }, [onCheck, checkboxClasses, classes, push, handleCheckAll]);

  return <ComplexTable data={result} columns={columns} initWithoutSorting headerRowsNumber={2} />;
};
