import React, { FC } from 'react';
import OrderQueryConditionSelect from 'site/management/component/page/bond/Order/OrderQueryConditionSelect';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

const ORDER_TYPE = {
  '': '全部',
  '1': '電子',
  '2': '人工',
};

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      margin: theme.spacing(1),
    },
    select: {
      minWidth: 150,
    },
  }),
);

export interface OrderFromContentValues {
  orderType: string;
}

interface OrderFromContentProps {
  values: OrderFromContentValues;
  onChange: (propName) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OrderFromContent: FC<OrderFromContentProps> = ({ onChange, values }) => {
  const classes = useStyle();

  return (
    <Box display="flex" alignItems="center">
      <OrderQueryConditionSelect
        title="委託方式"
        value={values.orderType}
        className={classes.select}
        options={ORDER_TYPE}
        onChange={onChange('orderType')}
      />
    </Box>
  );
};

export default OrderFromContent;
