import { Collapse, createStyles, Dialog, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import './knowledge.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 1000,
      maxWidth: 1000,
      padding: '60px 50px',
      overflow: 'initial',

      [theme.breakpoints.down('sm')]: {
        padding: '40px 30px',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 8,
        marginRight: 8,
        maxWidth: 'initial !important',
        width: 'auto !important',
      },
      '@media screen and (max-width: 1150px)': {
        width: '90%',
      },
    },
  }),
);

interface Props {
  onClose: () => void;
  onChange: (idx: number) => void;
  open: boolean;
  idx: number;
}

const QuestionDialog: FC<Props> = ({ idx, onChange, onClose, ...props }) => {
  const classes = useStyles();

  const handleClick = (idx: number) => () => onChange(idx);

  return (
    <Dialog {...props} PaperProps={{ className: classes.paper }} scroll="body" onClose={onClose}>
      <div id="knowledge-dialog">
        <a
          href="javascript:void(0);"
          className="knowledge-page-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        ></a>
        <h3 className="style1 text-center">
          <span className="text font-24">商品介紹</span>Desciption<span className="num"></span>
        </h3>
        <ul className="list2">
          <li onClick={handleClick(0)}>
            <p className="question d1">
              什麼是海外債券<i className={idx === 0 ? 'icon-minus' : 'icon-plus'}></i>
            </p>
            <Collapse in={idx === 0}>
              <div className="answer">
                <ol className="decimal">
                  <li>
                    債券發行者(外國政府、政府機構、公司或其他的機構組織)為籌措資金而向投資者發行，且將會承諾按約定利率支付利息，並按約定條件償還本金的債務憑證。
                  </li>
                  <li>
                    對投資人而言，就是把錢借給發行機構。例如：公債就是把錢借給中央政府、金融債就是把錢借給金融機構、公司債就是把錢借給工商企業。
                  </li>
                </ol>
              </div>
            </Collapse>
          </li>
          <li onClick={handleClick(1)}>
            <p className="question d2">
              債券投資的獲利來源<i className={idx === 1 ? 'icon-minus' : 'icon-plus'}></i>
            </p>
            <Collapse in={idx === 1}>
              <div className="answer">
                <ol className="decimal">
                  <li>投資債券的獲利來源，為持有期間的債券利息收入、價格上漲的資本利得。</li>
                  <li>利息所得：為持有債券期間之債券利息。</li>
                  <li>
                    資本利得：次級市場價格受利率、流動性或信用利差等因素有所波動，若賣出價格高於買入價格，即為資本利得。
                  </li>
                </ol>
              </div>
            </Collapse>
          </li>
          <li onClick={handleClick(2)}>
            <p className="question d3">
              債券的分類<i className={idx === 2 ? 'icon-minus' : 'icon-plus'}></i>
            </p>
            <Collapse in={idx === 2}>
              <div className="answer">
                <ol className="decimal">
                  <li>依發行機構：可分為政府公債、超國際債、公司債、金融債等。</li>
                  <li>依信用評等：可分為投資級債券、非投資級債券等。</li>
                  <li>依票面利率：可分為零息債券、固定利率債券、浮動利率債券等。</li>
                  <li>依還本方式：可分為一次還本債券、分次還本債券、永續債券等。</li>
                </ol>
              </div>
            </Collapse>
          </li>
          <li onClick={handleClick(3)}>
            <p className="question d4">
              債券投資的風險<i className={idx === 3 ? 'icon-minus' : 'icon-plus'}></i>
            </p>
            <Collapse in={idx === 3}>
              <div className="answer">
                <ol className="decimal">
                  <li>
                    投資債券具有風險，依投資標的及所投資交易之市場而有差異，以下列舉常見的債券風險，因無法囊括所有投資風險及可能影響市場行情之全部因素並逐項詳述，投資人投資前應詳閱債券商品公開說明書與風險說明。
                  </li>
                  <li>利率風險：市場利率變動引起債券價格變動的風險，也稱為價格風險。</li>
                  <li>
                    再投資風險：債券持有人收到債息後，如將債息再投資，若遇到市場利率下跌，則再投資收益將有減少的風險。
                  </li>
                  <li>違約風險：為發行機構沒有能力償付利息與本金的風險。</li>
                  <li>
                    購買力風險：由於債券在約定期間內是領取固定利息，若發生通貨膨脹，則債券利息的收入及本金部份的購買力都會下降。
                  </li>
                  <li>
                    流動性風險：債券購買或出售的難易程度所造成的風險。若次級市場不活絡，交易量很小，造成投資人想購買時買不到、想賣出時賣不掉，便為流動性風險。
                  </li>
                  <li>
                    強制贖回風險：若債券契約中約定，發行一段時間後，發行機構可以用特定價格強制贖回流通在外的債券，該債券即有強制贖回風險。此狀況通常發生在利率下跌時，發行機構可在利率下跌時贖回利率較高的債券，再發行利率較低的債券，以降低成本。
                  </li>
                  <li>匯率風險：由於國外債券係以外幣計價，在換算成本國貨幣時，將可能產生匯兌損失或利得。</li>
                </ol>
              </div>
            </Collapse>
          </li>
        </ul>
      </div>
    </Dialog>
  );
};

export default QuestionDialog;
