import { Map } from 'immutable';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import AuthorizationContainer from 'site/bond/component/page/AuthorizationContainer';
import ConfirmPage from 'site/bond/component/page/Confirm';
import InterestPage from 'site/bond/component/page/Interest';
import OrderPage from 'site/bond/component/page/Order';
import InventoryPage from 'site/bond/component/page/Inventory';
import ShellContainer from 'site/bond/component/page/Shell/ShellContainer';
import { UserState } from 'site/bond/reducer/userReducer';
import BuyPage from '../page/Buy';
import LoginContainerPage from '../page/LoginContainer';
import PageNotFound from '../page/PageNotFound';
import EmbeddedContainer from 'site/bond/component/page/EmbeddedContainer';
import { AnalyticsPage } from 'site/bond/component/page/Analytics/AnalyticsPage';
import RealizedProfitPage from 'site/bond/component/page/RealizedProfitPage';
import AdvancePage from 'site/bond/component/page/Advance/AdvancePage';
import SuggestionSearchContainer from 'site/bond/component/page/SuggestionSearch/SuggestionSearchContainer';
import { KnowledgePage } from 'site/bond/component/page/Knowledge/KnowlegdgePage';
import { ForeignBondKey } from 'constant/permissionKeys';

interface StatesToProps {
  user?: UserState;
}

const RootRouter: FC<StatesToProps> = ({ user }) => {
  let appMode = new URLSearchParams(location.search).get('appmode');
  if (appMode !== undefined) {
    if (appMode == true.toString() || appMode == false.toString()) {
      localStorage.setItem('appmode', appMode);
    }
  }
  sessionStorage.setItem('basepath', ForeignBondKey);
  return (
    <Switch>
      <Route path={['/login/:action', '/login']} component={LoginContainerPage} />
      {user && (
        <Route
          render={(): JSX.Element => (
            <AuthorizationContainer>
              <ShellContainer>
                <Switch>
                  <Route
                    exact
                    path={['/list', '/']}
                    render={() => <EmbeddedContainer url="/bond/PopularBonds_Iframe.aspx" />}
                  />
                  <Route
                    path="/analytics/:isin"
                    render={({ match }) => {
                      const isin = match.params.isin;

                      return <AnalyticsPage isin={isin} />;
                    }}
                  />
                  <Route path="/analytics" render={() => <SuggestionSearchContainer navigateTo="/analytics" />} />
                  <Route path="/market" render={() => <EmbeddedContainer url="/bond/MarketInfo_Iframe.aspx" />} />
                  <Route path="/search" render={() => <EmbeddedContainer url="/bond/SearchBond_iframe.aspx" />} />
                  <Route path="/advance" component={AdvancePage} />

                  <Route path="/buy/:isin" component={BuyPage} />
                  <Route path="/buy" render={() => <SuggestionSearchContainer navigateTo="/buy" />} />
                  <Route path="/order" component={OrderPage} />
                  <Route path="/interest" component={InterestPage} />
                  <Route path="/realizedProfit" component={RealizedProfitPage} />
                  <Route path="/confirm" component={ConfirmPage} />
                  <Route path="/inventory" component={InventoryPage} />
                  <Route path="/information" component={KnowledgePage} />

                  <Route component={PageNotFound} />
                </Switch>
              </ShellContainer>
            </AuthorizationContainer>
          )}
        />
      )}
    </Switch>
  );
};

const RootRouterContainer = connect(
  (state: Map<string, UserState>): StatesToProps => ({
    user: state.get('user'),
  }),
  {},
)(RootRouter);

export default RootRouterContainer;
