export const ForeignBondKey = 'bond';
export const PermissionKey = 'admin';
export const BTSKey = 'bts';
export const StructuredKey = 'structured';
export const BondOrder = 'bondOrder';

const ALL_PERMISSION_KEYS = {
  [ForeignBondKey]: {
    WARNING_MAINTAINER: 'iemmvkcleu8',
    OTHERS_MAINTAINER: 'mieqremqro',
    MONITOR: 'bmvirt749d',
    ANNOUNCEMENT_MAINTAINER: 'iiemdjh4kd',
    REPORT: 'dirnekdp23',
  },
  [StructuredKey]: {
    PLACE_ORDER: 'structured001',
    OTHERS_MAINTAINER: 'structured003',
    EXPORT_FILE: 'structured004',
  },
};

export default ALL_PERMISSION_KEYS;
