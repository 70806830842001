import { Box, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Fetch from 'component/common/Fetch';
import FetchWarningBlock from 'component/common/FetchWarningBlcok';
import NoDataIcon from 'component/icon/NoDataIcon';
import BondSuggestionRWD from 'component/suggestion/BondSuggestion/BondSuggestionRWD';
import React, { FC, useEffect, useState } from 'react';
import { match } from 'react-router';
import ErrorContent from '../../error/ErrorContent';
import BondBasicInfo, { BondBasicInfoCell } from '../BondBasicInfo';
import ShellGap from '../Shell/ShellGap';
import BuyContent from './BuyContent';
import { BuyOrSell } from './BuyDialog';
import BuyDialogContainer from './BuyDialogContainer';
import WebCACertContainer from '../../WebCA/WebCACertContainer';
import STATUS_CODE from 'constant/StatusCode';
import { BondInvestmentGradeWarning } from 'site/bond/component/page/Buy/BondInvestmentGradeWarning';
import AddToWatchListButton from 'site/bond/component/button/AddToWatchListButton';
import { BondSuggestionSearchRule } from 'component/suggestion/BondSuggestion/BondSuggestion';
import { IsBondSite } from 'constant/site';
import DataType from 'constant/datatype';
import CircleProgress from 'component/progress/CircleProgress';
import { fetchGet } from 'lib/url';

const ErrorComponent: FC = () => (
  <ErrorContent disableLink>
    <NoDataIcon />
  </ErrorContent>
);

const acceptableSubscribeStatusCode = [
  STATUS_CODE.BUY_PRICE_IS_OUTDATE,
  STATUS_CODE.ELECTRONIC_TRADING_BUY_DISABLE,
  STATUS_CODE.CUSTOMER_AND_BOND_PI_RESTRICT_DIALOG,
  STATUS_CODE.TRADE_DAY_BUT_CLOSE_TIME,
];

interface BuyProps {
  match: match<{ isin: string }>;
}

interface State {
  bond: {
    isin: string;
    bondName: string;
    bondCode: string;
  } | null;
  word: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
    },
    right: { textAlign: 'right', [theme.breakpoints.down('xs')]: { marginTop: theme.spacing(3) } },
    suggestionButtonWrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flex: 1,
      },
    },
  }),
);

const cells: BondBasicInfoCell[] = [
  { title: '商品名稱', key: 'bondName', xs: 12, sm: 4 },
  { title: '發行人', key: 'issuer', xs: 12, sm: 4 },
  { title: 'ISIN', key: 'isin', xs: 12, sm: 4 },

  { title: '幣別', key: 'currency' },
  { title: '產業', key: 'industry' },
  { title: '清償順位', key: 'paymentRank' },
  //{ title: '信評(穆迪/標普/惠譽)', key: 'rating' },
  { title: '信評(彭博)', key: 'ratingBBG' },
  { title: '最小交易單位', key: 'minPiece', dataType: DataType.Number },
  { title: '最小增額單位', key: 'minIncrement', dataType: DataType.Number },

  { title: '票息類型', key: 'couponTypeDesc' },
  { title: '票面利率', key: 'coupon' },
  { title: '配息頻率', key: 'couponFrequencyDesc' },
  { title: '付息月份', key: 'interestMonths' },
  { title: '下一付息日', key: 'nextCouponDate', dataType: DataType.Date },
  { title: '計息基準', key: 'dayCountDesc' },

  { title: '到期日', key: 'maturityDate', dataType: DataType.Date },
  { title: '距到期年', key: 'durationYears' },
  { title: '到期收益率(YTM)', key: 'ytm', dataType: DataType.NumberWithMantissa2 },
  { title: '是否為投資等級債券', key: 'investmentGrade', dataType: DataType.Boolean },
  { title: '是否僅限專業投資人申購', key: 'pi', dataType: DataType.Boolean },
  { title: '公開說明書', key: 'attachmentProspectus' },

  { title: '下一買回日', key: 'nextCallDate', dataType: DataType.Date },
  { title: '距買回年', key: 'durationCallYears' },
  { title: '買回收益率(YTC)', key: 'buyYTC', dataType: DataType.NumberWithMantissa2 },
  { title: '發行人是否可提前買回', key: 'callable', dataType: DataType.Boolean },
  { title: '備註', key: 'remark' },
  { title: '產品說明書', key: 'attachmentFinalTerms' },
];


const Buy: FC<BuyProps> = ({ match }) => {
  const [state, setstate] = useState<State>({
    bond: { isin: match.params.isin, bondName: '', bondCode: '' },
    word: '',
  });
  const [open, setopen] = useState(false);
  const [confirmProps, setConfirmProps] = useState({
    price: NaN,
    faceValue: NaN,
  });
  const classes = useStyles();
  const { isin = '', bondName = '' } = state.bond || {};
  const searchPayload = { isin, word: state.word };
  const urlAttachmentList = (IsBondSite ? '' : '/sb') + `/maintain/attachment/newbondlist`;
  const urlInfo = (IsBondSite ? '' : '/sb') + `/product/${isin}`;
  const FinalTerms = 'FinalTerms';
  const Prospectus = 'Prospectus';
  const [SNInfo, setSNInfo] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  const updateAccachmentInfo = (outInfo) => {
    const fromBTS = sessionStorage.getItem('fromBTS') === 'true';
    let url = fromBTS == true ? '/bts' + urlAttachmentList : urlAttachmentList;
    fetchGet<{ result: string[] }>(url, { param: { ISIN: isin } }).then((v): void => {
      try {
        let info = v['result'];
        let FinalTermsName = `${isin}-${FinalTerms}.pdf`;
        let ProspectusName = `${isin}-${Prospectus}.pdf`;
        for (var i = 0; i < info.length; i++) {
          if (info[i]['name'] === FinalTermsName) {
            outInfo['attachmentFinalTerms'] = info[i]['name'];
          } else if (info[i]['name'] === ProspectusName) {
            outInfo['attachmentProspectus'] = info[i]['name'];
          }
        }
        setSNInfo(outInfo);
        setLoading(false);
      } catch (e) {
        setErrorMsg(e + '');
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    setErrorMsg('');
    const fromBTS = sessionStorage.getItem('fromBTS') === 'true';

    let url = fromBTS == true ? '/bts' + urlInfo : urlInfo;
    fetchGet<{ result: string[] }>(url).then((v): void => {
      try {
        let info = v['result'][0];
        if (info == undefined) {
          info = [];
          setErrorMsg('no data');
        }
        //setSNInfoOrign(v);
        //setSNInfo(info);
        updateAccachmentInfo(info);
      } catch (e) {
        setErrorMsg(e + '');
        setLoading(false);
      }
    });
  }, [isin]);

  const handleSubmit = (price, faceValue) => {
    setConfirmProps({ price, faceValue });
    setopen(true);
  };

  const handleChangeBond = (bond, word) => {
    setstate({ bond, word: bond ? '' : word });
  };

  function setBondName(bondName) {
    if (state.bond) {
      setstate({ ...state, bond: { ...state.bond, bondName } });
    }
  }



  return (
    <ShellGap>
      <Grid container>
        <Grid xs={12} sm item>
          <Typography color="primary" variant="h5" className={classes.title}>
            {bondName}
          </Typography>
        </Grid>
        <Grid xs={12} sm item className={classes.right} container justify="flex-end" spacing={1} alignItems="center">
          <Grid item>
            <AddToWatchListButton isin={isin} />
          </Grid>
          <Grid item className={classes.suggestionButtonWrapper}>
            <BondSuggestionRWD
              api="/product/list"
              onChange={handleChangeBond}
              searchPayload={searchPayload}
              searchRule={BondSuggestionSearchRule.NameAndIsin}
            />
          </Grid>
        </Grid>
      </Grid>

      {isin !== '' && (
        <>
          <Fetch url={`/order/subscribe/${isin}`} acceptableStatusCode={acceptableSubscribeStatusCode}>
            <BuyContent onSubmit={handleSubmit} />
          </Fetch>
          <Fetch url={`/maintain/warning/buySystem/${isin}`} disableAnimate>
            <BondInvestmentGradeWarning />
          </Fetch>

          <Box my={2} />

          {
            loading ? (
              <CircleProgress />
            ) : errorMsg ? (
              <Box>查詢時發生錯誤</Box>
            ) : <BondBasicInfo onInitComplete={setBondName}
              cells={cells}
              data={SNInfo}
              attachmentPath={IsBondSite ? '/maintain/attachment/newbond' : '/sb/download/maintain/attachment/newbond'} />
          }

          <FetchWarningBlock url="/maintain/warning/buy" />
        </>
      )}

      {open && (
        <WebCACertContainer onFailed={() => setopen(false)}>
          <BuyDialogContainer
            isin={isin}
            bondName={bondName}
            {...confirmProps}
            onClose={() => setopen(false)}
            buyOrSell={BuyOrSell.Buy}
          />
        </WebCACertContainer>
      )}
    </ShellGap>
  );
};

export default Buy;
