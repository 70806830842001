import React from 'react';
import DataType from 'constant/datatype';
import { FieldsProperty } from './index';
import OrderStatusCell from 'site/bond/component/page/Order/OrderStatusCell';

const createOrderStatusCell = data => <OrderStatusCell data={data} />;

export enum BOND_ORDER_COLUMN_KEYS {
  ORDER_NUMBER = 'orderNumber',
  ENTRUST_DATE = 'entrustDate',
  ENTRUST_TIME = 'entrustTime',
  ENTRUST_PRICE = 'entrustPrice',
  ENTRUST_DATASOURCE = 'dataSource',
  STATUS = 'status',
}

export const BOND_ORDER_FIELDS: { [key: string]: FieldsProperty } = {
  ORDER_NUMBER: {
    header: '委託單號',
    columnKey: BOND_ORDER_COLUMN_KEYS.ORDER_NUMBER,
    dataType: DataType.String,
  },
  ENTRUST_DATE: { header: '委託日期', columnKey: BOND_ORDER_COLUMN_KEYS.ENTRUST_DATE, dataType: DataType.Date },
  ENTRUST_TIME: {
    header: '委託時間',
    columnKey: BOND_ORDER_COLUMN_KEYS.ENTRUST_TIME,
    dataType: DataType.TimeWithSecond,
  },
  ENTRUST_PRICE: { header: '委託價格', columnKey: BOND_ORDER_COLUMN_KEYS.ENTRUST_PRICE, dataType: DataType.Number },
  STATUS: {
    header: '委託狀態',
    columnKey: BOND_ORDER_COLUMN_KEYS.STATUS,
    dataType: DataType.String,
    cellRender: createOrderStatusCell,
    width: 120,
  },
  ENTRUST_DATASOURCE: { header: '委託方式', columnKey: 'dataSource', dataType: DataType.String },
};
