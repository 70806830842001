import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';
import { Map } from 'immutable';
import createRootReducer from './reducer';

export const history = createBrowserHistory();

// @ts-ignore
function configureStore(preloadedState): Store {
  const composeEnhancer =
    // @ts-ignore
    process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history))),
  );

  if (module.hot) {
    module.hot.accept('./reducer', (): void => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}

const store = configureStore(Map());

export default store;
