import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 6,
      height: 6,
      borderRadius: '50%',
      animation: '$type-progress 1s linear infinite alternate',
      margin: '8px',
      position: 'relative',
    },
    '@keyframes type-progress': {
      '0%': {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: `12px 0px 0px 0px ${fade(theme.palette.secondary.main, 0.2)}, 24px 0px 0px 0px ${fade(
          theme.palette.secondary.main,
          0.2,
        )}`,
      },
      '25%': {
        backgroundColor: fade(theme.palette.secondary.main, 0.4),
        boxShadow: `12px 0px 0px 0px ${theme.palette.secondary.main}, 24px 0px 0px 0px ${fade(
          theme.palette.secondary.main,
          0.2,
        )}`,
      },
      '75%': {
        backgroundColor: fade(theme.palette.secondary.main, 0.4),
        boxShadow: `12px 0px 0px 0px ${fade(theme.palette.secondary.main, 0.2)}, 24px 0px 0px 0px ${
          theme.palette.secondary.main
        }`,
      },
    },
  }),
);

const TypingProgress: FC = () => {
  const classes = useStyles();

  return <div className={classes.root}></div>;
};

export default TypingProgress;
