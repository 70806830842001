import STATUS_CODE from 'constant/StatusCode';
import React, { FC } from 'react';
import StatusCodeCheckDialog, {
  StatusCodeCheckDialogWrapperProps,
} from 'site/bond/component/dialog/StatusCodeCheckDialog';

const HolidayDialog: FC<StatusCodeCheckDialogWrapperProps> = ({ statusCode }) => (
  <StatusCodeCheckDialog
    statusCode={statusCode}
    validStatusCode={STATUS_CODE.HOLIDAY}
    warning="今日未開放交易，請於下一營業日下單"
  />
);

export default HolidayDialog;
