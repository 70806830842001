import React, { FC, useState } from 'react';
import { Button } from '@material-ui/core';
import EditableDialog from 'site/bond/component/dialog/EditableDialog';
import { List } from 'immutable';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CopyWatchListDialogContent from 'site/bond/component/page/WatchList/CopyWatchListDialogContent';

interface CopyWatchListButtonProps {
  className: string;
  listId: number;
  isinList: List<string>;
  watchListNames: List<string>;
}

export const CopyWatchListButton: FC<CopyWatchListButtonProps> = ({ watchListNames, listId, isinList, className }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const handleRenderConfirm = ({ onConfirm, isMobileWidth }) => {
    const handleConfirm = (listId: number) => {
      const body = { isinList: isinList.toArray() };

      onConfirm(`/watchList/${listId}/add`, { body });
    };

    return (
      <CopyWatchListDialogContent
        watchListNames={watchListNames}
        excludeListId={listId}
        onClose={handleClose}
        onConfirm={handleConfirm}
        isMobileWidth={isMobileWidth}
      />
    );
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="inherit"
        className={className}
        onClick={handleClick}
        disabled={isinList.size === 0}
      >
        <FileCopyOutlinedIcon />
        複製勾選商品
      </Button>
      {open && <EditableDialog onClose={handleClose} renderConfirm={handleRenderConfirm} title="觀察名單" />}
    </>
  );
};
