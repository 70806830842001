import React, { FC, useState } from 'react';
import { EditableStepDialogProps } from 'site/bond/component/dialog/EditableDialog';
import {
  Box,
  createStyles,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from '@material-ui/core';
import { EditableDialogActions } from 'site/bond/component/page/WatchList/EditableDialogActions';
import { List } from 'immutable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGroup: {
      flexDirection: 'row',
    },
    label: {
      marginBottom: theme.spacing(1),
    },
  }),
);

interface CopyWatchListDialogContentProps extends Omit<EditableStepDialogProps, 'onConfirm'> {
  watchListNames: List<string>;
  excludeListId?: number;
  onConfirm: (listId: number) => void;
}

const CopyWatchListDialogContent: FC<CopyWatchListDialogContentProps> = ({
  excludeListId,
  watchListNames,
  onConfirm,
  onClose,
  isMobileWidth,
}) => {
  const classes = useStyles();
  const [state, setState] = useState<string>('');

  const handleChange = (...args) => {
    const { 1: value } = args;

    setState(value);
  };

  const handleSubmit = () => {
    onConfirm(parseInt(state) + 1);
  };

  return (
    <>
      <DialogContent>
        <Box my={2}>
          <FormControl component="fieldset">
            <FormLabel focused component="legend" className={classes.label}>
              請選擇要加入的清單
            </FormLabel>
            <RadioGroup value={state} onChange={handleChange} className={classes.radioGroup}>
              {watchListNames.map((name, i) =>
                i + 1 === excludeListId ? null : (
                  <FormControlLabel
                    key={`${name}-${i}`}
                    control={<Radio color="primary" />}
                    label={name}
                    value={i.toString()}
                  />
                ),
              )}
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>

      <EditableDialogActions onClose={onClose} fullWidth={isMobileWidth} onSubmit={handleSubmit} />
    </>
  );
};

export default CopyWatchListDialogContent;
