import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Breakpoints from 'constant/breakpoints';
import Func from 'lib/func';
import { IsWindowWidthLessThan } from 'lib/width';
import React, { FC, useEffect, useState } from 'react';
import WarningIcon from 'component/icon/WarningIcon';
import ErrorIcon from 'component/icon/ErrorIcon';
import SuccessIcon from 'component/icon/SuccessIcon';

interface DialogMessageProps {
  onClose: () => void;
  onConfirm?: () => void;
  open: boolean;
  title?: string;
  variant?: 'warning' | 'error' | 'default' | 'success';
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  hiddenAction?: boolean;
  actionType?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontSize: 21,
      paddingTop: 4,
      paddingBottom: 4,
      textAlign: 'center',
    },
    button: {
      fontWeight: 'normal',
    },
    content: {
      padding: '19px 20px',
      textAlign: 'center',
    },
    action: {
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
  }),
);

const DialogMessage: FC<DialogMessageProps> = ({
  maxWidth = 'sm',
  title = '系統公告',
  onClose,
  onConfirm,
  children,
  open,
  variant = 'default',
  hiddenAction = false,
  actionType = 1,
}) => {
  const classes = useStyles();
  const [isMobileWidth, setIsMobileWidth] = useState(IsWindowWidthLessThan(Breakpoints.sm));

  // 偵測寬度，並顯示對應的申購確認視窗
  useEffect(() => {
    const handleResize = Func.debounce(1000)(() => {
      const v = IsWindowWidthLessThan(Breakpoints.sm);

      if (isMobileWidth !== v) {
        setIsMobileWidth(v);
      }
    });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileWidth]);

  return (
    <Dialog open={open} fullWidth maxWidth={maxWidth} onClose={onClose} fullScreen={isMobileWidth}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <Box mb={1.5} display="flex" justifyContent="center">
          {variant === 'warning' && <WarningIcon />}
          {variant === 'error' && <ErrorIcon />}
          {variant === 'success' && <SuccessIcon />}
        </Box>
        {children}
      </DialogContent>
      {!hiddenAction && actionType == 1 && (
        <DialogActions className={classes.action}>
          <Button
            className={classes.button}
            color="default"
            variant="contained"
            onClick={onConfirm || onClose}
            fullWidth={isMobileWidth}
          >
            確認
          </Button>
        </DialogActions>
      )}
      {!hiddenAction && actionType == 2 && (
        <DialogActions className={classes.action}>
          <Button
            className={classes.button}
            color="default"
            variant="contained"
            onClick={onClose}
            fullWidth={isMobileWidth}
          >
            否
          </Button>
          <Button
            className={classes.button}
            color="default"
            variant="contained"
            onClick={onConfirm}
            fullWidth={isMobileWidth}
          >
            是
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogMessage;
