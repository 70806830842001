import STATUS_CODE from 'constant/StatusCode';
import React, { FC } from 'react';
import StatusCodeCheckDialog, {
  StatusCodeCheckDialogWrapperProps,
} from 'site/bond/component/dialog/StatusCodeCheckDialog';

const CustomerAndBondPIRestrictDialog: FC<StatusCodeCheckDialogWrapperProps> = ({ statusCode }) => (
  <StatusCodeCheckDialog
    statusCode={statusCode}
    validStatusCode={STATUS_CODE.CUSTOMER_AND_BOND_PI_RESTRICT_DIALOG}
    warning="限專業投資人投資"
  />
);

export default CustomerAndBondPIRestrictDialog;
