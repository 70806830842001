import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'site/bond/component/App';
import * as serviceWorker from 'serviceWorker';
import 'index.css';
import generateGAScript from 'site/bond/lib/ga';
import Sensors from 'site/bond/lib/sensor';

Sensors.init();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

if (module.hot) {
  module.hot.accept('./component/App', (): void => {
    ReactDOM.render(<App />, document.getElementById('root'));
  });
}
if (process.env['NODE_ENV'] === 'production') {
  window.addEventListener('load', () => {
    generateGAScript();
  });
}
