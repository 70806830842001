import EditIcon from '@material-ui/icons/Edit';
import React, { FC, useState } from 'react';
import { Fab } from '@material-ui/core';
import RenameWatchListDialogContent from 'site/bond/component/page/WatchList/RenameWatchListDialogContent';
import EditableDialog from 'site/bond/component/dialog/EditableDialog';

interface RenameWatchListProps {
  text: string;
  listId: number;
  onChange: (newName: string) => void;
}

export const RenameWatchList: FC<RenameWatchListProps> = ({ text, listId, onChange }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = (newName: string) => {
    onChange(newName);
  };

  const handleRenderConfirm = ({ onConfirm, isMobileWidth }) => {
    return (
      <RenameWatchListDialogContent
        onConfirm={newName =>
          onConfirm(`/watchList/${listId}/rename/${newName}`, { onSuccess: () => handleSuccess(newName) })
        }
        onClose={handleClose}
        name={text}
        isMobileWidth={isMobileWidth}
      />
    );
  };

  return (
    <>
      <Fab size="small" color="primary" aria-label="rename" onClick={handleClick}>
        <EditIcon />
      </Fab>
      {open && <EditableDialog title="觀察名單" renderConfirm={handleRenderConfirm} onClose={handleClose} />}
    </>
  );
};
