import { Box, createStyles, Link, makeStyles, Typography } from '@material-ui/core';
import { goBack, push } from 'connected-react-router';
import React, { FC } from 'react';
import { connect } from 'react-redux';

interface DispatchToProps {
  push: (pathname: string) => void;
  goBack: () => void;
  disableLink?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    divider: {
      padding: 10,
    },
    fonts: {
      fontWeight: 'bold',
    },
    link: {
      cursor: 'pointer',
    },
  }),
);

const ErrorContent: FC<DispatchToProps> = ({ children, push, goBack, disableLink = false }) => {
  const classes = useStyles();

  return (
    <Box mt={8} textAlign="center">
      {children}
      {!disableLink && (
        <Box my={1}>
          <Typography color="primary" className={classes.fonts}>
            <Link className={classes.link} onClick={() => goBack()}>
              返回上一頁
            </Link>
            <span className={classes.divider}>|</span>
            <Link className={classes.link} onClick={() => push('/')}>
              回首頁
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default connect(
  null,
  dispatch => ({
    push: (pathname: string): void => {
      dispatch(push(pathname));
    },
    goBack: (): void => {
      dispatch(goBack());
    },
  }),
)(ErrorContent);
