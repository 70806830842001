import React from 'react';
import { Push } from 'connected-react-router';
import FetchWarningBlock from 'component/common/FetchWarningBlcok';
import ShellGap from 'site/bond/component/page/Shell/ShellGap';

const IFRAME_ORIGIN = process.env['REACT_APP_IFRAME_ORIGIN'];

enum ActionsCode {
  Height = 0,
  Buy = 1,
  Width = 2,
  Analytic = 3,
}

export interface EmbeddedOwnProps {
  url: string;
}

export interface EmbeddedDispatchToProps {
  push: Push;
}
export interface EmbeddedStateProps {
  pathname: string;
}
/**
 * @description 鑲嵌 cmoney 畫面元件
 */
export default class Embedded extends React.Component<EmbeddedOwnProps & EmbeddedDispatchToProps & EmbeddedStateProps> {
  public state = {
    height: 1,
    width: 'auto',
  };

  public constructor(props) {
    super(props);

    window.addEventListener('message', this._receiveMessage, false);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('message', this._receiveMessage);
  }

  private _receiveMessage = (event: MessageEvent) => {
    const origin = event.origin;

    if (origin !== IFRAME_ORIGIN) return;
    const { actionCode, data } = event.data;
    switch (actionCode) {
      case ActionsCode.Height:
        this.setState({ height: data });
        break;
      case ActionsCode.Analytic:
        this.props.push(`/analytics/${data}`);
        break;
      case ActionsCode.Width:
        this.setState({ width: data });
        break;
      case ActionsCode.Buy:
        this.props.push(`/buy/${data}`);
        break;
    }
  };

  public render() {
    const matches = this.props.pathname.match(/^\/[^\/]+/);
    const warningCategory = matches ? matches[0] : null;

    return (
      <ShellGap>
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <iframe
            style={{
              width: this.state.width,
              minWidth: '100%',
              borderWidth: 0,
              backgroundColor: 'transparent',
              height: this.state.height,
            }}
            scrolling="no"
            src={`${IFRAME_ORIGIN}${this.props.url}`}
          />
        </div>
        {warningCategory && <FetchWarningBlock url={`/maintain/warning${warningCategory}`} />}
      </ShellGap>
    );
  }
}
