import { TextCell } from 'component/common/fxTable/Cell';
import { HeaderCell, SortHeaderCell, SortingDirection, HeaderContent } from 'component/common/fxTable/HeaderCell';
import Table from 'component/common/fxTable/Table';
import { TABLE_PADDING_WIDTH, FieldsProperty, TABLE_FONT_SIZE, TABLE_HEADER_FONT_SIZE } from 'config/field';
import DATA from 'constant/data';
import DataType from 'constant/datatype';
import { Column } from 'fixed-data-table-2';
import { List, Map } from 'immutable';
import { compare } from 'lib/compare';
import React, { FC, useMemo, useState } from 'react';
import { calcStringWidth, dataFormatter } from 'lib/string';
import FXTABLE from 'constant/fxtable';

const calcContentWidth = (dataType: DataType, fontSize, str?: string | number | boolean | null) => {
  if (str === undefined || str === null) return 0;
  // 容許諞差值
  const offset = 1.5;
  const formatStr = dataFormatter(str, dataType);
  const number = calcStringWidth(formatStr, fontSize);

  return TABLE_PADDING_WIDTH + number + offset;
};

type GetProperWidth = (param: {
  header: string | string[] | JSX.Element;
  columnKey: string;
  dataType: DataType;
  data: List<Map<string, string | number | boolean>>;
  cellRender?: (data: List<Map<string, string>>) => JSX.Element;
  sortable?: boolean;
  showSortIcon?: boolean;
  width?: number;
}) => number;

// 依照 資料屬性、header and body data 的資料計算寬度
const calcMaxWidth: GetProperWidth = ({
  header,
  dataType,
  columnKey,
  data,
  cellRender,
  sortable = true,
  showSortIcon = false,
  width = 0,
}) => {
  const defaultWidth = DATA.WIDTH[dataType] || 0;
  const sortIconWidth = sortable && showSortIcon ? 32 : 0;
  const headerWidth =
    typeof header === 'string' ? calcContentWidth(DataType.String, TABLE_HEADER_FONT_SIZE, header) + sortIconWidth : 0;
  const contentWidth = cellRender
    ? List<number>()
    : data.map(el => calcContentWidth(dataType, TABLE_FONT_SIZE, el.get(columnKey)));

  var maxWidth = Math.max(defaultWidth, headerWidth, width, ...contentWidth.toArray());
  //再放大一點點，避免顯示白線
  maxWidth = Math.ceil(maxWidth);
  return maxWidth;
};

interface Props {
  data: List<Map<string, string | boolean>>;
  columns: FieldsProperty[];
  // 預設 sorting 欄位 key
  defaultSortKey?: string;
  initWithoutSorting?: boolean;
  // 左側固定欄位長度
  fixedLen?: number;
  enableFixHeader?: boolean;
  height?: number;
  headerRowsNumber?: number;
  showSortIcon?: boolean;
  //自訂義一頁顯示幾行(scrollbar)
  rowsPerPage?: number;
}

interface States {
  dir: SortingDirection;
  key: string;
}

const INVALID_SORT_KEY = 'none';
const INIT_WITHOUT_SORTING_SORT_KEY = 'init_without_sorting';

const ComplexTable: FC<Props> = ({
  fixedLen = 1,
  defaultSortKey,
  columns,
  data,
  height,
  enableFixHeader = false,
  initWithoutSorting = false,
  headerRowsNumber = 1,
  rowsPerPage,
}) => {
  //檢查欄位是否不顯示
  columns = columns.filter(item => item.visibility !== false);
  if (initWithoutSorting) defaultSortKey = INIT_WITHOUT_SORTING_SORT_KEY;
  if (defaultSortKey === undefined) {
    const col = columns.find(col => col.sortable !== false);

    defaultSortKey = col ? col.columnKey : INVALID_SORT_KEY;
  }

  const [sort, setSort] = useState<States>({
    dir: SortingDirection.Asc,
    key: defaultSortKey,
  });

  // 判斷資料需不需要排序
  const list = useMemo(() => {
    if (defaultSortKey === INVALID_SORT_KEY) return data;
    const col = columns.find(col => col.columnKey === sort.key);

    return data.sort((a, b) => {
      const aV = a.get(sort.key);
      const bV = b.get(sort.key);

      return compare(aV, bV, col ? col.dataType : DataType.String, sort.dir);
    });
  }, [data, sort, columns, defaultSortKey]);

  const handleSortChange = (key: string, dir: SortingDirection) => {
    let nextDir = dir === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc;
    setSort({ key, dir: nextDir });
  };

  const { colsWidth, maxRow } = useMemo(() => {
    let maxRow = 1;
    const result = columns.map(({ width, ...rest }) => {
      const maxWidth = calcMaxWidth({ data, ...rest });

      if (width !== undefined) {
        if (maxWidth !== 0) maxRow = Math.max(Math.ceil(maxWidth / width), maxRow);

        return width;
      }

      return maxWidth;
    });

    return { colsWidth: result, maxRow };
  }, [columns, data]);

  const rowHeight = FXTABLE.ROW_HEIGHT + (maxRow - 1) * FXTABLE.TEXT_ROW_HEIGHT;
  const headerHeight = FXTABLE.HEADER_HEIGHT + (headerRowsNumber - 1) * FXTABLE.TEXT_ROW_HEIGHT;

  return (
    <Table
      rowsCount={list.size}
      disableFixHeader={!enableFixHeader}
      height={height}
      rowHeight={rowHeight}
      headerHeight={headerHeight}
      rowsPerPage={rowsPerPage}
    >
      {columns.map(
        (
          {
            header,
            columnKey,
            dataType,
            align,
            sortable = true,
            showSortIcon = false,
            cellRender,
            headerClassName = '',
          },
          i,
        ) => {
          return (
            <Column
              align={align || DATA.ALIGN[dataType]}
              key={columnKey}
              columnKey={columnKey}
              header={
                sortable ? (
                  <SortHeaderCell
                    onSortChange={handleSortChange}
                    sortDir={columnKey === sort.key ? sort.dir : SortingDirection.None}
                    showSortIcon={showSortIcon}
                  >
                    <HeaderContent header={header}></HeaderContent>
                  </SortHeaderCell>
                ) : (
                  <HeaderCell columnKey={columnKey} className={headerClassName}>
                    <HeaderContent header={header}></HeaderContent>
                  </HeaderCell>
                )
              }
              cell={cellRender ? cellRender(list) : <TextCell data={list} dataType={dataType} />}
              flexGrow={1}
              width={colsWidth[i]}
              fixed={i < fixedLen}
            />
          );
        },
      )}
    </Table>
  );
};

export default ComplexTable;
