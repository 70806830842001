import React, { FC } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { BondSuggestionSearchRule } from 'component/suggestion/BondSuggestion/BondSuggestion';

export interface BondSuggestionItem {
  isin: string;
  bondName: string;
  bondCode: string;
}

interface ListResultProps {
  data?: {
    result: BondSuggestionItem[];
  };
  keyword: string;
  classes: { [key: string]: string };
  onClick: (bond: BondSuggestionItem, bondCode: string) => void;
  searchRule: BondSuggestionSearchRule;
}

// 依照 keyword 可列出對應的債券
const BondSuggestionList: FC<ListResultProps> = ({ data, classes, keyword, onClick, searchRule }) => {
  const keywordReg = new RegExp(`${keyword}`, 'i');

  let fieldName = '';

  switch (searchRule) {
    case BondSuggestionSearchRule.NameAndIsin:
      fieldName = 'bondName';
      break;
    case BondSuggestionSearchRule.BondCodeAndIsin:
      fieldName = 'bondCode';
      break;
  }

  const searchSpecificFieldByRule = (el: BondSuggestionItem) => {
    return keywordReg.test(el[fieldName]) || keywordReg.test(el.isin);
  };

  return (
    <List disablePadding>
      {data &&
        data.result
          .filter(searchSpecificFieldByRule)
          .slice(0, 10)
          .map(el => {
            const { isin, bondCode, bondName } = el;

            return (
              <ListItem
                key={isin}
                className={classes.listItem}
                button
                onClick={() => {
                  onClick({ isin, bondCode, bondName }, bondCode);
                }}
              >
                <ListItemText primary={el[fieldName]} secondary={isin} />
              </ListItem>
            );
          })}
    </List>
  );
};

export default BondSuggestionList;
