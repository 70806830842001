import { createStyles, Dialog, DialogTitle, makeStyles, Slide, Theme } from '@material-ui/core';
import Breakpoints from 'constant/breakpoints';
import Func from 'lib/func';
import { fetchPost } from 'lib/url';
import { IsWindowWidthLessThan } from 'lib/width';
import React, { FC, useEffect, useState } from 'react';
import BuyDialogStepConfirm from './BuyDialogStepConfirm';
import DialogStepResult from '../../dialog/DialogStepResult';
import WebCASignContainer from 'site/bond/component/WebCA/WebCASignContainer';
import { Push } from 'connected-react-router';
import STATUS_CODE from 'constant/StatusCode';
import DialogStepLoading from 'site/bond/component/dialog/DialogStepLoading';

export enum BuyOrSell {
  Sell,
  Buy,
}

export interface BuyDialogStatesToProps {
  account: string;
}

export interface BuyDialogProps {
  isin: string;
  bondName: string;
  price: number;
  faceValue: number;
  onClose: () => void;
  buyOrSell: BuyOrSell;
}

export interface BuyDialogDispatchToProps {
  push: Push;
}
enum Step {
  Sign = '憑證簽署',
  Confirm = '委託確認',
  Loading = '委託送出中',
  Result = '委託送出結果',
}

enum SignatureState {
  None = 0,
  Complete = 1,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: 21,
      paddingTop: 4,
      paddingBottom: 4,
      textAlign: 'center',
    },
    button: {
      fontWeight: 'normal',
    },
    content: {
      padding: '19px 20px',
    },
    textBold: {
      fontWeight: 'bold',
    },
    action: {
      justifyContent: 'center',
    },
    paper: {
      overflowX: 'hidden',
    },
  }),
);

const BuyDialog: FC<BuyDialogProps & BuyDialogStatesToProps & BuyDialogDispatchToProps> = props => {
  const { push, isin, faceValue, onClose } = props;
  const classes = useStyles();
  const [step, setstep] = useState<Step>(Step.Sign);
  const [signature, setSignature] = useState('');
  const [plainText, setPlainText] = useState('');
  const [certSN, setCertSN] = useState('');
  const [signatureState, setSignatureState] = useState(SignatureState.None);
  const [isMobileWidth, setIsMobileWidth] = useState(IsWindowWidthLessThan(Breakpoints.sm));
  const [result, setresult] = useState();

  // 偵測寬度，並顯示對應的申購確認視窗
  useEffect(() => {
    const handleResize = Func.debounce(1000)(() => {
      const v = IsWindowWidthLessThan(Breakpoints.sm);

      if (isMobileWidth !== v) {
        setIsMobileWidth(v);
      }
    });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileWidth]);

  const handleCloseDialog = () => {
    if (step !== Step.Loading) {
      if (result && result.statusCode === STATUS_CODE.SUCCESS) push('/order');
      else onClose();
    }
  };

  const subscribeBond = () => {
    setstep(Step.Loading);

    fetchPost({
      pathname: `/order/subscribe/${isin}`,
      body: { entrustDenomination: faceValue, signature, plainText, certSN },
    }).then(v => {
      setresult(v);
      setstep(Step.Result);
    });
  };

  const handleSingComplete = (isSuccess: boolean, signature?: string, plainText?: string, certSN?: string) => {
    setSignatureState(SignatureState.Complete);

    if (isSuccess && signature && plainText && certSN) {
      setSignature(signature);
      setPlainText(plainText);
      setCertSN(certSN);
      setstep(Step.Confirm);
    }
  };

  return (
    <Dialog
      PaperProps={{ className: classes.paper }}
      open
      fullWidth
      maxWidth="sm"
      onClose={handleCloseDialog}
      fullScreen={isMobileWidth}
    >
      <DialogTitle className={classes.title}>{step}</DialogTitle>
      {step === Step.Sign && (
        <div>
          {signatureState === SignatureState.None && <DialogStepLoading classes={classes} />}
          <WebCASignContainer onComplete={handleSingComplete} />
        </div>
      )}
      {step === Step.Confirm && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <div>
            <BuyDialogStepConfirm
              {...props}
              classes={classes}
              onConfirm={subscribeBond}
              isMobileWidth={isMobileWidth}
            />
          </div>
        </Slide>
      )}
      {step === Step.Loading && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <div>
            <DialogStepLoading classes={classes} />
          </div>
        </Slide>
      )}
      {step === Step.Result && (
        <DialogStepResult classes={classes} onClose={handleCloseDialog} isMobileWidth={isMobileWidth} result={result} />
      )}
    </Dialog>
  );
};

export default BuyDialog;
