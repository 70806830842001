import React, { Component } from 'react';
import STATUS_CODE from 'constant/StatusCode';
import DialogMessage from 'site/bond/component/dialog/DialogMessage';
import { log } from 'lib/logger';
import { ca } from 'site/bond/component/WebCA/caComponent';
import { Link, Typography } from '@material-ui/core';
//
import Sensors from 'site/bond/lib/sensor';
import { fetchGet } from 'lib/url';
import { push } from 'connected-react-router';
import store from 'site/bond/configureStore';

export interface WebCACertStateToProps {
  identification: string;
}

export interface WebCACertOwnProps {
  onFailed?: () => void;
  onSuccess?: () => void;
}

const GetWebCADataError = new Error('取得憑證時發生異常，請稍後再試試');
const WebCAHandlingError = new Error('執行憑證時發生異常，請稍後再試試');
const APPLY_CERT = 'ApplyCert';
const RENEW_CERT = 'RenewCert';
const GetErrorMessage = (action, code: string, msg: string) => {
  const actionText = action === APPLY_CERT ? '部屬' : '展期';

  switch (code) {
    case '5070':
    case '5079':
      return `您已取消憑證安裝，故將無法使用交易功能，代碼(${code}) ${msg}`;
    case '9110':
    default:
      return `憑證${actionText}失敗，將不能使用交易功能，錯誤代碼(${code}) ${msg}`;
  }
};

interface CertPayload {
  userID: string;
  signTxt: string;
  identifyNo: string;
  verifyNo: string;
  isWebview: boolean;
}

interface WebCACertState {
  code: string;
  showMessage: boolean;
  showConfirm: boolean;
  message: string;
  checkCertPass: boolean;
  suggestAction: null | string;
  payload: null | CertPayload;
}

/**
 * 驗證是否憑證有效
 */
export default class WebCACert extends Component<WebCACertStateToProps & WebCACertOwnProps> {
  public state: WebCACertState = {
    code: '',
    showMessage: false,
    showConfirm: false,
    message: '',
    checkCertPass: false,
    suggestAction: null,
    payload: null,
  };

  public componentDidMount() {
    const { identification } = this.props;
    const { suggestAction, isCertExist } = ca.checkCert(identification);

    if (isCertExist && suggestAction === 'None') {
      this.setState({ checkCertPass: true });
    } else this._webCaProcess(identification, suggestAction);
  }

  private _webCaProcess(identification: string, suggestAction: string) {
    fetch(`/api/webCA/${suggestAction}?ts=${Date.now()}`)
      .then(v => {
        if (!v.ok) throw GetWebCADataError;

        return v.json();
      })
      .then(json => {
        if (json.statusCode !== STATUS_CODE.SUCCESS) throw GetWebCADataError;

        return json;
      })
      .then(({ result }) => {
        const { verifyNo, identifyNo } = result;
        const payload: CertPayload = {
          userID: identification,
          signTxt: identification + verifyNo,
          identifyNo: identifyNo,
          verifyNo: verifyNo,
          isWebview: true,
        };

        if (suggestAction === APPLY_CERT || suggestAction === RENEW_CERT)
          this.setState({ showConfirm: true, suggestAction, payload });
        else throw WebCAHandlingError;
      })
      .catch(ex => {
        this.setState({ showMessage: true, message: ex.message });
      });
  }

  public componentDidCatch() {
    this.setState({ showMessage: true, message: '憑證驗證異常' });
  }

  public render() {
    const { showConfirm, showMessage, message, checkCertPass } = this.state;
    const { children = null } = this.props;

    if (showMessage)
      return (
        <DialogMessage open onClose={this.handleCloseDialog} title="系統訊息" variant="default">
          {message}
          <Typography>
            憑證相關問題，還請至
            <Link
              href="https://www.sinotrade.com.tw/CSCenter/CSCenter_13_2/?type1=%e9%9b%bb%e5%ad%90%e5%b9%b3%e5%8f%b0&type2=%e5%85%a8%e9%83%a8&keyword=%e6%86%91%e8%ad%89&pagesize=50&page=1"
              target="_blank"
            >
              客戶服務(連結)
            </Link>
            查詢
          </Typography>
        </DialogMessage>
      );

    if (showConfirm)
      return (
        <DialogMessage
          title="憑證系統"
          onClose={this.handleCloseDialog}
          onConfirm={this.handleContinue}
          open
          variant="default"
        >
          交易前必須安裝憑證，您現在無憑證。是否要載入憑證 ?
        </DialogMessage>
      );

    if (checkCertPass && this.props.onSuccess) {
      this.props.onSuccess();
    }
    return checkCertPass ? children : null;
  }

  private handleContinue = () => {
    switch (this.state.suggestAction) {
      // 部屬憑證
      case APPLY_CERT:
        ca.applyCert(this.state.payload, this.whenCaCompleted(APPLY_CERT));
        break;

      // 憑證展期
      case RENEW_CERT:
        ca.renewCert(this.state.payload, this.whenCaCompleted(RENEW_CERT));
        break;
    }
  };

  private whenCaCompleted(action) {
    return (code, msg, token, data) => {
      // 僅確認本站台憑證是否有成功
      const isSelfDeploySuccess = (code: number, data) => {
        try {
          data = JSON.parse(data);
          const reg = new RegExp(`^${window.location.origin}`, 'i');

          return code === 7000 && data.undeployableList.every(path => !reg.test(path));
        } catch {
          return false;
        }
      };

      if (code === 0 || isSelfDeploySuccess(code, data)) this.setState({ showConfirm: false, checkCertPass: true });
      else {
        log(`web ca(${code}): ${msg}`);
        this.setState({
          code: code,
          showConfirm: false,
          showMessage: true,
          message: GetErrorMessage(action, code, msg),
        });
      }
    };
  }

  private handleCloseDialog = () => {
    this.setState({ showMessage: false });
    if (this.props.onFailed) this.props.onFailed();
    // 新增回傳若code = 9110 (生日憑證錯誤)則登出導回首頁
    if (this.state.code == '9110') {
      fetchGet('/login/signout');
      Sensors.logout();
      store.dispatch(push('/login'));
    }
  };
}
