import { AppBar, Box, Hidden, IconButton, Theme, Toolbar, Typography } from '@material-ui/core';
import { ArrowRight as ArrowRightIcon, Menu as MenuIcon } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import Logo from 'asset/image/logo.png';
import clsx from 'clsx';
import Func from 'lib/func';
import React, { createRef, useEffect, useMemo, useState } from 'react';
import LogoutButtonContainer from 'site/bond/component/button/LogoutButtonContainer';
import ShellTabs from './ShellTabs';
import ShellSideBar from './ShellSideBar';
import tabList from 'site/bond/config/tabList';
import { UserNameAndAccountContainer } from 'site/bond/component/page/Shell/UserNameAndAccountContainer';
import { SinopacFooter } from 'site/bond/component/page/Shell/SinopacFooter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      padding: 0,
      boxShadow: '-2px 0 3px 0 rgba(0,0,0,0.30)',
      height: 57,
    },
    toolbar: {
      minHeight: 57,
    },
    logo: {
      display: 'block',
      width: 165,
      height: 48.27,
      backgroundImage: `url(${Logo})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    sidebarToggleIconButton: {
      marginRight: -10,
    },
    sidebarToggleIcon: {
      [theme.breakpoints.up(350)]: {
        fontSize: '2rem',
      },
    },
    platform: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    link: {
      display: 'flex',
      marginRight: 30,
      textDecoration: 'none',
      color: theme.palette.secondary.main,
    },
    tab: {
      minWidth: 'auto',
      fontWeight: 'bold',
      fontSize: 17,
      color: '#000',
      minHeight: 'auto',
      lineHeight: 1,
      padding: '0 0 5px 0',
      margin: `0 ${theme.spacing(1)}px`,
    },
    tabFlexContainer: {
      justifyContent: 'space-between',
    },
    shellGap: {
      padding: '0 24px',
      [theme.breakpoints.up('lg')]: {
        padding: `0 224px`,
      },
      [theme.breakpoints.down('md')]: {
        padding: `0 10px`,
      },
    },
  }),
);

interface Props {
  push: (arg0: string) => void;
  pathname: string;
}

const Shell: React.FC<Props> = ({ children, push, pathname }) => {
  const classes = useStyles();
  const tabIndex = tabList.findIndex(el => new RegExp(`^${el.link}`, 'i').test(pathname === '/' ? '/list' : pathname));
  const [slideBarOpen, setSlideBarOpen] = useState(false);
  const warningRef = createRef<HTMLDivElement>();
  const [warningDomHeight, setWarningDomHeight] = useState(0);
  const childGutterBottom = useMemo(() => warningDomHeight + 36 + 'px', [warningDomHeight]);
  const appMode = localStorage.getItem('appmode');
  const handleTabChange = (i: number) => {
    push(tabList[i].link);
  };

  useEffect(() => {
    const handleResize = () => {
      if (!warningRef.current) return;

      const { height } = warningRef.current.getBoundingClientRect();

      if (warningDomHeight !== height) {
        setWarningDomHeight(height);
      }
    };

    handleResize();
    const debounceHandle = Func.debounce(1000)(handleResize);

    window.addEventListener('resize', debounceHandle);

    return () => {
      window.removeEventListener('resize', debounceHandle);
    };
  }, [warningRef, warningDomHeight]);

  const newWebPath = `${process.env['REACT_APP_BANKEND_ORIGIN']}/api/newweb/Asset`;
  return (
    <>
      {appMode === 'true' ? (
        ''
      ) : (
        <AppBar position="static" color="inherit" className={classes.appbar} elevation={2}>
          <Toolbar className={clsx(classes.toolbar, classes.shellGap)}>
            <div className={classes.logo} />
            <Box ml={1.5}>
              <Typography variant="h6" color="primary" className={classes.platform}>
                海外債券
              </Typography>
            </Box>

            <Box flexGrow={1} />
            <Hidden only="xs">
              <Box display="flex" flexWrap="nowrap" alignItems="center">
                <a href={newWebPath} className={classes.link} target="_blank" rel="noopener noreferrer">
                  <ArrowRightIcon />
                  <Typography component="span">新理財網</Typography>
                </a>
                <Box mr={2}>
                  <UserNameAndAccountContainer />
                </Box>
                <LogoutButtonContainer />
              </Box>
            </Hidden>
            <Hidden smUp>
              <IconButton
                className={classes.sidebarToggleIconButton}
                onClick={() => {
                  setSlideBarOpen(true);
                }}
              >
                <MenuIcon className={classes.sidebarToggleIcon} />
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
      )}
      <Hidden only="xs">
        <Box className={classes.shellGap} mt={4}>
          <ShellTabs value={tabIndex} onChange={(e, i) => handleTabChange(i)} />
        </Box>
      </Hidden>

      <Hidden smUp>
        {slideBarOpen && (
          <ShellSideBar
            newWebPath={newWebPath}
            value={tabIndex}
            onChange={i => {
              handleTabChange(i);
              setSlideBarOpen(false);
            }}
            onCancle={() => {
              setSlideBarOpen(false);
            }}
          />
        )}
      </Hidden>

      <Box mt={4} pb={childGutterBottom}>
        {children}
      </Box>
      {appMode === 'true' ? '' : <SinopacFooter divRef={warningRef} />}
    </>
  );
};

export default Shell;
