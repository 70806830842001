import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import RootRouter from 'site/bond/component/router/index';
import { Provider } from 'react-redux';
import store, { history } from '../configureStore';
import theme from 'style/theme';
import { ConnectedRouter } from 'connected-react-router/immutable';
import moment from 'moment';
import 'moment-timezone';
import ResizeObserver from 'resize-observer-polyfill';
import Func from 'lib/func';
import DialogMessage from 'site/bond/component/dialog/DialogMessage';
import { fetchGet } from 'lib/url';
import STATUS_CODE from 'constant/StatusCode';
import LoginContainer from 'site/bond/component/page/LoginContainer';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SensorContainer from 'site/bond/component/Sensor/SensorContainer';

moment.tz.setDefault('Asia/Taipei');

// chrome v76, 當網頁內容自動撐高，y scrollbar 從無到有不會觸發 resize 事件
// 因此手做，透過偵測 body 寬高，觸發 resize event
const body = document.querySelector('body');
const resizeCb = () => window.dispatchEvent(new Event('resize'));
const ro = new ResizeObserver(Func.debounce(100)(resizeCb));

interface Announcement {
  result: string[];
}

enum AnnouncementType {
  None,
  Offline,
  Normal,
}

class App extends React.Component {
  public state = {
    openDialog: false,
    isDataReady: false,
    announcement: AnnouncementType.None,
    content: '',
  };

  public componentDidMount() {
    if (body) ro.observe(body);

    fetchGet<Announcement>('/maintain/announcement').then(v => {
      if (!('result' in v) || v.result.length === 0) {
        this.setState({ isDataReady: true });
        return;
      }

      const content = decodeURIComponent(v.result[0]);

      if (v.statusCode === STATUS_CODE.F_OFFLINE) {
        this.setState({ isDataReady: true, announcement: AnnouncementType.Offline, content: content });
        return;
      }

      this.setState({
        openDialog: true,
        isDataReady: true,
        announcement: AnnouncementType.Normal,
        content: content,
      });
    });
  }

  public componentWillUnmount(): void {
    if (body) ro.unobserve(body);
  }

  public handleClose = () => this.setState({ openDialog: false });

  public render() {
    const { announcement, openDialog, content, isDataReady } = this.state;

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="zh-TW">
              <>
                {isDataReady &&
                  (announcement === AnnouncementType.Offline ? (
                    <LoginContainer lockContent={content} />
                  ) : (
                    <RootRouter />
                  ))}

                {announcement === AnnouncementType.Normal && openDialog && (
                  <DialogMessage onClose={this.handleClose} open title="系統公告" variant="default">
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </DialogMessage>
                )}
              </>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
          <SensorContainer />
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
