import { connect } from 'react-redux';
import Shell from './index';
import { push } from 'connected-react-router';
import { Map } from 'immutable';

export default connect(
  (state: Map<string, string>) => ({
    pathname: state.getIn(['router', 'location', 'pathname']),
  }),
  { push },
)(Shell);
