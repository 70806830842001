import React, { FC, useMemo } from 'react';
import CopyWatchListDialogContent from 'site/bond/component/page/WatchList/CopyWatchListDialogContent';
import { fromJS } from 'immutable';
import { Result } from 'lib/url';

interface AddToWatchListButtonProps {
  onConfirm: (listId: number) => void;
  onClose: () => void;
  isMobileWidth: boolean;
  data?: Result<{ result: string[] }>;
}

const AddToWatchListButtonDialogContent: FC<AddToWatchListButtonProps> = ({
  onClose,
  onConfirm,
  isMobileWidth,
  data,
}) => {
  const iData = useMemo(() => fromJS(data).get('result', []), [data]);

  return (
    <CopyWatchListDialogContent
      watchListNames={iData}
      onClose={onClose}
      onConfirm={onConfirm}
      isMobileWidth={isMobileWidth}
    />
  );
};

export default AddToWatchListButtonDialogContent;
