import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '10px',
      margin: '50px auto',
      width: '11em',
      height: '11em',
      borderRadius: '50%',
      background: `linear-gradient(to right, ${theme.palette.primary.main} 10%, ${fade(
        theme.palette.primary.main,
        0,
      )} 42%)`,
      position: 'relative',
      animation: '$circle-progress 1.4s infinite linear',
      transform: 'translateZ(0)',
      textIndent: '-9999em',

      '&::before': {
        width: '50%',
        height: '50%',
        background: theme.palette.primary.main,
        borderRadius: '100% 0 0 0',
        position: 'absolute',
        top: 0,
        left: 0,
        content: "''",
      },
      '&::after': {
        background: '#ffffff',
        width: '75%',
        height: '75%',
        borderRadius: '50%',
        content: "''",
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
    },
    '@keyframes circle-progress': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
);

const CircleProgress: FC = () => {
  const classes = useStyles();

  return <div className={classes.root}></div>;
};

export default CircleProgress;
