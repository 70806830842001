/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import DialogMessage from 'site/bond/component/dialog/DialogMessage';
import BondBasicInfo, { BondBasicInfoCell } from 'site/bond/component/page/BondBasicInfo';
import DataType from 'constant/datatype';
import { IsBondSite } from 'constant/site';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import CircleProgress from 'component/progress/CircleProgress';
import { fetchGet } from 'lib/url';
import ComplexTable from 'component/table/ComplexTable';
import Immutable from 'immutable';
import { FieldsProperty } from 'config/field';
import createSNStockTargetCell from './SNStockTargetCell';

interface Props {
  open: boolean;
  onClose: () => void;
  isin: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const cells: BondBasicInfoCell[] = [
  { title: 'Sinopac Code', key: 'productCode', xs: 12, sm: 4 },
  { title: 'TDCC Code', key: 'tdcc', xs: 12, sm: 4 },
  { title: '計價幣別', key: 'currencySettlement', xs: 12, sm: 4 },

  { title: '保本率', key: 'protectionRate', dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign },
  { title: '發行價格', key: 'issuePrice', dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign },
  { title: '發行機構', key: 'issuer' },
  { title: '產品架構', key: 'productType1' },
  { title: '次產品架構', key: 'productType2' },
  { title: '連結標的類別', key: 'assetClass' },
  { title: '參與率', key: 'participationRate', dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign },
  { title: '紅利率', key: 'bonusRate', dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign },
  { title: '配息率', key: 'coupon', dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign },
  { title: '執行價格', key: 'putStrike', dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign },
  { title: '計息價格', key: 'couponBarrier', dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign },
  { title: '下限價格', key: 'knockIn', dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign },
  { title: '提前出場價格', key: 'knockOut', dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign },
  {
    title: '提前出場逐月調降幅度',
    key: 'koStepDown',
    dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign,
  },
  { title: '交易日', key: 'tradeDate', dataType: DataType.Date },
  { title: '發行日', key: 'issueDate', dataType: DataType.Date },
  { title: '最終評價日', key: 'finalValuationDate', dataType: DataType.Date },
  { title: '到期日', key: 'maturityDate', dataType: DataType.Date },
  { title: '商品天期(月)', key: 'tenor' },
  { title: '中文產品說明書', key: 'attachmentFileNameTS' },
  { title: '中文投資人須知', key: 'attachmentFileNameIIS' },
];

const SNBasicDialog: FC<Props> = ({ isin, ...props }) => {
  const urlInfo = (IsBondSite ? '' : '/sb') + `/product/sn/info/${isin}`;
  const urlStockTarget = (IsBondSite ? '' : '/sb') + `/product/sn/StockTarget/${isin}`;
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [SNInfoOrign, setSNInfoOrign] = useState([] as any);
  const [SNInfo, setSNInfo] = useState([]);
  const [StockTargets, setStockTargets] = useState([] as any);

  const stockTargetColumns: FieldsProperty[] = [
    {
      header: '標的名稱',
      dataType: DataType.String,
      columnKey: 'productNameChinese',
      cellRender: createSNStockTargetCell,
    },
    {
      header: '標的代碼',
      dataType: DataType.String,
      columnKey: 'underlying',
    },
    {
      header: '期初價格',
      dataType: DataType.NumberWithMantissa4,
      columnKey: 'unInitialPrice',
      align: 'center',
    },
    {
      header: `執行價格\n(期初價格的${SNInfo['putStrike'] == undefined ? '-' : SNInfo['putStrike']}%)`,
      dataType: DataType.NumberWithMantissa4,
      columnKey: 'putStrikePrice',
      align: 'center',
      width: 120,
    },
    {
      header: `下限價格\n(期初價格的${SNInfo['knockIn'] == undefined ? '-' : SNInfo['knockIn']}%)`,
      dataType: DataType.NumberWithMantissa4,
      columnKey: 'knockInPrice',
      align: 'center',
      width: 120,
    },
    {
      header: `提前出場價格\n(期初價格的${SNInfo['knockOut'] == undefined ? '-' : SNInfo['knockOut']}%)`,
      dataType: DataType.NumberWithMantissa4,
      columnKey: 'knockOutPrice',
      align: 'center',
      width: 120,
    },
    {
      header: '收盤價日期',
      dataType: DataType.Date,
      columnKey: 'priceDate',
    },
    {
      header: '收盤價',
      dataType: DataType.NumberWithMantissa2AndPreserveZero,
      columnKey: 'priceClose',
      align: 'center',
    },
    {
      header: '標的表現\n(期初至今變動%)',
      dataType: DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign,
      columnKey: 'performance',
      align: 'center',
    },
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLoading(true);

    const fromBTS = sessionStorage.getItem('fromBTS') === 'true';
    if (!props.open) {
      setLoading(false);
      return;
    }
    setErrorMsg('');

    let url = fromBTS == true ? '/bts' + urlInfo : urlInfo;
    fetchGet<{ result: string[] }>(url).then((v): void => {
      try {
        let info = v['result'][0];
        if (info == undefined) {
          info = [];
          setErrorMsg('no data');
        }
        setSNInfoOrign(v);
        setSNInfo(info);
        setLoading(false);
      } catch (e) {
        setErrorMsg(e + '');
        setLoading(false);
      }
    });
    url = fromBTS == true ? '/bts' + urlStockTarget : urlStockTarget;
    fetchGet<{ result: string[] }>(url).then((v): void => {
      try {
        let info = v['result'];

        //計算標的表現
        //(收盤價 - 期初價) / 期初價;
        for (let i = 0; i < info.length; i++) {
          info[i].performance = ((info[i].priceClose - info[i].unInitialPrice) / info[i].unInitialPrice) * 100;
        }

        let data = Immutable.fromJS(v ? v['result'] : []);
        setStockTargets(data);
      } catch (e) {
        setErrorMsg(e + '');
      }
    });
  }, [props.open, urlInfo, urlStockTarget]);

  return (
    <DialogMessage {...props} title="債券基本資料" variant="default" maxWidth={false}>
      {loading ? (
        <CircleProgress />
      ) : errorMsg ? (
        <Box>查詢時發生錯誤</Box>
      ) : (
        <>
          {SNInfo['productNameChinese'] ? <Box>{SNInfo['productNameChinese']}</Box> : <></>}
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="產品資訊" {...a11yProps(0)} />
              <Tab label="連結標的" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BondBasicInfo
              cells={cells}
              data={SNInfoOrign}
              attachmentPath={IsBondSite ? '/maintain/attachment/prospectus/sn' : '/sb/download/maintain/attachment/sn'}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ComplexTable data={StockTargets} columns={stockTargetColumns} enableFixHeader initWithoutSorting />
          </TabPanel>
        </>
      )}
    </DialogMessage>
  );
};

export default SNBasicDialog;
