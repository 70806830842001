import React, { FC, useState } from 'react';
import ShellGap from 'site/bond/component/page/Shell/ShellGap';
import { WatchListTabs } from 'site/bond/component/page/WatchList/WatchListTabs';
import Fetch from 'component/common/Fetch';
import { Box } from '@material-ui/core';
import { List } from 'immutable';
import WatchListTableContainer from 'site/bond/component/page/WatchList/WatchLIstTableContainer';

const WatchListPage: FC = () => {
  const [tab, setTab] = useState(0);
  const [updateKey, setUpdateKey] = useState(0);
  const [selectedIsinList, setSelectIsinList] = useState(List());

  const handleCheck = (isin: string, checked: boolean) => {
    const newList = checked ? selectedIsinList.push(isin) : selectedIsinList.remove(selectedIsinList.indexOf(isin));

    setSelectIsinList(newList);
  };

  const handleCheckAll = (isinList: List<string>) => {
    setSelectIsinList(isinList);
  };

  function handleChangeTab(tab: number) {
    setSelectIsinList(List());
    setTab(tab);
  }

  const handleWatchListUpdate = () => {
    setUpdateKey(Date.now());
  };

  return (
    <ShellGap>
      <Box my={2}>
        <Fetch url="/watchList/listNames">
          <WatchListTabs
            selectedIsinList={selectedIsinList}
            value={tab}
            onChangeTab={handleChangeTab}
            onUpdate={handleWatchListUpdate}
          />
        </Fetch>
      </Box>

      <Fetch url={`/watchList/${tab + 1}`} key={updateKey}>
        <WatchListTableContainer
          onCheckAll={handleCheckAll}
          onCheck={handleCheck}
          selectedIsinList={selectedIsinList}
        />
      </Fetch>
    </ShellGap>
  );
};

export default WatchListPage;
