import { connect } from 'react-redux';
import { Map } from 'immutable';
import WebCACert, { WebCACertOwnProps, WebCACertStateToProps } from 'site/bond/component/WebCA/WebCACert';

const WebCACertContainer = connect<WebCACertStateToProps, {}, WebCACertOwnProps, Map<string, string>>(
  states => ({
    identification: states.getIn(['user', 'identification']),
  }),
  {},
)(WebCACert);

export default WebCACertContainer;
