import { IsBondSite } from 'constant/site';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/camelcase
const CA_Component = IsBondSite ? window.CA_Component : function () { };

export const ca = new CA_Component({
  businessNo: '23113343',
  apiVersion: '1.0',
  hashKeyNo: '2',
  returnParams: '',
  DM: process.env.NODE_ENV !== 'production',
  windowURL: '/webcaFrm.html',
  webcaURL: process.env['REACT_APP_WEB_CA_SERVER'] + '/WebCA/WebCA',
});
