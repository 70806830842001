import { Box, Button, createStyles, DialogActions, DialogContent, Divider, makeStyles } from '@material-ui/core';
import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider } from '@material-ui/styles';
import Fetch from 'component/common/Fetch';
import FetchWarningBlock from 'component/common/FetchWarningBlcok';
import WordingSkeleton from 'component/skeleton/WordingSkeleton';
import React, { FC } from 'react';
import { themeOptions } from 'style/theme';
import SellDialogStepConfirmContent from './SellDialogStepConfirmContent';

const themeOverrides = themeOptions.overrides || {};
const theme: ThemeOptions = createMuiTheme({
  ...themeOptions,
  palette: {
    primary: {
      light: '#449D44',
      main: '#449D44',
      dark: '#449D44',
      contrastText: '#fff',
    },
  },
  overrides: {
    ...themeOverrides,
    MuiButton: {
      ...themeOverrides.MuiButton,
      outlinedPrimary: {
        border: `1px solid #449D44`,
      },
    },
  },
});

interface Props {
  account: string;
  orderNumber: string;
  onClose: () => void;
  onConfirm: () => void;
  isMobileWidth: boolean;
  classes: { content: string };
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      fontWeight: 'normal',
    },
    textBold: {
      fontWeight: 'bold',
    },
    action: {
      justifyContent: 'center',
    },
  }),
);

const SellDialogStepConfirm: FC<Props> = ({
  account,
  orderNumber,
  onClose,
  onConfirm,
  isMobileWidth,
  classes: propsClasses,
}) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={propsClasses.content}>
        <Fetch url={`/order/redeem/${orderNumber}`} loadingComponent={<WordingSkeleton />}>
          <SellDialogStepConfirmContent classes={classes} account={account} />
        </Fetch>
        <Box my={2}>
          <Divider />
        </Box>
        <FetchWarningBlock url="/maintain/warning/sellConfirm" />
      </DialogContent>
      <DialogActions className={classes.action}>
        <ThemeProvider theme={theme}>
          <Button
            className={classes.button}
            color="primary"
            variant="outlined"
            onClick={onClose}
            fullWidth={isMobileWidth}
          >
            取消
          </Button>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            fullWidth={isMobileWidth}
            onClick={onConfirm}
          >
            確認送出
          </Button>
        </ThemeProvider>
      </DialogActions>
    </>
  );
};

export default SellDialogStepConfirm;
