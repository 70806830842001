import React, { Component } from 'react';
import STATUS_CODE from 'constant/StatusCode';
import { ca } from 'site/bond/component/WebCA/caComponent';
import { DialogContent, Typography } from '@material-ui/core';

export interface WebCASignStateToProps {
  identification: string;
}

export interface WebCASignOwnProps {
  onComplete: (isSuccess: boolean, signature?: string, plainText?: string, certSN?: string) => void;
}

const GetWebCASignDataError = new Error('取得憑證時發生異常，請稍後再試試');
const WebCASignHandlingError = new Error('執行憑證時發生異常，請稍後再試試');

interface CertPayload {
  userID: string;
  signTxt: string;
  identifyNo: string;
  verifyNo: string;
  isWebview: boolean;
}

export default class WebCASign extends Component<WebCASignStateToProps & WebCASignOwnProps> {
  public state = {
    success: false,
    message: '',
  };

  public componentDidMount() {
    this._webCaProcess();
  }

  private _webCaProcess() {
    const { identification } = this.props;
    const { suggestAction, isCertExist } = ca.checkCert(identification);

    fetch(`/api/webCA/${suggestAction}`)
      .then(v => {
        if (!v.ok) throw GetWebCASignDataError;

        return v.json();
      })
      .then(json => {
        if (json.statusCode !== STATUS_CODE.SUCCESS) throw GetWebCASignDataError;

        return json;
      })
      .then(({ result }) => {
        const { verifyNo, identifyNo } = result;
        const payload: CertPayload = {
          userID: identification,
          signTxt: identification + verifyNo,
          identifyNo: identifyNo,
          verifyNo: verifyNo,
          isWebview: true,
        };

        if (isCertExist && suggestAction === 'None') return this._sign(payload);
        else throw WebCASignHandlingError;
      })
      .then(this._signSuccess)
      .catch(ex => {
        this.props.onComplete(false);
        this.setState({ message: ex.message });
      });
  }

  public componentDidCatch() {
    this.props.onComplete(false);
    this.setState({ message: '簽章異常' });
  }

  public render() {
    const { success, message } = this.state;

    return success ? null : (
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
    );
  }

  // 憑證簽署
  private _sign(payload: CertPayload) {
    return new Promise<void>((resolve, reject) => {
      ca.selectSigner(payload, (...args) => {
        const { 0: selectCode, 3: selectData } = args;

        if (selectCode === 0 && selectData)
          ca.sign(payload, () => {
            resolve();
          });
        else reject(WebCASignHandlingError);
      });
    });
  }

  private _signSuccess = () => {
    this.setState({ success: true });
    this.props.onComplete(true, ca.getSignature(), ca.getSignCode(), ca.getCertSN());
  };
}
