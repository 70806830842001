import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import React, { FC } from 'react';

interface Props {
  className: string;
}

const BondSuggestionError: FC<Props> = ({ className }) => (
  <List disablePadding>
    <ListItem className={className}>
      <ListItemIcon>
        <ErrorIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="目前查無資料" />
    </ListItem>
  </List>
);

export default BondSuggestionError;
