import STATUS_CODE from 'constant/StatusCode';
import React, { FC } from 'react';
import StatusCodeCheckDialog, {
  StatusCodeCheckDialogWrapperProps,
} from 'site/bond/component/dialog/StatusCodeCheckDialog';

const BuyPriceIsOutdateDialog: FC<StatusCodeCheckDialogWrapperProps> = ({ statusCode }) => (
  <StatusCodeCheckDialog
    statusCode={statusCode}
    validStatusCode={STATUS_CODE.BUY_PRICE_IS_OUTDATE}
    warning="委託標的今日為非交易日，不可交易。"
  />
);

export default BuyPriceIsOutdateDialog;
