import {
  Box,
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  makeStyles,
  Slide,
  Typography,
} from '@material-ui/core';
import SuccessIcon from 'component/icon/SuccessIcon';
import STATUS_CODE from 'constant/StatusCode';
import { Result } from 'lib/url';
import React, { FC } from 'react';
import ErrorIcon from 'component/icon/ErrorIcon';

interface DialogStepResultProps {
  result: Result<{}>;
  onClose: () => void;
  isMobileWidth: boolean;
  classes: { content: string };
  successText?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      fontWeight: 'normal',
    },
    action: {
      justifyContent: 'center',
    },
  }),
);

const DialogStepResult: FC<DialogStepResultProps> = ({
  result,
  onClose,
  isMobileWidth,
  classes: propsClasses,
  successText = '委託已成功送出',
}) => {
  const classes = useStyles();
  const isSuccess = result.statusCode === STATUS_CODE.SUCCESS;
  const text = isSuccess ? successText : result.status;

  return (
    <>
      <DialogContent className={propsClasses.content}>
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <div>
            <Box my="2px" textAlign="center">
              {isSuccess ? <SuccessIcon /> : <ErrorIcon />}
            </Box>
            <Box my="2px" textAlign="center">
              <Typography component="span">
                {text.split('\n').map(line => (
                  // eslint-disable-next-line react/jsx-key
                  <p>{line}</p>
                ))}
              </Typography>
            </Box>
          </div>
        </Slide>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <Button
            className={classes.button}
            color="default"
            variant="contained"
            fullWidth={isMobileWidth}
            onClick={onClose}
          >
            確認
          </Button>
        </Slide>
      </DialogActions>
    </>
  );
};

export default DialogStepResult;
