import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { Reducer } from 'redux';
import { History } from 'history';
import UserReducer from './userReducer';

const rootReducer = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    user: UserReducer,
  });

export default rootReducer;
