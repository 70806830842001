import { createStyles, Dialog, DialogTitle, makeStyles, Slide, Theme } from '@material-ui/core';
import Breakpoints from 'constant/breakpoints';
import Func from 'lib/func';
import { fetchDelete, fetchPost } from 'lib/url';
import { IsWindowWidthLessThan } from 'lib/width';
import React, { FC, useEffect, useState } from 'react';
import DialogStepResult from '../../dialog/DialogStepResult';
import CancleDialogStepConfirm from './CancleDialogStepConfirm';
import { Map } from 'immutable';
import STATUS_CODE from 'constant/StatusCode';
import WebCASignContainer from 'site/bond/component/WebCA/WebCASignContainer';
import DialogStepLoading from 'site/bond/component/dialog/DialogStepLoading';

interface Props {
  data: Map<string, string>;
  onClose: () => void;
  onComplete: (isSuccess: boolean, response) => void;
}

enum Step {
  Sign = '憑證簽署',
  Confirm = '刪單確認',
  Loading = '刪單送出中',
  Result = '刪單送出結果',
}

enum SignatureState {
  None = 0,
  Complete = 1,
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: 21,
      paddingTop: 4,
      paddingBottom: 4,
      textAlign: 'center',
    },
    button: {
      fontWeight: 'normal',
    },
    content: {
      padding: '19px 20px',
    },
    textBold: {
      fontWeight: 'bold',
    },
    action: {
      justifyContent: 'center',
    },
    paper: {
      overflowX: 'hidden',
    },
  }),
);

const CancleDialog: FC<Props> = props => {
  const { onClose, onComplete, data } = props;
  const orderKey = data.get('key');
  const classes = useStyles();
  const [step, setstep] = useState<Step>(Step.Sign);
  const [signature, setSignature] = useState('');
  const [plainText, setPlainText] = useState('');
  const [certSN, setCertSN] = useState('');
  const [signatureState, setSignatureState] = useState(SignatureState.None);
  const [isMobileWidth, setIsMobileWidth] = useState(IsWindowWidthLessThan(Breakpoints.sm));
  const [result, setresult] = useState();

  // 偵測寬度，並顯示對應的申購確認視窗
  useEffect(() => {
    const handleResize = Func.debounce(1000)(() => {
      const v = IsWindowWidthLessThan(Breakpoints.sm);

      if (isMobileWidth !== v) {
        setIsMobileWidth(v);
      }
    });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileWidth]);

  const handleCloseDialog = () => {
    if (step === Step.Confirm || (step === Step.Sign && signatureState !== SignatureState.None)) onClose();
    if (step === Step.Result) onComplete(result.statusCode !== STATUS_CODE.FAIL, result);
  };

  const cancleOrder = () => {
    setstep(Step.Loading);

    fetchPost({
      pathname: `/order/cancel/${orderKey}`,
      body: { signature, plainText, certSN },
    }).then(v => {
      setresult(v);
      setstep(Step.Result);
    });
  };

  const handleSingComplete = (isSuccess: boolean, signature?: string, plainText?: string, certSN?: string) => {
    setSignatureState(SignatureState.Complete);

    if (isSuccess && signature && plainText && certSN) {
      setSignature(signature);
      setPlainText(plainText);
      setCertSN(certSN);
      setstep(Step.Confirm);
    }
  };
  return (
    <Dialog
      PaperProps={{ className: classes.paper }}
      open
      fullWidth
      maxWidth="sm"
      onClose={handleCloseDialog}
      fullScreen={isMobileWidth}
    >
      <DialogTitle className={classes.title}>{step}</DialogTitle>
      {step === Step.Sign && (
        <div>
          {signatureState === SignatureState.None && <DialogStepLoading classes={classes} />}
          <WebCASignContainer onComplete={handleSingComplete} />
        </div>
      )}
      {step === Step.Confirm && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <div>
            <CancleDialogStepConfirm
              {...props}
              classes={classes}
              onConfirm={cancleOrder}
              isMobileWidth={isMobileWidth}
            />
          </div>
        </Slide>
      )}
      {step === Step.Loading && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <div>
            <DialogStepLoading classes={classes} />
          </div>
        </Slide>
      )}
      {step === Step.Result && (
        <DialogStepResult
          classes={classes}
          onClose={handleCloseDialog}
          isMobileWidth={isMobileWidth}
          result={result}
          successText="刪單已成功送出"
        />
      )}
    </Dialog>
  );
};

export default CancleDialog;
