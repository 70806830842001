import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    shellGap: {
      padding: '0 24px',
      [theme.breakpoints.up('lg')]: {
        padding: `0 224px`,
      },
      [theme.breakpoints.down('md')]: {
        padding: `0 10px`,
      },
    },
  }),
);

const ShellGap: FC = ({ children }) => {
  const commonClasses = useCommonStyles();

  return <div className={commonClasses.shellGap}>{children}</div>;
};

export default ShellGap;
