import React from 'react';
import { Table as FxTable } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import ResizeObserver from 'resize-observer-polyfill';
import { Paper, LinearProgress } from '@material-ui/core';
import FXTABLE from 'constant/fxtable';
import './Table.scss';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';

const styles = () =>
  createStyles({
    mask: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: 'rgba(162, 162, 162, 0.3)',
      zIndex: 100,
      'border-radius': 4,
    },
    paper: {
      position: 'relative',
    },
  });

interface Props extends WithStyles<typeof styles> {
  rowHeight: number;
  rowsCount: number;
  headerHeight: number;
  height: number;
  loading: boolean;
  // 是否固定表頭
  disableFixHeader: boolean;
  //自訂義一頁顯示幾行(scrollbar)
  rowsPerPage?: number;
}

class Table extends React.Component<Props, { width: number }> {
  public static defaultProps = {
    height: 0,
    rowHeight: FXTABLE.ROW_HEIGHT,
    headerHeight: FXTABLE.HEADER_HEIGHT,
    loading: false,
    disableFixHeader: false,
  };

  public constructor(props: Props) {
    super(props);

    this.state = { width: -1 };
    this.ref = React.createRef();
    this.resizeObserver = new ResizeObserver((entries): void => {
      entries.forEach((): void => {
        this.setState({
          width: this.ref.current.getBoundingClientRect().width,
        });
      });
    });
  }

  private ref;
  private resizeObserver;

  public componentDidMount(): void {
    this.setState({
      width: this.ref.current.getBoundingClientRect().width,
    });

    this.resizeObserver.observe(this.ref.current);
  }

  public componentWillUnmount(): void {
    this.resizeObserver.unobserve(this.ref.current);
  }

  public render(): JSX.Element {
    const { width } = this.state;
    const { height, children, rowHeight, headerHeight, rowsCount, classes, loading, disableFixHeader, rowsPerPage } = this.props;

    const totalHeight = headerHeight + rowsCount * rowHeight;
    const minRows = rowsPerPage ? rowsPerPage : disableFixHeader ? rowsCount : FXTABLE.MIN_ROW;
    const minHeight = headerHeight + Math.min(minRows, rowsCount) * rowHeight;
    const xScrollbarHeight = 15;

    return (
      <div>
        <Paper ref={this.ref} className={classes.paper} elevation={0}>
          {width !== -1 && (
            <FxTable
              rowHeight={rowHeight}
              rowsCount={rowsCount}
              headerHeight={headerHeight}
              height={Math.max(minHeight, Math.min(height - xScrollbarHeight - 2, totalHeight)) + 2 + xScrollbarHeight}
              width={width}
              touchScrollEnabled
            >
              {children}
            </FxTable>
          )}
          {loading && (
            <div className={classes.mask}>
              <LinearProgress color="secondary" />
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(Table);
