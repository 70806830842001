import React from 'react';
import Func from 'lib/func';

interface Props {
  mobile: JSX.Element;
  desktop: JSX.Element;
  breakpoint: number;
}

interface States {
  width: number;
  isDesktop: boolean;
}

/**
 * 依照視窗寬度自動切換元件
 */
export default class DesktopMobileSwitch extends React.Component<Props, States> {
  public static defaultProps = {
    breakpoint: 768,
  };

  public constructor(props: Props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      isDesktop: window.innerWidth >= props.breakpoint,
    };
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  public shouldComponentUpdate(nextProps: Props, nextState: States) {
    return (
      ['mobile', 'desktop'].some(propkey => this.props[propkey] !== nextProps[propkey]) ||
      this.state.isDesktop !== nextState.isDesktop
    );
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public handleResize = Func.throttle(500)(() => {
    this.setState({ isDesktop: window.innerWidth >= this.props.breakpoint });
  });

  public render() {
    return this.state.isDesktop ? this.props.desktop : this.props.mobile;
  }
}
