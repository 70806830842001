const tabList = [
  { name: '債券列表', link: '/list' },
  { name: '債券搜尋', link: '/search' },
  { name: '債券資訊', link: '/analytics' },
  { name: '市場資訊', link: '/market' },
  { name: '進階功能', link: '/advance' },
  { name: '債券買進', link: '/buy' },
  { name: '債券庫存', link: '/inventory' },
  { name: '委託查詢', link: '/order' },
  { name: '成交查詢', link: '/confirm' },
  { name: '配息查詢', link: '/interest' },
  { name: '已實現損益', link: '/realizedProfit' },
  { name: '認識海外債券', link: '/information' },
];

export default tabList;
