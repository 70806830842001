import DataType from 'constant/datatype';
import { FieldsProperty } from './index';

export enum BOND_COLUMN_KEYS {
  MONTH_ROI = 'monthROI',
  WEEK_ROI = 'weekROI',
  REF_YTM = 'refYTM',
  DURATION_YEARS = 'durationYears',
  SELECT = 'select',
  ISIN = 'isin',
  Branch = 'branch',
  Account = 'account',
  ProductType = 'productType',
  RemainFaceValue = 'remainFaceValue',
  Broker = 'broker',
  CustomerRelationshipManager = 'customerRelationshipManager',
  CustName = 'custname',
  BOND_CODE = 'bondCode',
  PRODUCT_NAME = 'productName',
  ELECTRONIC_TRADING = 'electronicTrading',
  MATURITY_DATE = 'maturityDate',
  ISSUE_DATE = 'issueDate',
  CURRENCY = 'currency',
  FACE_VALUE = 'faceValue',
  MIN_BUY_AMT = 'minBuyAmt',
  MIN_INCREMENT = 'minIncrement',
  MIN_BUY_INCREMENT = 'minBuyIncrement',
  MIN_SELL_INCREMENT = 'minSellIncrement',
  COUPON = 'coupon',
  COUPON_FREQUENCY = 'couponFrequency',
  COUPON_FREQUENCY_DESC = 'couponFrequencyDesc',
  DAYS_YEAR = 'daysYear',
  PI = 'pi',
  RISK_GRADE = 'riskGrade',
  ACCRUED_INTEREST = 'accruedInterest',
  ORDER_NUMBER = 'orderNumber',
  APPROPRIATION_DATE = 'appropriationDate',
  INTEREST = 'interest',
  ASSIGN_DATE = 'assignDate',
  TRADE_DATE = 'tradeDate',
  BS = 'bs',
  ORDER_FACE_VALUE = 'orderFaceValue',
  ORDER_PRICE = 'orderPrice',
  SETTLEMENT_AMT = 'settlementAmt',
  PAYMENT_AMT = 'paymentAmt',
  ORDER_NO = 'orderNo',
  CONFIRM_DATE = 'confirmDate',
  CONFIRM_PRICE = 'confirmPrice',
  COST = 'cost',
  REF_PRICE_DATE = 'refPriceDate',
  REF_PRICE = 'refPrice',
  MARKET_VALUE = 'marketValue',
  EXPIRY_PRICE = 'expiryPrice',
  TOTAL_INTEREST = 'totalInterest',
  PROFIT = 'profit',
  ROI = 'roi',
  PROFIT_WITH_INT = 'profitWithInt',
  ROI_WITH_INT = 'roiWithInt',
  RATING = 'rating',
  RATINGBBG = 'ratingBBG',
  DataDate = 'dataDate',
  UpdateNotify = 'updatenotify',
  PrincipalFactor = 'principalFactor',
  IsDualCurrency = 'isDualCurrency',
  PriceDate = 'priceDate',
  BuyCleanPrice = 'buyCleanPrice',
  SellCleanPrice = 'sellCleanPrice',
}

export const BOND_FIELDS: { [key: string]: FieldsProperty } = {
  DataDate: { header: '資料日期', columnKey: BOND_COLUMN_KEYS.DataDate, dataType: DataType.Date },
  PriceDate: { header: '資料日期', columnKey: BOND_COLUMN_KEYS.PriceDate, dataType: DataType.Date },
  Branch: { header: '分公司', columnKey: BOND_COLUMN_KEYS.Branch, dataType: DataType.String, width: 100 },
  Account: { header: '帳戶', columnKey: BOND_COLUMN_KEYS.Account, dataType: DataType.String },
  CustName: { header: '姓名', columnKey: BOND_COLUMN_KEYS.CustName, dataType: DataType.String },
  ISIN: { header: 'ISIN', columnKey: BOND_COLUMN_KEYS.ISIN, dataType: DataType.String },
  BOND_CODE: { header: '商品代號', columnKey: BOND_COLUMN_KEYS.BOND_CODE, dataType: DataType.String },
  ProductType: { header: '商品類別', columnKey: BOND_COLUMN_KEYS.ProductType, dataType: DataType.String },
  Coupon: { header: '票面利率', columnKey: BOND_COLUMN_KEYS.COUPON, dataType: DataType.String },
  Broker: { header: '營業員', columnKey: BOND_COLUMN_KEYS.Broker, dataType: DataType.String, width: 120 },
  CustomerRelationshipManager: {
    header: '理專',
    columnKey: BOND_COLUMN_KEYS.CustomerRelationshipManager,
    dataType: DataType.String,
    width: 120,
  },
  RemainFaceValue: {
    header: '持有面額',
    columnKey: BOND_COLUMN_KEYS.RemainFaceValue,
    dataType: DataType.String,
  },
  PRODUCT_NAME: {
    header: '商品名稱',
    columnKey: BOND_COLUMN_KEYS.PRODUCT_NAME,
    dataType: DataType.String,
    //width: 160,
  },
  ELECTRONIC_TRADING: {
    header: '電子平台可申購',
    columnKey: BOND_COLUMN_KEYS.ELECTRONIC_TRADING,
    dataType: DataType.Boolean,
  },
  MATURITY_DATE: { header: '到期日期', columnKey: BOND_COLUMN_KEYS.MATURITY_DATE, dataType: DataType.Date },
  ISSUE_DATE: { header: '發行日期', columnKey: BOND_COLUMN_KEYS.ISSUE_DATE, dataType: DataType.Date },
  CURRENCY: { header: '幣別', columnKey: BOND_COLUMN_KEYS.CURRENCY, dataType: DataType.String },
  FACE_VALUE: { header: '票面價值', columnKey: BOND_COLUMN_KEYS.FACE_VALUE, dataType: DataType.Number },
  MIN_BUY_AMT: { header: '最低申購金額', columnKey: BOND_COLUMN_KEYS.MIN_BUY_AMT, dataType: DataType.Number },
  MIN_INCREMENT: {
    header: '電子最小增額',
    columnKey: BOND_COLUMN_KEYS.MIN_INCREMENT,
    dataType: DataType.Number,
  },
  MIN_BUY_INCREMENT: {
    header: '最低申購增額',
    columnKey: BOND_COLUMN_KEYS.MIN_BUY_INCREMENT,
    dataType: DataType.Number,
  },
  MIN_SELL_INCREMENT: {
    header: '最低贖回增額',
    columnKey: BOND_COLUMN_KEYS.MIN_SELL_INCREMENT,
    dataType: DataType.Number,
  },
  COUPON: { header: '票面利率(%)', columnKey: BOND_COLUMN_KEYS.COUPON, dataType: DataType.Number },
  COUPON_FREQUENCY: {
    header: '付息頻率',
    columnKey: BOND_COLUMN_KEYS.COUPON_FREQUENCY,
    dataType: DataType.String,
  },
  COUPON_FREQUENCY_DESC: {
    header: '付息頻率',
    columnKey: BOND_COLUMN_KEYS.COUPON_FREQUENCY_DESC,
    dataType: DataType.String,
  },
  DAYS_YEAR: { header: '計息方式', columnKey: BOND_COLUMN_KEYS.DAYS_YEAR, dataType: DataType.String },
  PI: { header: '限專業投資', columnKey: BOND_COLUMN_KEYS.PI, dataType: DataType.Boolean },
  RISK_GRADE: {
    header: '風險等級',
    columnKey: BOND_COLUMN_KEYS.RISK_GRADE,
    dataType: DataType.String,
    align: 'center',
  },
  ACCRUED_INTEREST: {
    header: '每單位前手息',
    columnKey: BOND_COLUMN_KEYS.ACCRUED_INTEREST,
    dataType: DataType.Number,
  },
  ORDER_NUMBER: { header: '買入委託單號', columnKey: BOND_COLUMN_KEYS.ORDER_NUMBER, dataType: DataType.String },
  APPROPRIATION_DATE: {
    header: '入帳日',
    columnKey: BOND_COLUMN_KEYS.APPROPRIATION_DATE,
    dataType: DataType.Date,
  },
  INTEREST: {
    header: '配息金額',
    columnKey: BOND_COLUMN_KEYS.INTEREST,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
  },
  TRADE_DATE: { header: '交易日期', columnKey: BOND_COLUMN_KEYS.TRADE_DATE, dataType: DataType.Date },
  ASSIGN_DATE: { header: '交易日期', columnKey: BOND_COLUMN_KEYS.ASSIGN_DATE, dataType: DataType.Date },
  BS: { header: '交易類別', columnKey: BOND_COLUMN_KEYS.BS, dataType: DataType.String },
  ORDER_FACE_VALUE: { header: '面額', columnKey: BOND_COLUMN_KEYS.ORDER_FACE_VALUE, dataType: DataType.Number },
  ORDER_PRICE: { header: '成交價格', columnKey: BOND_COLUMN_KEYS.ORDER_PRICE, dataType: DataType.Number },
  SETTLEMENT_AMT: {
    header: '成交金額',
    columnKey: BOND_COLUMN_KEYS.SETTLEMENT_AMT,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
  },
  PAYMENT_AMT: {
    header: '應收付金額',
    columnKey: BOND_COLUMN_KEYS.PAYMENT_AMT,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
  },
  CONFIRM_DATE: { header: '成交日期', columnKey: BOND_COLUMN_KEYS.CONFIRM_DATE, dataType: DataType.Date },
  CONFIRM_PRICE: { header: '成交價格', columnKey: BOND_COLUMN_KEYS.CONFIRM_PRICE, dataType: DataType.Number },
  COST: {
    header: '投資成本',
    columnKey: BOND_COLUMN_KEYS.COST,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
  },
  REF_PRICE_DATE: { header: '參考日期', columnKey: BOND_COLUMN_KEYS.REF_PRICE_DATE, dataType: DataType.Date },
  REF_PRICE: { header: '參考價', columnKey: BOND_COLUMN_KEYS.REF_PRICE, dataType: DataType.Number },
  MARKET_VALUE: {
    header: '參考市值',
    columnKey: BOND_COLUMN_KEYS.MARKET_VALUE,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
  },
  EXPIRY_PRICE: { header: '到期價', columnKey: BOND_COLUMN_KEYS.EXPIRY_PRICE, dataType: DataType.Number },
  TOTAL_INTEREST: {
    header: '累計債息',
    columnKey: BOND_COLUMN_KEYS.TOTAL_INTEREST,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
  },
  PROFIT: {
    header: ['投資損益', '(不含債息)'],
    columnKey: BOND_COLUMN_KEYS.PROFIT,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
    width: 100,
  },
  ROI: {
    header: ['報酬率%', '(不含債息)'],
    columnKey: BOND_COLUMN_KEYS.ROI,
    dataType: DataType.NumberWithMantissa2AndPercentageSign,
    width: 100,
  },
  PROFIT_WITH_INT: {
    header: ['投資損益', '(含債息)'],
    columnKey: BOND_COLUMN_KEYS.PROFIT_WITH_INT,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
    width: 100,
  },
  ROI_WITH_INT: {
    header: ['報酬率%', '(含債息)'],
    columnKey: BOND_COLUMN_KEYS.ROI_WITH_INT,
    dataType: DataType.NumberWithMantissa2AndPercentageSign,
    width: 100,
  },
  RATING: {
    header: ['信評', '(穆迪/標普/惠譽)'],
    columnKey: BOND_COLUMN_KEYS.RATING,
    dataType: DataType.String,
    align: 'center',
    width: 140,
  },
  RATINGBBG: {
    header: ['信評', '(彭博)'],
    columnKey: BOND_COLUMN_KEYS.RATINGBBG,
    dataType: DataType.String,
    align: 'center',
    width: 140,
  },
  DURATION_YEARS: {
    header: '距到期年',
    columnKey: BOND_COLUMN_KEYS.DURATION_YEARS,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
  },
  REF_YTM: {
    header: '參考到期殖利率(YTM)',
    columnKey: BOND_COLUMN_KEYS.REF_YTM,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
  },
  WEEK_ROI: {
    header: '周報酬率(%)',
    columnKey: BOND_COLUMN_KEYS.WEEK_ROI,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
  },
  MONTH_ROI: {
    header: '月報酬率(%)',
    columnKey: BOND_COLUMN_KEYS.MONTH_ROI,
    dataType: DataType.NumberWithMantissa2AndPreserveZero,
  },
  PrincipalFactor: {
    header: '本金因子',
    columnKey: BOND_COLUMN_KEYS.PrincipalFactor,
    dataType: DataType.Number,
  },
  IsDualCurrency: {
    header: '雙元貨幣',
    columnKey: BOND_COLUMN_KEYS.IsDualCurrency,
    dataType: DataType.String,
  },
  SellCleanPrice: {
    header: '預估賣出前手息',
    columnKey: BOND_COLUMN_KEYS.SellCleanPrice,
    dataType: DataType.Number,
  },
  BuyCleanPrice: {
    header: '持有面額買進前手息',
    columnKey: BOND_COLUMN_KEYS.BuyCleanPrice,
    dataType: DataType.Number,
  },
};
