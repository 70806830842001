import React, { FC } from 'react';
import ShellGap from './Shell/ShellGap';
import SearchDatesAndBondPage from 'site/management/component/page/bond/SearchDatesAndBondPage';
import RealizedProfitContent from 'site/management/component/page/bond/Account/RealizedProfit/RealizedProfitContent';

const RealizedProfitPage: FC = () => (
  <ShellGap>
    <SearchDatesAndBondPage
      bondListAPI="/account/realizedProfit/bondList"
      contentAPI="/account/realizedProfit"
      warningAPI="/maintain/warning/realizedProfit"
    >
      <RealizedProfitContent />
    </SearchDatesAndBondPage>
  </ShellGap>
);

export default RealizedProfitPage;
