import React, { FC } from 'react';
import ShellGap from './Shell/ShellGap';
import SearchDatesAndBondPage from 'site/management/component/page/bond/SearchDatesAndBondPage';
import OrderContent from 'site/management/component/page/bond/Account/Order/OrderContent';
import moment from 'moment';
import STATUS_CODE from 'constant/StatusCode';

const startDate = moment();
const Order: FC = () => (
  <ShellGap>
    <SearchDatesAndBondPage
      bondListAPI="/account/order/bondList"
      contentAPI="/account/order"
      warningAPI="/maintain/warning/orderQuery"
      acceptableStatusCode={[STATUS_CODE.TRADE_DAY_BUT_CLOSE_TIME]}
      startDate={startDate}
    >
      <OrderContent />
    </SearchDatesAndBondPage>
  </ShellGap>
);

export default Order;
