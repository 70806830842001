import DataType from 'constant/datatype';
import { BOND_COLUMN_KEYS, BOND_FIELDS } from './bond';
import { List, Map } from 'immutable';

export const TABLE_PADDING_WIDTH = 14;
export const TABLE_HEADER_FONT_SIZE = '15px';
export const TABLE_FONT_SIZE = '14px';

export interface FieldsProperty {
  header: string | string[] | JSX.Element;
  headerClassName?: string;
  columnKey: string;
  dataType: DataType;
  // 固定欄位寬度
  width?: number;
  align?: 'left' | 'right' | 'center';
  sortable?: boolean;
  showSortIcon?: boolean;
  cellRender?: (data: List<Map<string, string | number | boolean>>) => JSX.Element;
  visibility?: boolean;
}

export { BOND_FIELDS, BOND_COLUMN_KEYS };
