import { handleActions } from 'redux-actions';
import { login, logout } from '../action/userAction';

const defaultState = {
  name: '',
  auth: false,
  pi: false,
  dvp: false,
  account: '',
  identification: '',
  permissionList: undefined,
};

export interface UserState {
  name: string;
  auth: boolean;
  pi: boolean;
  dvp: boolean;
  account: string;
  identification: string;
  permissionList: any;
}

const reducer = handleActions(
  {
    // @ts-ignore
    [login]: (state, action): UserState => ({
      name: action.payload.name,
      auth: true,
      pi: action.payload.pi,
      dvp: action.payload.dvp,
      account: action.payload.account,
      identification: action.payload.identification,
      permissionList: action.payload.permissionList,
    }),
    // @ts-ignore
    [logout]: (): UserState => ({
      ...defaultState,
    }),
  },
  {
    ...defaultState,
  },
);

export default reducer;
