import React, { FC } from 'react';
import ShellGap from './Shell/ShellGap';
import SearchDatesAndBondPage from 'site/management/component/page/bond/SearchDatesAndBondPage';
import ConfirmContent from 'site/management/component/page/bond/Account/Confirm/ConfirmContent';

const Confirm: FC = () => (
  <ShellGap>
    <SearchDatesAndBondPage
      bondListAPI="/account/confirm/bondList"
      contentAPI="/account/confirm"
      warningAPI="/maintain/warning/confirm"
    >
      <ConfirmContent />
    </SearchDatesAndBondPage>
  </ShellGap>
);

export default Confirm;
