import { createStyles, Icon, makeStyles } from '@material-ui/core';
import TableSorting from 'asset/image/table_sorting.png';
import React, { FC } from 'react';
import clsx from 'clsx';

const useStyle = makeStyles(() =>
  createStyles({
    base: {
      backgroundImage: `url("${TableSorting}")`,
      backgroundRepeat: 'no-repeat',
    },
    asc: {
      backgroundPositionY: -120,
    },
    desc: {
      backgroundPositionY: -59,
    },
  }),
);

export const SortingNoneIcon: FC = () => {
  const classes = useStyle();

  return <Icon className={classes.base} component="div" />;
};

export const SortingAscIcon: FC = () => {
  const classes = useStyle();

  return <Icon className={clsx(classes.base, classes.asc)} component="div" />;
};

export const SortingDescIcon: FC = () => {
  const classes = useStyle();

  return <Icon className={clsx(classes.base, classes.desc)} component="div" />;
};
