import {
  Collapse,
  createStyles,
  Grow,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from '@material-ui/core';
import { ArrowRight as ArrowRightIcon } from '@material-ui/icons';
import clsx from 'clsx';
import React, { FC, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 30,
      textDecoration: 'none',
      color: `${theme.palette.secondary.main} !important`,

      '& .MuiTypography-body1': {
        fontWeight: 'bold',

        [theme.breakpoints.down('sm')]: {
          lineHeight: '50px',
        },
      },
    },
  }),
);

const LoginHeader: FC = ({}) => {
  const classes = useStyles();
  const overThan1026Width = useMediaQuery('(min-width: 1026px)');
  // const overThan1026Width = useMediaQuery('(min-width: 1026px)');
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 800,
  });
  const [collapseIn, setCollapseIn] = useState(false);

  const handleClick = (selector: string) => () => {
    const dom = document.querySelector(selector);

    if (dom) {
      dom.scrollIntoView({ behavior: 'smooth' });
      setCollapseIn(false);
    }
  };

  const handleClickMenu = () => setCollapseIn(!collapseIn);

  return (
    <header className="header">
      <div className="container">
        <a href="javascript:void(0)" className="text-hide logo">
          永豐金證券
        </a>
        <Grow in={trigger} mountOnEnter unmountOnExit>
          <a href="javascript:void(0)" onClick={handleClick('body')} className="link hidden-desktop">
            立即開始
          </a>
        </Grow>

        <div className={clsx({ open: collapseIn }, 'icon-burger')} onClick={handleClickMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <nav className="nav">
          <Collapse in={collapseIn || overThan1026Width} mountOnEnter unmountOnExit>
            <ul>
              <li className="nolink">
                <a href="javascript:void(0)" onClick={handleClick('#introduce-anchor')}>
                  商品介紹
                </a>
              </li>
              <li className="nolink">
                <a href="javascript:void(0)" onClick={handleClick('#strategy-anchor')}>
                  投資組合策略
                </a>
              </li>
              <li className="nolink">
                <a href="javascript:void(0)" onClick={handleClick('#faq-anchor')}>
                  常見問題
                </a>
              </li>
              <Grow in={trigger} mountOnEnter unmountOnExit>
                <li className="hidden-mobile hidden-pad">
                  <a href="javascript:void(0)" className="link" onClick={handleClick('body')}>
                    立即開始
                  </a>
                </li>
              </Grow>
              <li className="nolink">
                <a
                  href="https://www.sinotrade.com.tw/newweb/"
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ArrowRightIcon />
                  <Typography component="span">新理財網</Typography>
                </a>
              </li>
            </ul>
          </Collapse>
        </nav>
      </div>
    </header>
  );
};

export default LoginHeader;
