import { createStyles, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      width: 80,
      height: 80,
      border: '4px solid #eea236',
      borderRadius: '50%',
      position: 'relative',
      boxSizing: 'content-box',
    },
    body: {
      position: 'absolute',
      width: 5,
      height: 47,
      left: '50%',
      top: 10,
      borderRadius: 2,
      marginLeft: -2,
      backgroundColor: '#f0ad4e',
    },
    dot: {
      position: 'absolute',
      width: 7,
      height: 7,
      borderRadius: '50%',
      marginLeft: -3,
      left: '50%',
      bottom: 10,
      backgroundColor: '#f0ad4e',
    },
  }),
);

const WarningIcon: FC = () => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <span className={classes.body} />
      <span className={classes.dot} />
    </div>
  );
};

export default WarningIcon;
