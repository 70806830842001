import { AppBar, Tab, Tabs, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import tabList from 'site/bond/config/tabList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      minWidth: 'auto',
      fontWeight: 'bold',
      fontSize: 17,
      color: '#000',
      minHeight: 'auto',
      lineHeight: 1,
      padding: '0 0 5px 0',
      margin: `0 ${theme.spacing(1)}px`,
    },
    tabFlexContainer: {
      justifyContent: 'space-between',
    },
    scroller: {
      marginBottom: '0 !important',
    },
  }),
);

interface Props {
  onChange: (e, i: number) => void;
  value: number;
}

const ShellTabs: React.FC<Props> = ({ onChange, value }) => {
  const classes = useStyles();

  return (
    <AppBar position="static" color="inherit" elevation={0}>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={value == -1 ? false : value}
        onChange={onChange}
        variant="scrollable"
        scrollButtons="auto"
        classes={{ flexContainer: classes.tabFlexContainer, scroller: classes.scroller }}
      >
        {tabList.map(({ link, name }) => (
          <Tab key={link} label={name} className={classes.tab} disableRipple />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default ShellTabs;
