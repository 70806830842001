import { Box, Typography } from '@material-ui/core';
import { numberFormatter } from 'lib/number';
import React, { FC } from 'react';

interface Props {
  data?: {
    result: {
      isin: string;
      bondName: string;
      price: number;
      faceValue: number;
    }[];
  };
  classes: { [key: string]: string };
  account: string;
}

const SellDialogStepConfirmContent: FC<Props> = ({ account, data, classes }) => {
  const { isin = '', bondName = '', price = null, faceValue = null } = data ? data.result[0] : {};

  return (
    <>
      <Box my="2px">
        <Typography className={classes.textBold} component="span">
          帳號：
        </Typography>
        <Typography component="span">{account}</Typography>
      </Box>
      <Box my="2px">
        <Typography className={classes.textBold} component="span">
          ISIN：
        </Typography>
        <Typography component="span">{isin}</Typography>
      </Box>
      <Box my="2px">
        <Typography className={classes.textBold} component="span">
          商品名稱：
        </Typography>
        <Typography component="span">{bondName}</Typography>
      </Box>
      <Box my="2px">
        <Typography className={classes.textBold} component="span">
          贖回面額：
        </Typography>
        <Typography className={classes.textBold} component="span">
          {numberFormatter(faceValue)}
        </Typography>
      </Box>
      <Box my="2px">
        <Typography className={classes.textBold} component="span">
          贖回價格：
        </Typography>
        <Typography className={classes.textBold} component="span">
          {price}
        </Typography>
      </Box>
    </>
  );
};

export default SellDialogStepConfirmContent;
