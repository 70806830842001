import { Box, MenuItem, TextField } from '@material-ui/core';
import React, { FC } from 'react';

interface OrderQueryConditionSelectProps {
  className: string;
  value: string;
  options: {
    [key: string]: string;
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
}

const OrderQueryConditionSelect: FC<OrderQueryConditionSelectProps> = props => (
  <Box m={1}>
    <TextField select label={props.title} className={props.className} value={props.value} onChange={props.onChange}>
      {Object.entries(props.options).map(([k, v]) => (
        <MenuItem key={k} value={k}>
          {v}
        </MenuItem>
      ))}
    </TextField>
  </Box>
);

export default OrderQueryConditionSelect;
