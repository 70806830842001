import { connect } from 'react-redux';
import SuggestionSearchPage, {
  SuggestionSearchDispatchToProps,
  SuggestionSearchPageOwnProps,
} from 'site/bond/component/page/SuggestionSearch/SuggestionSearchPage';
import { push } from 'connected-react-router';

const SuggestionSearchContainer = connect<{}, SuggestionSearchDispatchToProps, SuggestionSearchPageOwnProps>(
  null,
  { push },
)(SuggestionSearchPage);
export default SuggestionSearchContainer;
