import STATUS_CODE from 'constant/StatusCode';
import React, { FC } from 'react';
import StatusCodeCheckDialog, {
  StatusCodeCheckDialogWrapperProps,
} from 'site/bond/component/dialog/StatusCodeCheckDialog';

const TradeDayButCloseTimeDialog: FC<StatusCodeCheckDialogWrapperProps> = ({ status = '', statusCode }) => (
  <StatusCodeCheckDialog
    statusCode={statusCode}
    validStatusCode={STATUS_CODE.TRADE_DAY_BUT_CLOSE_TIME}
    warning={status}
  />
);

export default TradeDayButCloseTimeDialog;
