import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 28,
      height: 'auto',
    },
    path: {
      fill: '#fff',
    },
  }),
);

const AvatarIcon: FC = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 300 300" className={classes.root}>
      <g>
        <g>
          <path
            className={classes.path}
            d="M150,0C67.2,0,0,67.2,0,150c0,82.8,67.2,150,150,150s150-67.2,150-150C300,67.2,232.8,0,150,0z M150.5,220.8
			L150.5,220.8l-0.9,0H85.5c0-46.9,41.2-46.8,50.3-59.1l1-5.6c-12.8-6.5-21.9-22.2-21.9-40.5c0-24.2,15.7-43.7,35.1-43.7
			s35.1,19.6,35.1,43.7c0,18.2-8.9,33.8-21.6,40.4l1.2,6.3c10,11.7,49.8,12.4,49.8,58.5H150.5z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default AvatarIcon;
