import Fetch from 'component/common/Fetch';
import React, { FC, useEffect, useState } from 'react';
import DialogMessage from 'site/bond/component/dialog/DialogMessage';
import BondBasicInfo, { BondBasicInfoCell } from 'site/bond/component/page/BondBasicInfo';
import DataType from 'constant/datatype';
import { IsBondSite } from 'constant/site';
import CircleProgress from 'component/progress/CircleProgress';
import { fetchGet } from 'lib/url';
import { Box } from '@material-ui/core';

interface Props {
  open: boolean;
  onClose: () => void;
  isin: string;
}
const cells: BondBasicInfoCell[] = [
  { title: '商品名稱', key: 'bondName', xs: 12, sm: 4 },
  { title: '發行人', key: 'issuer', xs: 12, sm: 4 },
  { title: 'ISIN', key: 'isin', xs: 12, sm: 4 },

  { title: '幣別', key: 'currency' },
  { title: '產業', key: 'industry' },
  { title: '清償順位', key: 'paymentRank' },
  //{ title: '信評(穆迪/標普/惠譽)', key: 'rating' },
  { title: '信評(彭博)', key: 'ratingBBG' },
  { title: '最小交易單位', key: 'minPiece', dataType: DataType.Number },
  { title: '最小增額單位', key: 'minIncrement', dataType: DataType.Number },

  { title: '票息類型', key: 'couponTypeDesc' },
  { title: '票面利率', key: 'coupon' },
  { title: '配息頻率', key: 'couponFrequencyDesc' },
  { title: '付息月份', key: 'interestMonths' },
  { title: '下一付息日', key: 'nextCouponDate', dataType: DataType.Date },
  { title: '計息基準', key: 'dayCountDesc' },

  { title: '到期日', key: 'maturityDate', dataType: DataType.Date },
  { title: '距到期年', key: 'durationYears' },
  { title: '是否為投資等級債券', key: 'investmentGrade', dataType: DataType.Boolean },
  { title: '是否僅限專業投資人申購', key: 'pi', dataType: DataType.Boolean },
  { title: '公開說明書', key: 'attachmentProspectus', xs: 12, sm: 4 },

  { title: '下一買回日', key: 'nextCallDate', dataType: DataType.Date },
  { title: '距買回年', key: 'durationCallYears' },
  { title: '發行人是否可提前買回', key: 'callable', dataType: DataType.Boolean },
  { title: '備註', key: 'remark' },
  { title: '產品說明書', key: 'attachmentFinalTerms', xs: 12, sm: 4 },
];

const BondBasicDialog: FC<Props> = ({ isin, ...props }) => {
  const urlAttachment = (IsBondSite ? '' : '/sb') + `/maintain/attachment/newbond`;
  const urlAttachmentList = (IsBondSite ? '' : '/sb') + `/maintain/attachment/newbondlist`;
  const [SNInfoOrign, setSNInfoOrign] = useState([] as any);
  const [SNInfo, setSNInfo] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const urlInfo = (IsBondSite ? '' : '/sb') + `/product/${isin}`;
  const FinalTerms = 'FinalTerms';
  const Prospectus = 'Prospectus';

  const updateAccachmentInfo = (outInfo) => {
    const fromBTS = sessionStorage.getItem('fromBTS') === 'true';
    let url = fromBTS == true ? '/bts' + urlAttachmentList : urlAttachmentList;
    fetchGet<{ result: string[] }>(url, { param: { ISIN: isin } }).then((v): void => {
      try {
        let info = v['result'];
        let FinalTermsName = `${isin}-${FinalTerms}.pdf`;
        let ProspectusName = `${isin}-${Prospectus}.pdf`;
        for (var i = 0; i < info.length; i++) {
          if (info[i]['name'] === FinalTermsName) {
            outInfo['attachmentFinalTerms'] = info[i]['name'];
          } else if (info[i]['name'] === ProspectusName) {
            outInfo['attachmentProspectus'] = info[i]['name'];
          }
        }
        setSNInfo(outInfo);
        setLoading(false);
      } catch (e) {
        setErrorMsg(e + '');
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    setErrorMsg('');
    const fromBTS = sessionStorage.getItem('fromBTS') === 'true';
    if (!props.open) {
      setLoading(false);
      return;
    }
    let url = fromBTS == true ? '/bts' + urlInfo : urlInfo;
    fetchGet<{ result: string[] }>(url).then((v): void => {
      try {
        let info = v['result'][0];
        if (info == undefined) {
          info = [];
          setErrorMsg('no data');
        }
        //setSNInfoOrign(v);
        //setSNInfo(info);
        updateAccachmentInfo(info);
      } catch (e) {
        setErrorMsg(e + '');
        setLoading(false);
      }
    });
  }, [props.open, isin]);

  return (
    <DialogMessage {...props} title="債券基本資料" variant="default" maxWidth="md">
      {
        loading ? (
          <CircleProgress />
        ) : errorMsg ? (
          <Box>查詢時發生錯誤</Box>
        ) : <BondBasicInfo
          cells={cells}
          data={SNInfo}
          attachmentPath={IsBondSite ? '/maintain/attachment/newbond' : '/sb/download/maintain/attachment/newbond'}
        />
      }
    </DialogMessage>
  );
};

export default BondBasicDialog;
