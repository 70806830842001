import ComplexTable from 'component/table/ComplexTable';
import { BOND_FIELDS } from 'config/field';
import Immutable from 'immutable';
import React, { FC, useCallback, useEffect, useState } from 'react';
import DataType from 'constant/datatype';
import CreateCancleCell from './CancleCell';
import { BOND_ORDER_FIELDS } from 'config/field/bondOrder';
import { Result } from 'lib/url';
import STATUS_CODE from 'constant/StatusCode';
import { IsBondSite } from 'constant/site';
import { Box, Tab, Tabs } from '@material-ui/core';

const Category = {
  all: '全部',
  bond: '海外債',
  sn: '結構債',
};

const columns = [
  {
    header: '動作',
    columnKey: 'redeem',
    dataType: DataType.Action,
    sortable: false,
  },
  BOND_FIELDS.ASSIGN_DATE,
  BOND_ORDER_FIELDS.ENTRUST_DATE,
  BOND_ORDER_FIELDS.ENTRUST_TIME,
  BOND_ORDER_FIELDS.STATUS,
  BOND_FIELDS.BS,
  BOND_FIELDS.ISIN,
  BOND_FIELDS.BOND_CODE,
  BOND_FIELDS.CURRENCY,
  { ...BOND_FIELDS.ORDER_FACE_VALUE, header: '委託面額' },
  { ...BOND_FIELDS.ORDER_PRICE, header: '委託價格' },
  { header: '成交面額', columnKey: 'confirmFaceValue', dataType: DataType.Number },
  { header: '成交價格', columnKey: 'confirmPrice', dataType: DataType.Number },
  BOND_ORDER_FIELDS.ORDER_NUMBER,
];
if (!IsBondSite) {
  columns.push(BOND_ORDER_FIELDS.ENTRUST_DATASOURCE); //'委託來源
}

interface Props {
  data?: Result<{
    result: {}[];
  }>;
  onLoaded?: (data: object | string) => void;
}

const OrderContent: FC<Props> = ({ data = { result: [], statusCode: -1, status }, onLoaded }) => {
  const [tab, setTab] = useState(Object.keys(Category)[0]);
  const [sourceData, setSourceData] = useState(data);
  const [immutableData, setImmutableData] = useState(
    Immutable.fromJS(data)
      .get('result', [])
      .map((el, i) => el.set('rowKey', `${i}`))
      .map(el => el.update('bs', bs => (bs === 'B' ? '買進' : '賣出')))
      .map(el => el.update('dataSource', dataSource => (dataSource === '電子平台' ? '電子' : '人工'))),
  );

  const isTradeDay =
    data.statusCode === STATUS_CODE.SUCCESS || data.statusCode === STATUS_CODE.TRADE_DAY_BUT_CLOSE_TIME;
  const closeTime = data.statusCode === STATUS_CODE.TRADE_DAY_BUT_CLOSE_TIME;
  columns[0].cellRender = CreateCancleCell(
    (rowIndex: number, response) => {
      //轉換成新的object  useStatus 才會被啟動
      let tmpObj = JSON.parse(JSON.stringify(sourceData));
      tmpObj['result'][rowIndex]['status'] = response['result'][0]['status'];
      tmpObj['result'][rowIndex]['errorDesc'] = response['result'][0]['errorDesc'];
      tmpObj['result'][rowIndex]['cancelDataSource'] = response['result'][0]['cancelDataSource'];
      setSourceData(tmpObj);


      /*
      const immutableFetchResponse = Immutable.fromJS(response);

      const updateSpecificProperties = (properties: string[]) =>
        properties.reduce(
          (prev, curr) => prev.setIn([rowIndex, curr], immutableFetchResponse.getIn(['result', 0, curr])),
          immutableData,
        );

      setImmutableData(updateSpecificProperties(['status', 'errorDesc', 'cancelDataSource']));
      */
    },
    isTradeDay,
    closeTime,
    data.status,
  );

  //先對原始資料放入自定義 rowKey
  useEffect(() => {
    for (let i = 0; i < data['result'].length; i++) {
      data['result'][i]['rowKey'] = i;
      data['result'][i]['bs'] = (data['result'][i]['bs'] === 'B' ? '買進' : '賣出');
      data['result'][i]['dataSource'] = (data['result'][i]['dataSource'] === '電子平台' ? '電子' : '人工');
    }
    setSourceData(data);
  }, [data]);

  useEffect(() => {
    let detailData: any[];
    let detailTmp: any = [];
    if (tab === 'all') {
      detailData = sourceData['result'];
    } else {
      for (let i = 0; i < sourceData['result'].length; i++) {
        if (sourceData['result'][i]['isBondProduct'] == (tab === 'bond' ? true : false)) {
          detailTmp.push(sourceData['result'][i]);
        }
      }
      detailData = detailTmp;
    }
    if (onLoaded) {
      onLoaded({
        columns: columns,
        data: detailData,
      });
    }
    let immDetail = Immutable.fromJS(detailData);
    setImmutableData(immDetail);
  }, [sourceData, onLoaded, tab]);

  return (
    <>
      <Box mb={2}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          onChange={(e, v): void => {
            setTab(v);
          }}
        >
          {Object.entries(Category).map(
            ([k, v]): JSX.Element => (
              <Tab key={k} label={v} value={k} />
            ),
          )}
        </Tabs>
        <ComplexTable data={immutableData} columns={columns} initWithoutSorting />
      </Box>
    </>
  );
};

export default OrderContent;
