import React, { FC } from 'react';
import ErrorContent from '../error/ErrorContent';
import PageNotFoundIcon from 'component/icon/PageNotFoundIcon';

const PageNotFound: FC = () => (
  <ErrorContent>
    <PageNotFoundIcon />
  </ErrorContent>
);

export default PageNotFound;
