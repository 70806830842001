import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Radio,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import DATA from 'constant/data';
import DataType from 'constant/datatype';
import { Cell } from 'fixed-data-table-2';
import { List, Map } from 'immutable';
import DynamicDataLoader from 'lib/dynamicDataLoader';
import React, { FC } from 'react';
import { dataFormatter } from 'lib/string';
import { ButtonProps } from '@material-ui/core/Button/Button';

const useStyles = makeStyles(() =>
  createStyles({
    cell: {
      fontSize: 14,
      position: 'relative',
    },
    errorCell: {
      fontSize: 14,
      position: 'relative',
      //color: '#ff0000',
      backgroundColor: '#d9534f',
    },
  }),
);

export interface CellDefaultProps {
  data: List<List<unknown> | Map<string, unknown>> | DynamicDataLoader;
  rowIndex?: number;
  columnKey?: string;
  className?: string;
}

export const TextCell: React.FC<CellDefaultProps & { dataType?: DataType }> = ({
  data,
  rowIndex,
  columnKey,
  dataType,
  ...props
}) => {
  const classes = useStyles();

  let classname = classes.cell;

  let dataError = data
    .getIn([rowIndex, 'summaryError'], DATA.NULL)
    .toString()
    .toLowerCase();
  if (dataError === 'true') {
    classname = classes.errorCell;
  }
  /*   變紅色再想想怎麼處理
  let dataSource = data.getIn([rowIndex, 'dataSource']);
  dataError = data.getIn([rowIndex, 'approveStatus'], DATA.NULL);
  if (dataError !== 'Y' && dataSource !== 'W') {
    classname = classes.errorCell;
  }
  */

  let text = data.getIn([rowIndex, columnKey], DATA.NULL);

  text = dataFormatter(text, dataType);

  return (
    <Cell className={classname} {...props}>
      {text}
    </Cell>
  );
};

export const LinkCell: React.FC<CellDefaultProps> = ({ data, rowIndex, columnKey, ...props }) => {
  const hasLink = data.hasIn([rowIndex, columnKey]);
  const text = data.getIn([rowIndex, columnKey, 'text'], '');
  const link = data.getIn([rowIndex, columnKey, 'link'], '');

  return (
    <Cell {...props}>
      {hasLink && (
        <Tooltip title={text}>
          <Link href={link} color="secondary" target="_blank">
            {text}
          </Link>
        </Tooltip>
      )}
    </Cell>
  );
};

interface RadioCellProps {
  selectedRowKey: string;
  rowIndex?: number;
  className?: string;
  onChange: (key: string, rowIndex: number) => void;
  data: List<List<unknown> | Map<string, unknown>> | DynamicDataLoader;
  keyName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkDisabled?: (rowData: Map<string, any>) => boolean;
  msg?: (rowData: Map<string, any>) => string;
}

export const RadioCell: React.FC<RadioCellProps> = ({
  data,
  selectedRowKey,
  rowIndex = -1,
  onChange,
  className,
  keyName,
  checkDisabled,
  msg,
  ...props
}) => {
  const key = data.getIn([rowIndex, keyName]);
  const el = data.get(rowIndex, Map());

  return (
    <Cell {...props} className={clsx('action-cell', className)}>
      <FormControlLabel
        label={msg ? msg(el) : ''}
        control={
          <Radio
            checked={selectedRowKey === key}
            disabled={checkDisabled ? checkDisabled(el) : false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const val = event.target.checked;

              // 注意: 雖然這邊有回傳 rowIndex, 但如果資料順序有異動時，可能還是會不一致
              // 最好還是用 key 取得正確 row
              if (val && rowIndex !== undefined) onChange(key, rowIndex);
            }}
          />
        }
      />
    </Cell>
  );
};

export const CheckboxCell: React.FC<
  CellDefaultProps & { onChange: (rowIndex: number, columnKey: string, checked: boolean) => void }
> = ({ data, rowIndex, columnKey, onChange, className, ...props }) => (
  <Cell {...props} className={clsx('action-cell', className)}>
    <Checkbox
      checked={data.getIn([rowIndex, columnKey])}
      onChange={e => {
        if (rowIndex !== undefined && columnKey !== undefined) onChange(rowIndex, columnKey, e.target.checked);
      }}
    />
  </Cell>
);

interface ButtonCellProps extends CellDefaultProps, Omit<ButtonProps, 'onClick'> {
  onClick: (keyValue: string) => void;
  keyName?: string;
  buttonClassName?: string;
  content?: string;
  checkVisibility?: (rowData: Map<string, any>) => boolean;
}

export const ButtonCell: FC<ButtonCellProps> = ({
  data,
  rowIndex,
  columnKey,
  keyName,
  onClick,
  className,
  buttonClassName,
  variant = 'contained',
  color = 'primary',
  size = 'small',
  content,
  checkVisibility,
  ...props
}) => {
  const el = data.getIn([rowIndex]);
  return (
    <Cell {...props} className={clsx('action-cell', className)}>
      <Button
        className={buttonClassName}
        variant={variant}
        color={color}
        size={size}
        onClick={() => {
          if (keyName) {
            onClick(data.getIn([rowIndex, keyName]));
          } else {
            onClick(data.getIn([rowIndex]));
          }
        }
        }
        //style={checkVisibility ? checkVisibility(el) : 'visibilify'}
        style={{ visibility: checkVisibility ? (checkVisibility(el) ? 'visible' : 'collapse') : 'visible' }}
      >
        {content ? content : data.getIn([rowIndex, columnKey])}
      </Button>
    </Cell >
  );
};
