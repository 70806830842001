import { fetch } from 'whatwg-fetch';
import { logError } from './logger';
import STATUS_CODE from 'constant/StatusCode';
import { isMoment } from 'moment';
import DATETIME from 'constant/datetime';

export const urlSearchToObject = (parameterName?: string) => {
  const search = location.search.substring(1);

  if (search === '' || search === null) return null;

  const o = JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}',
  );

  return parameterName ? o[parameterName] : o;
};

export type Result<T> = T & {
  ''?: string[];
  statusCode: number;
  status: string;
};

export interface FetchGet {
  <T>(pathname: string, options?: { param?: object | string }): Promise<Result<T | {}>>;
}

export interface FetchPost {
  <T>(param: {
    body: string | FormData | object;
    pathname: string;
    contentType?: string | null;
    headers?: { [key: string]: string };
  }): Promise<Result<T | {}>>;
}

export interface FetchDelete {
  (pathname, body?: { signature: string; plainText: string; certSN: string }): Promise<Result<{}>>;
}

const FetchStatusCodeError = new Error('Fetch status code is greater then 500');

// 處理回傳狀態
const handleStatus = async <T>(resp: Response): Promise<Result<T>> => {
  if (resp.status >= 500) throw FetchStatusCodeError;

  return resp.json();
};

const handleCatch = (e: Error): Result<{}> => {
  logError(e, 'something happend when fetching');

  return { statusCode: STATUS_CODE.NETWORK_ERROR, status: 'network error' };
};

export const fetchPost: FetchPost = ({ body, pathname, contentType = 'application/json', headers = {} }) => {
  const composeHeaders = contentType !== null ? { 'Content-Type': contentType, ...headers } : headers;

  if (typeof body !== 'string' && body.constructor !== FormData) {
    Object.entries(body).forEach(([k, v]) => {
      if ([undefined, null, ''].every(invalid => v === invalid)) delete body[k];

      if (isMoment(v)) body[k] = v.format(DATETIME.UNIVERSAL_DATE_FORMAT);
    });

    body = JSON.stringify(body);
  }

  return fetch(`/api${pathname}`, {
    method: 'POST',
    cache: 'no-cache',
    mode: 'no-cors',
    headers: composeHeaders,
    body,
    credentials: 'include',
  })
    .then(handleStatus)
    .catch(handleCatch);
};

export const fetchGet: FetchGet = (pathname, options = { param: '' }) => {
  let { param = '' } = options;

  if (typeof param === 'object')
    param = Object.entries(param)
      .filter(({ 1: v }): boolean => [undefined, null, ''].every(invalid => v !== invalid))
      .map(([k, v]): string => {
        if (isMoment(v)) v = v.format(DATETIME.UNIVERSAL_DATE_FORMAT);

        return `${k}=${v}`;
      })
      .join('&');

  return fetch(`/api${pathname}?ts=${Date.now()}&${param}`, {
    method: 'GET',
    credentials: 'include',
  })
    .then(handleStatus)
    .catch(handleCatch);
};

export const fetchGetPdf: FetchGet = (pathname, options = { param: '' }) => {
  let { param = '' } = options;

  if (typeof param === 'object')
    param = Object.entries(param)
      .filter(({ 1: v }): boolean => [undefined, null, ''].every(invalid => v !== invalid))
      .map(([k, v]): string => {
        if (isMoment(v)) v = v.format(DATETIME.UNIVERSAL_DATE_FORMAT);

        return `${k}=${v}`;
      })
      .join('&');

  return fetch(`/api${pathname}?ts=${Date.now()}&${param}`, {
    method: 'GET',
    credentials: 'include',
  })
    .then(
      async <T>(resp: Response): Promise<Blob> => {
        if (resp.headers.get('Content-Type') === 'application/pdf') {
          return resp.blob();
        } else {
          throw FetchStatusCodeError;
        }
      },
    )
    .then(blob => {
      var url = window.URL.createObjectURL(blob); // create url from blob
      var fileLink = document.createElement('a'); // create link for file
      fileLink.href = url;
      fileLink.target = '_blank';
      //fileLink.download = saveName; // download filename
      document.body.appendChild(fileLink); // append file link to download
      fileLink.click();
      fileLink.remove(); // remove file link after click
    });
};

export const fetchDelete: FetchDelete = (pathname, body) => {
  return fetch(`/api${pathname}`, {
    credentials: 'include',
    method: 'DELETE',
    cache: 'no-cache',
    mode: 'no-cors',
    body,
  })
    .then(handleStatus)
    .catch(handleCatch);
};
