import { logout } from 'site/bond/action/userAction';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import LogoutButton, { DispatchToProps, OwnProps } from './LogoutButton';

export default connect<{}, DispatchToProps, OwnProps>(
  null,
  (dispatch: Dispatch): DispatchToProps => ({
    push: (pathname): void => {
      dispatch(push(pathname));
    },
    logout: (): void => {
      dispatch(logout());
    },
  }),
)(LogoutButton);
