/* eslint-disable react/jsx-handler-names */
import React, { Component } from 'react';
import { InventoryTable } from 'site/management/component/page/bond/Account/Inventory/InventoryTable';
import { Result } from 'lib/url';
import BondSuggestionRWD from 'component/suggestion/BondSuggestion/BondSuggestionRWD';
import { BondSuggestionItem } from 'component/suggestion/BondSuggestion/BondSuggestionList';
import { BondSuggestionChangeBond, BondSuggestionSearchRule } from 'component/suggestion/BondSuggestion/BondSuggestion';
import { Box, Button } from '@material-ui/core';
import { stringCompare } from 'lib/compare';
import XlsxButton from 'site/management/component/button/XlsxButton';

interface InventorySearchWrapperProps {
  data?: Result<{
    result: BondSuggestionItem[];
  }>;
  WordChangeCallBack?: Function;
  word?: string;
  suggestionData: { result: BondSuggestionItem[] };
}

interface InventorySearchWrapperStates {
  filterData: Result<{
    result: BondSuggestionItem[];
  }>;
  payload: {
    word: string;
    isin: string;
  };
  CsvProps: {
    theader: string[];
    tbody: string[];
  };
  suggestionData: { result: BondSuggestionItem[] };
}

export default class InventorySearchWrapper extends Component<
  InventorySearchWrapperProps,
  InventorySearchWrapperStates
> {
  public state: InventorySearchWrapperStates = {
    filterData: { statusCode: 0, status: '', result: [] },
    payload: { word: '', isin: '' },
    CsvProps: { theader: [], tbody: [] },
    suggestionData: { result: [] },
  };

  public constructor(props) {
    super(props);

    if (this.props.data && this.props.suggestionData.result.length <= 0) {
      let mySuggestionData = {
        result: Object.values(props.data['result'][0]['detail'].reduce(
          (prev: { [key: string]: BondSuggestionItem }, curr: BondSuggestionItem) => {
            if (!(curr.bondCode in prev)) {
              prev[curr.bondCode] = curr;
            }

            return prev;
          },
          {},
        ) as BondSuggestionItem).sort((a: BondSuggestionItem, b: BondSuggestionItem) =>
          stringCompare(a.bondCode, b.bondCode),
        ),
      };
      this.state.filterData = this.props.data;
      this.state.suggestionData = mySuggestionData;
      this.setState({ suggestionData: mySuggestionData, filterData: this.props.data });
    } else if (this.props.data) {
      this.state.suggestionData = this.props.suggestionData;
      this.state.filterData = this.props.data;
      this.setState({ filterData: this.props.data });
    }
  }

  private handleChange = (bond: BondSuggestionChangeBond | null, word: string) => {
    this.setState({ payload: { word, isin: bond ? bond.isin : '' } });
  };

  private static filterIsinOrBondCode(el: BondSuggestionItem, word: string) {
    const regExp = new RegExp(word, 'i');

    return regExp.test(el.bondCode) || regExp.test(el.isin);
  }

  private static filterByBond(el: BondSuggestionItem, bond: BondSuggestionChangeBond) {
    return el.bondCode === bond.bondCode;
  }

  private InventoryTableOnLoaded = (data: string | object) => {
    if (data) {
      if (
        JSON.stringify(this.state.CsvProps.theader) != JSON.stringify(data['columns']) ||
        JSON.stringify(this.state.CsvProps.tbody) != JSON.stringify(data['data'])
      ) {
        this.setState({ ...this.state, CsvProps: { theader: data['columns'], tbody: data['data'] } });
      }
    }
  };

  public render() {
    return (
      <>
        <Box my={2} display="flex" justifyContent="space-between">
          <Box display="flex">
            <Box m={1}>
              <BondSuggestionRWD
                searchRule={BondSuggestionSearchRule.BondCodeAndIsin}
                disabledVerify
                onChange={this.handleChange}
                searchPayload={this.state.payload}
                data={this.state.suggestionData}
              />
            </Box>
            <Box m={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (this.props.WordChangeCallBack) {
                    this.props.WordChangeCallBack(this.state.payload.word, this.state.suggestionData);
                  }
                }}
              >
                查詢
              </Button>
            </Box>
          </Box>
          <Box m={1}>
            <XlsxButton saveName="匯出" theader={this.state.CsvProps.theader} tBody={this.state.CsvProps.tbody}>
              匯出
            </XlsxButton>
          </Box>
        </Box>
        <InventoryTable data={this.state.filterData} onLoaded={this.InventoryTableOnLoaded} />
      </>
    );
  }
}
