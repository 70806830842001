import DesktopMobileSwitch from 'component/common/DesktopMobileSwitch';
import React, { FC } from 'react';
import { BondSuggestionProps } from './BondSuggestion';
import BondSuggestionDesktop from './BondSuggestionDesktop';
import BondSuggestionMobile from './BondSuggestionMobile';

const BondSuggestionRWD: FC<BondSuggestionProps> = ({ ...props }) => (
  <DesktopMobileSwitch desktop={<BondSuggestionDesktop {...props} />} mobile={<BondSuggestionMobile {...props} />} />
);

export default BondSuggestionRWD;
