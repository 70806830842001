const ORDER_STATUS = {
  0: '委託中',
  11: '委託成功',
  12: '委託失敗',
  21: '已成交',
  22: '未成交',
  30: '取消中',
  31: '已取消',
  32: '刪單失敗，委託仍有效',
};

export default ORDER_STATUS;
