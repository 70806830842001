import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  WatchListTable,
  WatchLIstTableDispatchProps,
  WatchListTableOwnProps,
} from 'site/bond/component/page/WatchList/WatchLIstTable';

const WatchListTableContainer = connect<{}, WatchLIstTableDispatchProps, WatchListTableOwnProps>(
  null,
  {
    push,
  },
)(WatchListTable);

export default WatchListTableContainer;
