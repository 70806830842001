import React, { FC } from 'react';
import { EditableStepDialogProps } from 'site/bond/component/dialog/EditableDialog';
import { Box, DialogContent, DialogContentText } from '@material-ui/core';
import { EditableDialogActions } from 'site/bond/component/page/WatchList/EditableDialogActions';

const DeleteWatchListDialogContent: FC<EditableStepDialogProps> = ({ onConfirm, onClose, isMobileWidth }) => {
  return (
    <>
      <DialogContent>
        <Box my={2}>
          <DialogContentText>確認是否刪除勾選商品</DialogContentText>
        </Box>
      </DialogContent>

      <EditableDialogActions onClose={onClose} fullWidth={isMobileWidth} onSubmit={onConfirm} />
    </>
  );
};

export default DeleteWatchListDialogContent;
