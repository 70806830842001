import { Button, createStyles, DialogActions, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      fontWeight: 'normal',
    },
    action: {
      justifyContent: 'center',
    },
  }),
);
export function EditableDialogActions(props: {
  onClose: () => void;
  fullWidth: boolean;
  onSubmit: () => void;
  disableSubmitButton?: boolean;
}) {
  const classes = useStyles();

  return (
    <DialogActions className={classes.action}>
      <Button
        className={classes.button}
        color="secondary"
        variant="outlined"
        onClick={props.onClose}
        fullWidth={props.fullWidth}
      >
        取消
      </Button>
      <Button
        className={classes.button}
        color="secondary"
        variant="contained"
        fullWidth={props.fullWidth}
        onClick={props.onSubmit}
        disabled={props.disableSubmitButton}
      >
        確認送出
      </Button>
    </DialogActions>
  );
}
