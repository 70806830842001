import React, { Component } from 'react';
import { BondSuggestionChangeBond, BondSuggestionSearchRule } from 'component/suggestion/BondSuggestion/BondSuggestion';
import ShellGap from 'site/bond/component/page/Shell/ShellGap';
import BondSuggestionRWD from 'component/suggestion/BondSuggestion/BondSuggestionRWD';
import clsx from 'clsx';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Push } from 'connected-react-router';

export const styles = createStyles({
  root: {
    textAlign: 'center',
    paddingTop: 90,
  },
  desktopInput: {
    width: '100%',
  },
  desktopRootStart: {
    width: 230,
    transition: 'width 0.5s',
  },
  desktopRootEnd: {
    width: '80%',
    transition: 'width 0.5s',
  },
});

export interface SuggestionSearchDispatchToProps {
  push: Push;
}

export interface SuggestionSearchPageOwnProps {
  navigateTo: string;
}
interface SuggestionSearchProps
  extends SuggestionSearchPageOwnProps,
    SuggestionSearchDispatchToProps,
    WithStyles<typeof styles> {}

class SuggestionSearch extends Component<SuggestionSearchProps> {
  public state = {
    isDidMount: false,
    searchPayload: {
      isin: '',
      word: '',
    },
  };

  public componentDidMount(): void {
    this.setState({ isDidMount: true });
  }

  private handleChange = (bond: BondSuggestionChangeBond | null, word: string) => {
    if (bond) {
      this.props.push(`${this.props.navigateTo}/${bond.isin}`);
    } else {
      this.setState({ searchPayload: { isin: '', word } });
    }
  };

  public render() {
    const { classes } = this.props;
    const { isDidMount, searchPayload } = this.state;

    return (
      <ShellGap>
        <div className={classes.root}>
          <BondSuggestionRWD
            api="/product/list"
            onChange={this.handleChange}
            searchPayload={searchPayload}
            searchRule={BondSuggestionSearchRule.NameAndIsin}
            classes={{
              desktopInput: classes.desktopInput,
              desktopRoot: clsx({
                [this.props.classes.desktopRootStart]: !isDidMount,
                [this.props.classes.desktopRootEnd]: isDidMount,
              }),
            }}
          />
        </div>
      </ShellGap>
    );
  }
}

export default withStyles(styles)(SuggestionSearch);
