import React, { FC } from 'react';
import ShellGap from './Shell/ShellGap';
import SearchDatesAndBondPage from 'site/management/component/page/bond/SearchDatesAndBondPage';
import InterestContent from 'site/management/component/page/bond/Account/Interest/InterestContent';

const Interest: FC = () => (
  <ShellGap>
    <SearchDatesAndBondPage
      bondListAPI="/account/interest/bondList"
      contentAPI="/account/interest"
      warningAPI="/maintain/warning/interest"
    >
      <InterestContent />
    </SearchDatesAndBondPage>
  </ShellGap>
);

export default Interest;
