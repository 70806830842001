import { createStyles, Icon, makeStyles } from '@material-ui/core';
import SearchImage from 'asset/image/search.png';
import React, { FC } from 'react';

const useStyle = makeStyles(() =>
  createStyles({
    icon: {
      backgroundImage: `url("${SearchImage}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  }),
);

const SearchIcon: FC = () => {
  const classes = useStyle();

  return <Icon className={classes.icon} />;
};
export default SearchIcon;
