const DATETIME = {
  UNIVERSAL_DATE_FORMAT: 'YYYY/MM/DD',
  UNIVERSAL_TIME_FORMAT: 'HH:mm',
  UNIVERSAL_TIME_WITH_SECOND_FORMAT: 'HH:mm:ss',
  UNIVERSAL_DATE_TIME_FORMAT: 'YYYY/MM/DD HH:mm',
  UNIVERSAL_DATE_TIME_FORMAT_WITH_SECOND: 'YYYY/MM/DD HH:mm:ss',
  //瀏覽器要這格式才看得懂
  UNIVERSAL_DATE_TIME_FORMAT_JS: 'YYYY-MM-DDTHH:mm',
  UNIVERSAL_DATE_FORMAT_JS: 'YYYY-MM-DD',
  ETF_DATE_FORMAT: 'YYYYMMDD',
};

export default DATETIME;
