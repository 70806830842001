import React, { FC, useRef } from 'react';
import { Box, Button, Fade, Grid, InputBase, Paper, Popper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { numberFormatter } from 'lib/number';
import numbro from 'numbro';

interface CellInputProps {
  classes: { [key: string]: string };
  value: number;
  min: number;
  minStep: number;
  onChange: (val: number) => void;
  lessThenMin: boolean;
  valueIsNotMultiple: boolean;
  isTradingTime: boolean;
}

export const BuyContentCellInput: FC<CellInputProps> = ({
  classes,
  value,
  onChange,
  min,
  minStep,
  lessThenMin,
  valueIsNotMultiple,
  isTradingTime,
}) => {
  const rootRef = useRef<HTMLDivElement>();
  const open = (lessThenMin || valueIsNotMultiple) && isTradingTime;
  const id = open ? 'transitions-popper' : undefined;

  let isComposition = false;
  const handleComposition = () => {
    isComposition = true;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //預防中文輸入法情況下數字會亂跳
    if (isComposition) {
      isComposition = false;
      return;
    }
    const val = numbro.unformat(event.target.value || '0');

    if (val !== undefined) onChange(val);
  };

  const handleSubtract = () => {
    onChange(
      numbro(value)
        .subtract(minStep)
        .value(),
    );
  };

  const handleAdd = () => {
    onChange(
      numbro(value)
        .add(minStep)
        .value(),
    );
  };

  return (
    <>
      <Grid item xs={12} sm innerRef={rootRef}>
        <div className={classes.title}>申購面額</div>
        <Box display="flex" className={classes.inputWrapper}>
          <Button
            color="secondary"
            variant="contained"
            className={clsx(classes.addOrSubtract, classes.subtract)}
            onClick={handleSubtract}
            disabled={value <= min}
          >
            -
          </Button>

          <InputBase
            className={classes.input}
            value={numberFormatter(value)}
            onChange={handleChange}
            onCompositionUpdate={handleComposition}
          />

          <Button
            color="secondary"
            variant="contained"
            className={clsx(classes.addOrSubtract, classes.add)}
            onClick={handleAdd}
          >
            +
          </Button>
        </Box>
      </Grid>
      <Popper id={id} open={open} anchorEl={rootRef.current} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Box p={1}>
                {lessThenMin && (
                  <Typography color="primary">輸入面額不得小於最小交易單位({numberFormatter(min)})</Typography>
                )}
                {valueIsNotMultiple && (
                  <Typography color="primary">輸入面額未符合最小增額單位({numberFormatter(minStep)})</Typography>
                )}
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
