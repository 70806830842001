import { Box } from '@material-ui/core';
import Fetch from 'component/common/Fetch';
import WarningBlock from 'component/common/WarningBlock';
import WordingSkeleton from 'component/skeleton/WordingSkeleton';
import React, { FC, useState } from 'react';
import STATUS_CODE from 'constant/StatusCode';
import ErrorContent from 'site/bond/component/error/ErrorContent';
import NoDataIcon from 'component/icon/NoDataIcon';
import InventorySearchWrapper from 'site/management/component/page/bond/Account/Inventory/InventorySearchWrapper';
import SystemType from 'constant/SystemType';
import { BondSuggestionItem } from 'component/suggestion/BondSuggestion/BondSuggestionList';

interface Props {
  api: string;
  hashId: string;
  warningAPI: string;
}

const acceptableStatusCode = [
  STATUS_CODE.ELECTRONIC_TRADING_RESTRICT_REDEEM,
  STATUS_CODE.TRADE_DAY_BUT_CLOSE_TIME,
  STATUS_CODE.SOCKET_OFFLINE_PERIOD,
  STATUS_CODE.MANAGEMENT_RESTRICT_REDEEM,
  STATUS_CODE.CannotFindBondCode,
];

export const ErrorComponent: FC = () => (
  <ErrorContent>
    <NoDataIcon />
  </ErrorContent>
);

const InventoryContent: FC<Props> = ({ api, hashId, warningAPI }) => {
  let [state, setState] = useState({ word: '', suggestionData: { result: [] } });
  const basepath = sessionStorage.getItem('basepath') || '';
  let param = { systemType: SystemType[basepath], hashId: hashId, word: state.word };
  const wordChange = (myWord, mySuggestionData) => {
    if (state.suggestionData.result.length == 0 || myWord === '') {
      setState({ ...state, word: myWord, suggestionData: mySuggestionData });
    } else {
      setState({ ...state, word: myWord });
    }
  };

  return (
    <>
      <Fetch url={api} param={param} errorComponent={<ErrorComponent />} acceptableStatusCode={acceptableStatusCode}>
        <InventorySearchWrapper
          WordChangeCallBack={wordChange}
          word={state.word}
          suggestionData={state.suggestionData}
        />
      </Fetch>
      <Box my={2}>
        <Fetch url={warningAPI} loadingComponent={<WordingSkeleton />}>
          <WarningBlock />
        </Fetch>
      </Box>
    </>
  );
};

export default InventoryContent;
