import React, { FC } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Push } from 'connected-react-router';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      color: '#8e8e8e',
      fontSize: 21,
      padding: '0 30px',
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    activeItem: {
      color: theme.palette.primary.main,
    },
    divider: {
      borderRight: '1px solid #eee',
    },
  }),
);

export interface AdvanceMenuDispatchProps {
  push: Push;
}

export interface AdvanceMenuStateToProps {
  pathname: string;
}

const AdvanceMenu: FC<AdvanceMenuDispatchProps & AdvanceMenuStateToProps> = ({ push, pathname }) => {
  const classes = useStyles();
  const testPath = (expectPath: string) => new RegExp(`${expectPath}$`, 'i').test(pathname);

  const handleClick = (path: string) => () => {
    push(`/advance/${path}`);
  };

  return (
    <Box display="inline-flex">
      <Box
        className={clsx(classes.item, { [classes.activeItem]: testPath('watchList') })}
        onClick={handleClick('watchList')}
      >
        觀察名單
      </Box>
      <div className={classes.divider} />
      <Box
        className={clsx(classes.item, { [classes.activeItem]: testPath('combination') })}
        onClick={handleClick('combination')}
      >
        債券組合
      </Box>
    </Box>
  );
};

export default AdvanceMenu;
