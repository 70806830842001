import { Component } from 'react';
import sensors from 'sa-sdk-javascript';
import tabList from 'site/bond/config/tabList';

export interface SensorStatesToProps {
  routeKey: string;
  pathname: string;
}

let env;

switch (process.env['NODE_EVN']) {
  case 'development':
    env = '(開發環境)';
    break;
  case 'staging':
    env = '(測試環境)';
    break;
  case 'production':
  default:
    env = '';
}

class Sensor extends Component<SensorStatesToProps> {
  private pathList = tabList.concat({ name: '登入', link: '/login' });

  public componentDidMount() {
    this.sendPageView();
  }

  private sendPageView() {
    const { pathname } = this.props;

    const item = this.pathList.find(el => new RegExp(`^${el.link}`, 'i').test(pathname === '/' ? '/list' : pathname));

    document.title = `永豐金證券海外債券電子平台${env} - ${item ? item.name : '查無頁面'}`;
    sensors.quick('autoTrackSinglePage');
  }

  public componentDidUpdate(prevProps: Readonly<SensorStatesToProps>): void {
    const { routeKey } = this.props;

    if (prevProps.routeKey !== routeKey) {
      this.sendPageView();
    }
  }

  public render() {
    return null;
  }
}

export default Sensor;
