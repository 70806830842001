import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 13,
      lineHeight: '18px',
      margin: `${theme.spacing(3)}px 0`,

      '& ol, & ul': {
        paddingLeft: 24,

        '& > li': {
          marginBottom: 6.5,
        },
      },
      '& > p': {
        margin: `${theme.spacing(1)}px 0`,
      },
    },
  }),
);

interface Props {
  data?: {
    result: string[];
  };
}

const WarningBlock: FC<Props> = ({ data = { result: [] } }) => {
  const classes = useStyles();
  const warning = decodeURIComponent(data.result.length > 0 ? data.result[0] : '');

  // 維護編輯區塊，html 格式
  // eslint-disable-next-line
  return <div className={classes.root} dangerouslySetInnerHTML={{ __html: warning }} />;
};

export default WarningBlock;
