import React, { FC } from 'react';
import Knowledge from 'site/bond/component/page/Knowledge/index';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        marginTop: 50,
      },
    },
  }),
);

export const KnowledgePage: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Knowledge />
    </Box>
  );
};
