import { SortingDirection } from 'component/common/fxTable/HeaderCell';
import DataType from 'constant/datatype';
import DATETIME from 'constant/datetime';
import moment from 'moment';
import numbro from 'numbro';
import { charactorIsChinese } from './string';

/**
 * @description 字串比對
 * @param x 比對字串一
 * @param y 比對字串二
 *
 * @returns 1 表示 x > y
 *          0 表示 x === y
 *          -1 表示 x < y
 */
export const stringCompare = (x: string, y: string): number => {
  if (x.length === 0 && y.length === 0) return 0;
  else if (x.length === 0) return -1;
  else if (y.length === 0) return 1;

  const x1 = x.substr(0, 1);
  const y1 = y.substr(0, 1);
  const x1IsChinese = charactorIsChinese(x.charCodeAt(0));
  const y1IsChinese = charactorIsChinese(y.charCodeAt(0));
  let result: number;

  // 當字元都是中文時比較
  if (x1IsChinese && y1IsChinese) {
    result = x1.localeCompare(y1, 'zh-Hant');
  } else {
    // 中文大於英數
    if (x1IsChinese) return 1;
    if (y1IsChinese) return -1;

    result = x1.localeCompare(y1);
  }

  return result === 0 ? stringCompare(x.substr(1), y.substr(1)) : result;
};

/**
 * @description 數值比對
 * @param x 比對數值一
 * @param y 比對數值二
 *
 * @returns 1 表示 x > y
 *          0 表示 x === y
 *          -1 表示 x < y
 */
export const numberCompare = (x: string | number, y: string | number) => {
  const xV = numbro(x).value();
  const yV = numbro(y).value();

  return xV - yV;
};

/**
 * @description 日期比對
 * @param x 比對日期一
 * @param y 比對日期二
 *
 * @returns 1 表示 x > y
 *          0 表示 x === y
 *          -1 表示 x < y
 */
export const dateCompare = (x: string, y: string, format = DATETIME.UNIVERSAL_DATE_FORMAT) => {
  const xV = moment(x, format);
  const yV = moment(y, format);

  if (xV.isAfter(yV)) return 1;

  return xV.isBefore(yV) ? -1 : 0;
};

export const compare = (x, y, dataType: DataType, dir: SortingDirection) => {
  let sort = 0;
  // 如果資料為 null/undefined 時，不管是遞增或遞減，該筆將會在最下面

  if (x === y) {
    return 0 * dir;
  } else if (x === undefined || x === null) {
    return -1 * dir;
  } else if (y === undefined || y === null) {
    return 1 * dir;
  }

  //if (x === undefined || x === null) return y === undefined || y === null ? 0 : 1;
  //else if (y === undefined || y === null) return -1 * dir;

  switch (dataType) {
    case DataType.Number:
    case DataType.NumberWithMantissa2:
    case DataType.NumberWithMantissa2AndPercentageSign:
    case DataType.NumberWithMantissa2AndPreserveZero:
      sort = numberCompare(x, y);
      break;
    case DataType.Date:
      sort = dateCompare(x, y);
      break;
    case DataType.TimeWithSecond:
      sort = dateCompare(x, y, DATETIME.UNIVERSAL_TIME_WITH_SECOND_FORMAT);
      break;
    case DataType.Boolean:
      sort = numberCompare(x ? 1 : 0, y ? 1 : 0);
      break;
    case DataType.String:
      sort = stringCompare(x, y);
      break;
    default:
      sort = stringCompare(x.toString(), y.toString());
      break;
  }
  return sort * dir;
};
