import { createStyles, makeStyles } from '@material-ui/core';
import { CellDefaultProps } from 'component/common/fxTable/Cell';
import DATA from 'constant/data';
import { Cell } from 'fixed-data-table-2';
import React, { FC } from 'react';
import palette from 'style/palette';
import { IsBondSite } from 'constant/site';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      color: palette.link,
      textDecoration: 'none',
      fontSize: '14px',
    },
  }),
);

const SNStockTargetCell: FC<CellDefaultProps> = ({ rowIndex, data, ...props }) => {
  const classes = useStyles();
  const text = data.getIn([rowIndex, 'productNameChinese'], DATA.NULL);
  let underlying = data.getIn([rowIndex, 'underlying'], DATA.NULL);
  underlying = underlying.split(' ')[0];

  return (
    <Cell {...props}>
      <>
        <a
          target={'_blank'}
          href={
            IsBondSite
              ? `/api/newweb/TradingCenter_TWStocks_SubBrokerage/?tab=2&symbol=${underlying}&exch=NASDAQ`
              : `${process.env['REACT_APP_NEWWEB']}TradingCenter_TWStocks_SubBrokerage/?tab=2&symbol=${underlying}&exch=NASDAQ`
          }
          className={classes.link}
          rel="noopener noreferrer"
        >
          {text}
        </a>
      </>
    </Cell>
  );
};

const createSNStockTargetCell = data => <SNStockTargetCell data={data} />;

export default createSNStockTargetCell;
