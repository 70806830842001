import { createStyles, makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';
import clsx from 'clsx';

const useStyle = makeStyles(() =>
  createStyles({
    root: { width: 'auto', maxWidth: 300, height: 'auto' },
    st0: { fill: '#E0E0E0' },
    st1: { fill: '#EDEDED' },
    st2: { fill: '#CCCCCC' },
    st3: { fill: '#939393' },
    st4: { fontSize: 58.3934 },
  }),
);

const NoDataIcon: FC = () => {
  const classes = useStyle();

  return (
    <SvgIcon viewBox="0 0 351.4 486.4" className={classes.root}>
      <g>
        <g>
          <polygon className={classes.st0} points="71.6,263.6 65.6,8.8 252.6,12.8 		" />
          <polygon className={classes.st1} points="192.6,263.6 71.6,263.6 69.6,10.8 252.6,12.8 252.6,203.6 		" />
          <polygon className={classes.st2} points="188.6,204.6 252.6,203.6 192.6,263.6 		" />
          <polygon className={classes.st0} points="192.6,263.6 192.6,205.6 252.6,203.6 		" />
          <g>
            <g>
              <g>
                <path
                  className={classes.st3}
                  d="M110.8,116.7c-2.1-5.8,1.8-12.6,8.7-15.1s14.2,0.2,16.4,6c2.1,5.8-1.8,12.6-8.7,15.1
						C120.3,125.2,113,122.5,110.8,116.7z"
                />
              </g>
            </g>
            <g>
              <g>
                <ellipse
                  transform="matrix(0.342 -0.9397 0.9397 0.342 26.7756 262.5436)"
                  className={classes.st3}
                  cx="200.9"
                  cy="112.2"
                  rx="11.1"
                  ry="13.3"
                />
              </g>
            </g>
            <g>
              <path
                className={classes.st3}
                d="M199.2,169.6c-1.3,0-2.6-0.6-3.5-1.7c-6-7.8-18.4-12.8-32.3-13c-14.3-0.2-27,4.7-34,13.1
					c-1.5,1.9-4.3,2.1-6.2,0.6c-1.9-1.5-2.1-4.3-0.6-6.2c8.5-10.3,23.3-16.3,39.8-16.3c0.4,0,0.7,0,1,0c16.5,0.3,31.5,6.5,39.1,16.4
					c1.5,1.9,1.1,4.7-0.8,6.1C201.1,169.3,200.2,169.6,199.2,169.6z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            className={classes.st3}
            d="M110.2,319.8c0,7.9-2,14-5.9,18.2s-9.5,6.4-16.9,6.4s-12.9-2.1-16.9-6.4c-3.9-4.3-5.9-10.4-5.9-18.3
			s2-14,5.9-18.2s9.6-6.3,16.9-6.3s13,2.1,16.9,6.3C108.3,305.8,110.2,311.9,110.2,319.8z M75.4,319.8c0,5.3,1,9.4,3,12.1s5,4,9.1,4
			c8.1,0,12.1-5.4,12.1-16.1c0-10.8-4-16.1-12.1-16.1c-4,0-7.1,1.4-9.1,4.1C76.4,310.5,75.4,314.5,75.4,319.8z"
          />
          <path
            className={classes.st3}
            d="M152.5,325.5c0,6-1.6,10.6-4.7,14c-3.1,3.4-7.5,5-13.1,5c-3.5,0-6.6-0.8-9.3-2.3c-2.7-1.5-4.7-3.7-6.2-6.6
			s-2.2-6.2-2.2-10.1c0-6,1.6-10.6,4.7-13.9c3.1-3.3,7.5-5,13.1-5c3.5,0,6.6,0.8,9.3,2.3s4.7,3.7,6.2,6.6
			C151.8,318.3,152.5,321.6,152.5,325.5z M127.3,325.5c0,3.6,0.6,6.4,1.8,8.2s3.1,2.8,5.8,2.8c2.7,0,4.6-0.9,5.7-2.8
			c1.2-1.8,1.8-4.6,1.8-8.2c0-3.6-0.6-6.3-1.8-8.1c-1.2-1.8-3.1-2.7-5.8-2.7c-2.7,0-4.6,0.9-5.8,2.7
			C127.9,319.1,127.3,321.8,127.3,325.5z"
          />
          <path
            className={classes.st3}
            d="M180.9,344.5c-4.3,0-7.7-1.6-10.1-4.7h-0.5c0.4,3,0.5,4.8,0.5,5.3v14.8h-10v-52.6h8.1l1.4,4.7h0.5
			c2.3-3.6,5.8-5.4,10.4-5.4c4.3,0,7.7,1.7,10.1,5s3.7,8,3.7,13.9c0,3.9-0.6,7.3-1.7,10.2c-1.1,2.9-2.8,5.1-4.9,6.6
			C186.2,343.7,183.7,344.5,180.9,344.5z M177.9,314.5c-2.5,0-4.3,0.8-5.4,2.3c-1.1,1.5-1.7,4-1.8,7.5v1.1c0,3.9,0.6,6.7,1.8,8.4
			c1.2,1.7,3,2.5,5.5,2.5c4.5,0,6.7-3.7,6.7-11c0-3.6-0.5-6.3-1.6-8.1C182,315.5,180.3,314.5,177.9,314.5z"
          />
          <path
            className={classes.st3}
            d="M228.6,333c0,3.8-1.3,6.6-3.9,8.6c-2.6,2-6.5,2.9-11.7,2.9c-2.7,0-4.9-0.2-6.8-0.5c-1.9-0.4-3.6-0.9-5.3-1.6
			v-8.2c1.9,0.9,3.9,1.6,6.3,2.2s4.4,0.9,6.1,0.9c3.6,0,5.4-1,5.4-3.1c0-0.8-0.2-1.4-0.7-1.9s-1.3-1-2.5-1.7
			c-1.2-0.6-2.8-1.3-4.7-2.2c-2.8-1.2-4.9-2.3-6.2-3.3c-1.3-1-2.3-2.1-2.9-3.5c-0.6-1.3-0.9-2.9-0.9-4.8c0-3.3,1.3-5.8,3.8-7.5
			c2.5-1.8,6.1-2.7,10.7-2.7c4.4,0,8.7,1,12.9,2.9l-3,7.2c-1.8-0.8-3.5-1.4-5.1-1.9s-3.2-0.8-4.9-0.8c-2.9,0-4.4,0.8-4.4,2.4
			c0,0.9,0.5,1.7,1.4,2.3s3,1.6,6.2,2.9c2.9,1.2,5,2.2,6.3,3.2c1.3,1,2.3,2.2,2.9,3.5C228.3,329.6,228.6,331.1,228.6,333z"
          />
          <path
            className={classes.st3}
            d="M235,339.1c0-1.8,0.5-3.2,1.5-4.1s2.4-1.4,4.3-1.4c1.8,0,3.2,0.5,4.2,1.4c1,1,1.5,2.3,1.5,4.1
			c0,1.7-0.5,3.1-1.5,4.1c-1,1-2.4,1.5-4.2,1.5c-1.8,0-3.3-0.5-4.3-1.5C235.5,342.3,235,340.9,235,339.1z"
          />
          <path
            className={classes.st3}
            d="M254.1,339.1c0-1.8,0.5-3.2,1.5-4.1s2.4-1.4,4.3-1.4c1.8,0,3.2,0.5,4.2,1.4s1.5,2.3,1.5,4.1
			c0,1.7-0.5,3.1-1.5,4.1c-1,1-2.4,1.5-4.2,1.5c-1.8,0-3.3-0.5-4.3-1.5C254.6,342.3,254.1,340.9,254.1,339.1z"
          />
          <path
            className={classes.st3}
            d="M273.2,339.1c0-1.8,0.5-3.2,1.5-4.1s2.4-1.4,4.3-1.4c1.8,0,3.2,0.5,4.2,1.4s1.5,2.3,1.5,4.1
			c0,1.7-0.5,3.1-1.5,4.1c-1,1-2.4,1.5-4.2,1.5c-1.8,0-3.3-0.5-4.3-1.5C273.7,342.3,273.2,340.9,273.2,339.1z"
          />
        </g>
      </g>
      <text transform="matrix(1 0 0 1 -0.51 466.4071)" className={clsx(classes.st4)}>
        目前查無資料
      </text>
    </SvgIcon>
  );
};
export default NoDataIcon;
