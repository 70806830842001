import { Box, Divider, List, ListItem, ListItemText, Portal, Slide, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import tabList from 'site/bond/config/tabList';
import LogoutButtonContainer from 'site/bond/component/button/LogoutButtonContainer';
import PortalMask from 'component/common/PortalMask';
import { UserAvatarContainer } from 'site/bond/component/page/Shell/UserAvatorContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userBlock: {
      backgroundColor: '#cb2335',
    },
    root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: 152,
      zIndex: 151,
      overflow: 'auto',
    },
    listItem: {
      textAlign: 'center',

      '& .MuiTypography-root': {
        fontSize: 17,
        paddingBottom: 3,
      },

      '&.MuiListItem-root.Mui-selected': {
        backgroundColor: 'inherit',

        '& .MuiListItemText-root .MuiTypography-root': {
          borderBottom: `3px solid ${theme.palette.primary.contrastText}`,
        },
      },
    },
    divider: {
      width: 115,
      margin: '0 auto',
    },
    mask: {
      backgroundColor: 'rgba(0, 0, 0, 0.49)',
      zIndex: 150,
    },
  }),
);

interface Props {
  onChange: (i: number) => void;
  onCancle: () => void;
  value: number;
  newWebPath: string;
}

const ShellSideBar: React.FC<Props> = ({ newWebPath, onCancle, onChange, value }) => {
  const classes = useStyles();
  const body = document.querySelector('body');

  useEffect(() => {
    body && (body.style.overflow = 'hidden');

    return () => {
      body && (body.style.overflow = 'auto');
    };
  });

  return (
    <Portal container={body}>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <div className={classes.root}>
          <Box py="22px" textAlign="center" px="10px" className={classes.userBlock}>
            <UserAvatarContainer />
            <Box py={1} />
            <LogoutButtonContainer color="inherit" fullWidth />
          </Box>

          <Divider />

          <List>
            {tabList.map(({ name, link }, i) => (
              <ListItem
                className={classes.listItem}
                button
                key={link}
                onClick={() => onChange(i)}
                selected={value === i}
              >
                <ListItemText primary={name} />
              </ListItem>
            ))}
            <Divider className={classes.divider} />
            <ListItem className={classes.listItem} button component="a" href={newWebPath} target="_blank">
              <ListItemText primary="新理財網" />
            </ListItem>
          </List>
          <PortalMask className={classes.mask} onClick={onCancle} />
        </div>
      </Slide>
    </Portal>
  );
};

export default ShellSideBar;
