import sensors from 'sa-sdk-javascript';

const Sensors = {
  init: () => {
    sensors.init({
      // eslint-disable-next-line @typescript-eslint/camelcase
      sdk_url: 'https://cdn.jsdelivr.net/npm/sa-sdk-javascript@1.14.11/sensorsdata.min.js',
      // eslint-disable-next-line @typescript-eslint/camelcase
      heatmap_url: 'https://cdn.jsdelivr.net/npm/sa-sdk-javascript@1.14.11/heatmap.min.js',
      name: 'sensors',
      // eslint-disable-next-line @typescript-eslint/camelcase
      server_url: process.env['REACT_APP_SENSORS_SERVER'],
      // eslint-disable-next-line @typescript-eslint/camelcase
      show_log: process.env['NODE_ENV'] !== 'production',
    });
    // sensors.quick('autoTrack');
    sensors.registerPage({
      platform: window.outerWidth > 768 ? 'PC端' : '移動端',
    });
  },

  signIn(identification) {
    sensors.login(identification);
  },

  logout() {
    sensors.logout();
  },
};

export default Sensors;
