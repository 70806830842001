import { Box, Button, createStyles, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Map } from 'immutable';

interface Props {
  data: Map<string, string>;
  onClose: () => void;
  onConfirm: () => void;
  isMobileWidth: boolean;
  classes: { content: string };
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      fontWeight: 'normal',
    },
    textBold: {
      fontWeight: 'bold',
    },
    action: {
      justifyContent: 'center',
    },
  }),
);

const CancleDialogStepConfirm: FC<Props> = ({ data, onClose, onConfirm, isMobileWidth, classes: propsClasses }) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={propsClasses.content}>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            委託日期：
          </Typography>
          <Typography component="span">{data.get('entrustDate', '')}</Typography>
        </Box>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            委託時間：
          </Typography>
          <Typography component="span">{data.get('entrustTime', '')}</Typography>
        </Box>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            交易類別：
          </Typography>
          <Typography component="span">{data.get('bs', '')}</Typography>
        </Box>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            ISIN：
          </Typography>
          <Typography component="span">{data.get('isin', '')}</Typography>
        </Box>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            商品代碼：
          </Typography>
          <Typography component="span">{data.get('bondCode', '')}</Typography>
        </Box>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            委託面額：
          </Typography>
          <Typography component="span">{data.get('orderFaceValue', '')}</Typography>
        </Box>
        <Box my="2px">
          <Typography className={classes.textBold} component="span">
            委託價格：
          </Typography>
          <Typography component="span">{data.get('orderPrice', '')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={onClose}
          fullWidth={isMobileWidth}
        >
          取消
        </Button>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          fullWidth={isMobileWidth}
          onClick={onConfirm}
        >
          確認送出
        </Button>
      </DialogActions>
    </>
  );
};

export default CancleDialogStepConfirm;
