import ComplexTable from 'component/table/ComplexTable';
import { BOND_FIELDS } from 'config/field';
import Immutable from 'immutable';
import React, { FC, useEffect, useMemo, useState } from 'react';
import DataType from 'constant/datatype';
import { Result } from 'lib/url';
import { Box, Tab, Tabs } from '@material-ui/core';

const Category = {
  all: '全部',
  bond: '海外債',
  sn: '結構債',
};

const detailColumns = [
  BOND_FIELDS.ASSIGN_DATE,
  BOND_FIELDS.ISIN,
  BOND_FIELDS.BOND_CODE,
  BOND_FIELDS.COUPON,
  BOND_FIELDS.MATURITY_DATE,
  { header: '交易類別', columnKey: 'tradeCategory', dataType: DataType.String },
  BOND_FIELDS.CURRENCY,
  { ...BOND_FIELDS.ORDER_FACE_VALUE, header: '成交面額' },
  { ...BOND_FIELDS.ORDER_PRICE, header: '成交價格(%)' },
  { header: '賣出成交金額', columnKey: 'deliveryAmt', dataType: DataType.NumberWithMantissa2AndPreserveZero },
  { header: '賣出前手息', columnKey: 'deliveryDividend', dataType: DataType.NumberWithMantissa2AndPreserveZero },
  { ...BOND_FIELDS.COST, header: '原始取得成本' },
  { header: '原始買進前手息', columnKey: 'orgDividend', dataType: DataType.NumberWithMantissa2AndPreserveZero },
  { header: '累積債息', columnKey: 'totalInterest', dataType: DataType.NumberWithMantissa2AndPreserveZero },
  BOND_FIELDS.PROFIT,
  BOND_FIELDS.ROI,
  BOND_FIELDS.PROFIT_WITH_INT,
  BOND_FIELDS.ROI_WITH_INT,
];

const summaryColumns = [
  BOND_FIELDS.CURRENCY,
  { ...BOND_FIELDS.PROFIT, header: '總損益(不含債息)' },
  { ...BOND_FIELDS.ROI, header: '總報酬率%(不含債息)' },
  { ...BOND_FIELDS.PROFIT_WITH_INT, header: '總損益(含債息)' },
  { ...BOND_FIELDS.ROI_WITH_INT, header: '總報酬率%(含債息)' },
];

interface Props {
  data?: Result<{
    result: {}[];
  }>;
  onLoaded?: (data: object | string) => void;
}

const RealizedProfitContent: FC<Props> = ({ data = { result: [], statusCode: -1, status }, onLoaded }) => {
  const [tab, setTab] = useState(Object.keys(Category)[0]);
  const idata = useMemo(() => Immutable.fromJS(data), [data]);
  const [detail, setDetail] = useState(Immutable.List());
  const [summary, setSummary] = useState(Immutable.List());

  useEffect(() => {
    let detailData: any;
    let detailTmp = [] as any;
    let summaryData: any;

    if (tab === 'all') {
      detailData = data['result'][0]['detail'];
      summaryData = data['result'][0]['summary'];
    } else if (tab === 'bond') {
      summaryData = data['result'][0]['summaryBond'];
      for (let i = 0; i < data['result'][0]['detail'].length; i++) {
        if (data['result'][0]['detail'][i]['isBondProduct'] == true) {
          detailTmp.push(data['result'][0]['detail'][i]);
        }
      }
      detailData = detailTmp;
    } else {
      summaryData = data['result'][0]['summarySN'];
      for (let i = 0; i < data['result'][0]['detail'].length; i++) {
        if (data['result'][0]['detail'][i]['isBondProduct'] == false) {
          detailTmp.push(data['result'][0]['detail'][i]);
        }
      }
      detailData = detailTmp;
    }

    if (onLoaded) {
      onLoaded({
        columns: detailColumns,
        data: detailData,
      });
    }

    let immDetail = Immutable.fromJS(detailData);
    let immSummary = Immutable.fromJS(summaryData);
    setDetail(immDetail);
    setSummary(immSummary);
  }, [data, idata, onLoaded, tab]);

  return (
    <>
      <Box mb={2}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          onChange={(e, v): void => {
            setTab(v);
          }}
        >
          {Object.entries(Category).map(
            ([k, v]): JSX.Element => (
              <Tab key={k} label={v} value={k} />
            ),
          )}
        </Tabs>
        <ComplexTable data={detail} columns={detailColumns} initWithoutSorting />
      </Box>
      <ComplexTable data={summary} columns={summaryColumns} />
    </>
  );
};

export default RealizedProfitContent;
