import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import Breakpoints from 'constant/breakpoints';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const themeOptions: ThemeOptions = {
  props: {
    MuiTooltip: {
      enterTouchDelay: 0,
    },
  },
  typography: {
    fontFamily: "'微軟正黑體', ' Arial ', ' Heiti TC ', 'Apple LiGothic Medium'",
  },
  breakpoints: {
    values: Breakpoints,
  },
  palette,
  overrides: {
    MuiAppBar: {
      colorPrimary: { backgroundColor: palette.primary.dark },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${palette.secondary.main}`,
        },
      },
    },
    MuiInputLabel: { outlined: { transform: 'translate(14px, 15px) scale(1)' } },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: palette.secondary.main,
        },
      },
      input: {
        padding: '12px',
      },
      notchedOutline: {
        borderColor: '#535353',
        borderRadius: 3,
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': { color: palette.secondary.main },
      },
    },
    MuiButton: {
      root: {
        fontSize: 18,
        borderRadius: 3,
        fontWeight: 'bold',
      },
      outlined: {
        padding: '3.5px 17px',
      },
      contained: {
        padding: '4.5px 17px',
      },
      outlinedPrimary: {
        border: `1px solid ${palette.primary.light}`,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#DBDBDB',
      },
    },
    MuiTooltip: {
      tooltip: { fontSize: '1rem' },
    },
    MuiTabs: {
      root: {
        minHeight: 'auto',
      },
      scrollable: {
        overflowX: 'auto',
      },
    },
    MuiCardActions: {
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: 8,
        },
      },
    },
    MuiDialogActions: {
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: 8,
        },
      },
    },
    MuiExpansionPanelActions: {
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: 8,
        },
      },
    },
  },
};

const theme = createMuiTheme(themeOptions);

export default theme;
