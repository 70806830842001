import STATUS_CODE from 'constant/StatusCode';
import React, { FC } from 'react';
import StatusCodeCheckDialog, {
  StatusCodeCheckDialogWrapperProps,
} from 'site/bond/component/dialog/StatusCodeCheckDialog';

const Warning: FC = () => (
  <div>
    <div>目前非服務時段，請稍後再試。</div>
    <div>（暫停服務時段為每日晚間19:30~20:02、清晨05:30~07:22及每週日凌晨02:00~07:22）</div>
  </div>
);

const SocketOfflinePeriodDialog: FC<StatusCodeCheckDialogWrapperProps> = ({ statusCode }) => (
  <StatusCodeCheckDialog
    statusCode={statusCode}
    validStatusCode={STATUS_CODE.SOCKET_OFFLINE_PERIOD}
    warning={<Warning />}
  />
);

export default SocketOfflinePeriodDialog;
