import React from 'react';
import EmbeddedContainer from 'site/bond/component/page/EmbeddedContainer';
import AnalyticsSearchContainer from 'site/bond/component/page/Analytics/AnalyticsSearchContainer';

export function AnalyticsPage(props: { isin: string }) {
  return (
    <>
      <AnalyticsSearchContainer />
      <EmbeddedContainer url={`/bond/BondAnalysis_Iframe.aspx?${props.isin ? `isinCode=${props.isin}` : ''}`} />
    </>
  );
}
