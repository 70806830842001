import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { AnalyticsSearch, AnalyticsSearchDispatchToProps } from 'site/bond/component/page/Analytics/AnalyticsSearch';
import { AdvanceMenuStateToProps } from 'site/bond/component/page/Advance/AdvanceMenu';
import { Map } from 'immutable';

const AnalyticsSearchContainer = connect<
  AdvanceMenuStateToProps,
  AnalyticsSearchDispatchToProps,
  {},
  Map<string, string>
>(
  state => ({
    pathname: state.getIn(['router', 'location', 'pathname']),
  }),
  { push },
)(AnalyticsSearch);

export default AnalyticsSearchContainer;
