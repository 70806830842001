import { createStyles, makeStyles } from '@material-ui/styles';
import { Cell } from 'fixed-data-table-2';
import React, { FC, useState } from 'react';
import { Button } from '@material-ui/core';
import CancleDialog from 'site/bond/component/page/Order/CancleDialog';
import { CellDefaultProps } from 'component/common/fxTable/Cell';
import { Map } from 'immutable';
import ORDER_STATUS from 'constant/OrderStatus';
import WebCACertContainer from 'site/bond/component/WebCA/WebCACertContainer';
import { IsBondSite } from 'constant/site';
import DialogMessage from 'site/bond/component/dialog/DialogMessage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    cell: {
      margin: '0 auto',
    },
  }),
);

interface Props extends CellDefaultProps {
  refresh: (rowKey: string, response) => void;
  workday: boolean;
  closeTime: boolean;
  closeMessage: string;
}

const CancleButton: FC<Props> = ({ data, rowIndex, refresh, workday, closeTime, closeMessage }) => {
  const classes = useStyles();
  const [open, setopen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleComplete = (isSuccess: boolean, response) => {
    if (isSuccess) refresh(data.getIn([rowIndex, 'rowKey'], ''), response);

    setopen(false);
  };

  function handleClick() {
    if (closeTime) setShowMessage(true);
    else setopen(true);
  }

  return (
    <Cell className={classes.cell}>
      {['11', '32'].some(currentStatus => ORDER_STATUS[currentStatus] === data.getIn([rowIndex, 'status'])) && workday && (
        <Button variant="contained" color="primary" size="small" className={classes.root} onClick={handleClick}>
          刪單
        </Button>
      )}
      {open && rowIndex !== undefined && (
        <WebCACertContainer onFailed={() => setopen(false)}>
          <CancleDialog
            data={data.get(rowIndex, Map<string, string>())}
            onClose={() => setopen(false)}
            onComplete={handleComplete}
          />
        </WebCACertContainer>
      )}
      <DialogMessage onClose={() => setShowMessage(false)} open={showMessage} title="系統訊息" variant="default">
        {closeMessage}
      </DialogMessage>
    </Cell>
  );
};

const CreateCancleCell = (refresh, workday, closeTime, closeMessage) =>
  function Foo(data) {
    return IsBondSite ? (
      <CancleButton data={data} refresh={refresh} workday={workday} closeTime={closeTime} closeMessage={closeMessage} />
    ) : (
      <Cell />
    );
  };

export default CreateCancleCell;
