import React, { FC } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import AvatarIcon from 'component/icon/AvatarIcon';

export interface UserAvatarStateToProps {
  name: string;
  account: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    name: { fontSize: 15, fontWeight: 'bold' },
    account: { fontSize: 13 },
    iconGrid: { marginRight: 3, paddingTop: 4 },
  }),
);

export const UserAvatar: FC<UserAvatarStateToProps> = ({ name, account }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item className={classes.iconGrid}>
        <AvatarIcon />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="flex-start">
          <Grid item className={classes.account}>
            {account}
          </Grid>
          <Grid item className={classes.name}>
            {name}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
