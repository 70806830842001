import React, { FC, useState } from 'react';
import { Button, createStyles, makeStyles } from '@material-ui/core';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import EditableDialog from 'site/bond/component/dialog/EditableDialog';
import Fetch from 'component/common/Fetch';
import AddToWatchListButtonDialogContent from 'site/bond/component/button/AddToWatchListButtonDialogContent';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  }),
);

interface AddToWatchListButton {
  isin: string;
}

const AddToWatchListButton: FC<AddToWatchListButton> = ({ isin }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const handleRenderConfirm = ({ onConfirm, isMobileWidth }) => {
    const handleConfirm = (listId: number) => {
      const body = { isinList: [isin] };

      onConfirm(`/watchList/${listId}/add`, { body });
    };

    return (
      <Fetch url="/watchList/listNames">
        <AddToWatchListButtonDialogContent
          onConfirm={handleConfirm}
          onClose={handleClose}
          isMobileWidth={isMobileWidth}
        />
      </Fetch>
    );
  };

  return (
    <>
      <Button className={classes.root} onClick={handleClick} variant="outlined" color="primary">
        <FavoriteBorderOutlinedIcon />
        加入觀察
      </Button>
      {open && <EditableDialog onClose={handleClose} renderConfirm={handleRenderConfirm} title="觀察名單" />}
    </>
  );
};

export default AddToWatchListButton;
