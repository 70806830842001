import React, { FC } from 'react';
import ShellGap from './Shell/ShellGap';
import InventoryContent from 'site/management/component/page/bond/Account/Inventory/InventoryContent';

const Inventory: FC = () => (
  <ShellGap>
    <InventoryContent api="/account/inventorySummary" hashId="" warningAPI={`/maintain/warning/inventory`} />
  </ShellGap>
);

export default Inventory;
