import ComplexTable from 'component/table/ComplexTable';
import { BOND_FIELDS } from 'config/field';
import Immutable from 'immutable';
import React, { FC, useEffect, useMemo, useState } from 'react';
import DataType from 'constant/datatype';
import { Box, Tab, Tabs } from '@material-ui/core';

const Category = {
  all: '全部',
  bond: '海外債',
  sn: '結構債',
};

const columns = [
  BOND_FIELDS.ASSIGN_DATE,
  BOND_FIELDS.ISIN,
  BOND_FIELDS.BOND_CODE,
  BOND_FIELDS.BS,
  BOND_FIELDS.ORDER_FACE_VALUE,
  BOND_FIELDS.ORDER_PRICE,
  BOND_FIELDS.SETTLEMENT_AMT,
  { ...BOND_FIELDS.ACCRUED_INTEREST, header: '應計利息', dataType: DataType.NumberWithMantissa2AndPreserveZero },
  { ...BOND_FIELDS.CURRENCY },
  BOND_FIELDS.PAYMENT_AMT,
];

interface Props {
  data?: {
    result: {}[];
  };
  onLoaded?: (data: object | string) => void;
}

const ConfirmContent: FC<Props> = ({ data = { result: [] }, onLoaded }) => {
  const [tab, setTab] = useState(Object.keys(Category)[0]);
  const [detail, setDetail] = useState(Immutable.List());

  useEffect(() => {
    let detailData: any[];
    let detailTmp: any = [];
    if (tab === 'all') {
      detailData = data['result'];
    } else {
      for (let i = 0; i < data['result'].length; i++) {
        if (data['result'][i]['isBondProduct'] == (tab === 'bond' ? true : false)) {
          detailTmp.push(data['result'][i]);
        }
      }
      detailData = detailTmp;
    }

    if (onLoaded) {
      onLoaded({
        columns: columns,
        data: detailData,
      });
    }

    let immDetail = Immutable.fromJS(detailData);
    setDetail(immDetail);
  }, [data, onLoaded, tab]);

  return (
    <>
      <Box mb={2}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          onChange={(e, v): void => {
            setTab(v);
          }}
        >
          {Object.entries(Category).map(
            ([k, v]): JSX.Element => (
              <Tab key={k} label={v} value={k} />
            ),
          )}
        </Tabs>
        <ComplexTable data={detail} columns={columns} initWithoutSorting />
      </Box>
    </>
  );
};

export default ConfirmContent;
