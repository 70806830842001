// eslint-disable-next-line
export const log = (info: any): void => {
  // eslint-disable-next-line
  console.log(info);
};

// eslint-disable-next-line
export const logError = (e?: Error, info?: any): void => {
  // eslint-disable-next-line
  console.error(e, info);
};
