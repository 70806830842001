import { Box, Card, Collapse, createStyles, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import Fetch from 'component/common/Fetch';
import PortalMask from 'component/common/PortalMask';
import SearchIcon from 'component/icon/SearchIcon';
import TypingProgress from 'component/progress/TypingProgress';
import React, { FC, useMemo, useState } from 'react';
import { BondSuggestionProps } from './BondSuggestion';
import BondSuggestionError from './BondSuggestionError';
import BondSuggestionList from './BondSuggestionList';
import clsx from 'clsx';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'inline-block',
      zIndex: 101,
    },
    input: {
      width: 230,
    },
    listItem: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px `,
      borderBottom: '1px solid #e0e0e0',
    },
    suggestion: {
      position: 'absolute',
      top: 44,
      left: 0,
      right: 0,
    },
    card: {
      maxHeight: 250,
      overflowY: 'auto',
    },
  }),
);

const LoadingComponent = (
  <Box p={1}>
    <TypingProgress />
  </Box>
);

const BondSuggestionDesktop: FC<BondSuggestionProps> = ({
  data,
  disabledVerify = false,
  onChange,
  api,
  searchPayload,
  classes: propsClasses = {},
  searchRule,
}) => {
  const classes = useStyle();
  const [focus, setFocus] = useState(false);

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(null, e.target.value);
  };

  const textFieldProps = useMemo(
    () =>
      !disabledVerify && searchPayload.isin === '' && searchPayload.word !== '' && focus === false
        ? { error: true, helperText: '請選擇正確商品項目' }
        : {},
    [disabledVerify, searchPayload, focus],
  );

  const handleClick = (bond, bondCode) => {
    setFocus(false);
    onChange(bond, bondCode);
  };

  return (
    <div className={clsx(classes.root, propsClasses.desktopRoot)}>
      <TextField
        variant="outlined"
        label={`請輸入商品${searchRule}或ISIN`}
        className={clsx(classes.input, propsClasses.desktopInput)}
        value={searchPayload.word}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleChangeText}
        onFocus={() => {
          if (!focus) {
            setFocus(true);
          }
        }}
        {...textFieldProps}
      />
      <div className={classes.suggestion}>
        <Collapse in={focus}>
          <Card className={classes.card}>
            {api && (
              <Fetch
                url={api}
                loadingComponent={LoadingComponent}
                errorComponent={<BondSuggestionError className={classes.listItem} />}
              >
                <BondSuggestionList
                  classes={classes}
                  keyword={searchPayload.word}
                  onClick={handleClick}
                  searchRule={searchRule}
                />
              </Fetch>
            )}
            {data && (
              <BondSuggestionList
                data={data}
                classes={classes}
                keyword={searchPayload.word}
                onClick={handleClick}
                searchRule={searchRule}
              />
            )}
          </Card>
        </Collapse>
      </div>
      {focus && <PortalMask onClick={() => setFocus(false)} />}
    </div>
  );
};

export default BondSuggestionDesktop;
