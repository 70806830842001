import ComplexTable from 'component/table/ComplexTable';
import { BOND_FIELDS } from 'config/field';
import Immutable from 'immutable';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';

const Category = {
  all: '全部',
  bond: '海外債',
  sn: '結構債',
};

const detailColumns = [
  BOND_FIELDS.APPROPRIATION_DATE,
  BOND_FIELDS.ISIN,
  BOND_FIELDS.BOND_CODE,
  BOND_FIELDS.CURRENCY,
  { ...BOND_FIELDS.FACE_VALUE, header: '庫存面額' },
  BOND_FIELDS.INTEREST,
];
const summaryColumns = [BOND_FIELDS.CURRENCY, { ...BOND_FIELDS.INTEREST, header: '總配息金額' }];

interface Props {
  data?: {
    result: { detail: any[]; summary: any[] }[];
  };
  onLoaded?: (data: object | string) => void;
}

const InterestContent: FC<Props> = ({ data = { result: [] }, onLoaded }) => {
  const [tab, setTab] = useState(Object.keys(Category)[0]);
  const idata = useMemo(() => Immutable.fromJS(data), [data]);
  const [detail, setDetail] = useState(Immutable.List());
  const [summary, setSummary] = useState(Immutable.List());

  useEffect(() => {
    let detailData: any[];
    let detailTmp = [] as any;
    let summaryData: any;

    if (tab === 'all') {
      detailData = data['result'][0]['detail'];
      summaryData = data['result'][0]['summary'];
    } else if (tab === 'bond') {
      for (let i = 0; i < data['result'][0]['detail'].length; i++) {
        if (data['result'][0]['detail'][i]['isBondProduct'] == true) {
          detailTmp.push(data['result'][0]['detail'][i]);
        }
      }
      detailData = detailTmp;
      summaryData = data['result'][0]['summaryBond'];
    } else {
      for (let i = 0; i < data['result'][0]['detail'].length; i++) {
        if (data['result'][0]['detail'][i]['isBondProduct'] == false) {
          detailTmp.push(data['result'][0]['detail'][i]);
        }
      }
      detailData = detailTmp;
      summaryData = data['result'][0]['summarySN'];
    }

    if (onLoaded) {
      onLoaded({
        columns: detailColumns,
        data: detailData,
      });
    }

    let immDetail = Immutable.fromJS(detailData);
    let immSummary = Immutable.fromJS(summaryData);
    setDetail(immDetail);
    setSummary(immSummary);
  }, [data, idata, onLoaded, tab]);

  return (
    <>
      <Box mb={2}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          onChange={(e, v): void => {
            setTab(v);
          }}
        >
          {Object.entries(Category).map(
            ([k, v]): JSX.Element => (
              <Tab key={k} label={v} value={k} />
            ),
          )}
        </Tabs>
        <ComplexTable data={detail} columns={detailColumns} initWithoutSorting />
      </Box>
      <ComplexTable data={summary} columns={summaryColumns} />
    </>
  );
};

export default InterestContent;
