import CircleProgress from 'component/progress/CircleProgress';
import { isStatusCodeOK } from 'constant/StatusCode';
import FetchContext from 'context/FetchContext';
import { logError } from 'lib/logger';
import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';

export interface DispatchToProps {
  showError: (arg0: string) => void;
}

interface Props {
  url: string;
  param?: object | string;
  children: ReactElement;
  disableAnimate?: boolean;
  loadingComponent?: JSX.Element;
  errorComponent?: JSX.Element;
  acceptableStatusCode?: number[];
  onLoaded?: (data: object | string) => void;
}

const Fetch: FC<Props> = ({
  url,
  param,
  children,
  disableAnimate = false,
  loadingComponent = <CircleProgress />,
  errorComponent = null,
  acceptableStatusCode,
  onLoaded,
}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [errorOccur, setErrorOccur] = useState(false);
  const fetchGet = useContext(FetchContext);

  useEffect((): void => {
    setLoading(true);

    fetchGet(url, { param }).then((v): void => {
      try {
        if (!isStatusCodeOK(v.statusCode, acceptableStatusCode)) throw Error('Fetch Status Code Error');

        setData(v);
        setLoading(false);
        setErrorOccur(false);
      } catch (e) {
        logError(e);
        setErrorOccur(true);
        setLoading(false);
      }
    });
  }, [fetchGet, url, param, acceptableStatusCode]);

  if (loading && !disableAnimate) return loadingComponent;
  return errorOccur ? errorComponent : <children.type {...children.props} data={data} onLoaded={onLoaded} />;
};

export default Fetch;
