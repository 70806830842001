import { connect } from 'react-redux';
import AdvanceMenu, {
  AdvanceMenuDispatchProps,
  AdvanceMenuStateToProps,
} from 'site/bond/component/page/Advance/AdvanceMenu';
import { push } from 'connected-react-router';
import { Map } from 'immutable';

const AdvanceMenuContainer = connect<AdvanceMenuStateToProps, AdvanceMenuDispatchProps, {}, Map<string, string>>(
  state => ({
    pathname: state.getIn(['router', 'location', 'pathname']),
  }),
  {
    push,
  },
)(AdvanceMenu);

export default AdvanceMenuContainer;
