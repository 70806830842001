import XLSX, { WritingOptions } from 'xlsx';

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export default class Workbook {
  SheetNames: string[] = []; // 儲存 Sheet 的名稱。
  Sheets: {} = {}; // 儲存 Sheet 的物件內容
  wopts: WritingOptions = {
    bookType: 'xlsx', // 要生成的文件类型
    bookSST: false, // 是否生成 Shared String Table。官方解釋是如果開啟生成速度會下降，但在低版本IOS設備上有更好的兼容性
    type: 'binary',
  };
  constructor() {
    // 使用單例模式，產生唯一的 workbook
    if (!(this instanceof Workbook)) return new Workbook();
  }

  /**
   * 塞入 Sheet
   */
  appendSheet(sheet, name = `sheet${this.SheetNames.length + 1}`) {
    this.SheetNames = [...this.SheetNames, name];
    this.Sheets[name] = sheet;
  }

  toBlob(option = this.wopts) {
    // 字串轉 ArrayBuffer
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    var wbout = XLSX.write(this, option);
    var blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    return blob;
  }

  isEmpty() {
    return !this.SheetNames.length && JSON.stringify(this.Sheets === '{}');
  }
}
