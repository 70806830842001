import { createStyles, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers';
import { DatePickerViewsProps } from '@material-ui/pickers/DatePicker/DatePicker';
import clsx from 'clsx';
import DATETIME from 'constant/datetime';
import 'moment/locale/zh-tw';
import React, { FC } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiInputBase-root': {
        '@media (max-width: 380px)': {
          fontSize: 15,
        },
      },
    },
  }),
);

const MuiKeyboardDatePicker: FC<KeyboardDateTimePickerProps & DatePickerViewsProps> = ({
  format = DATETIME.UNIVERSAL_DATE_FORMAT,
  okLabel = '確認',
  cancelLabel = '取消',
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <KeyboardDatePicker
      className={clsx(className, classes.root)}
      okLabel={okLabel}
      cancelLabel={cancelLabel}
      format={format}
      {...props}
    />
  );
};

export default MuiKeyboardDatePicker;
