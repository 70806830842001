import { createStyles, makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const useStyle = makeStyles(() =>
  createStyles({
    root: { width: 'auto', maxWidth: 500, height: 'auto' },
    st0: { fill: '#CECECE' },
    st1: { fill: '#BC875B' },
    st2: { fill: '#9E6946' },
    st3: { fill: '#A8754F' },
    st4: { fill: '#E5B88C' },
    st5: { fill: '#E8C09C' },
    st6: { opacity: 0.69, fill: '#E0D0C1' },
    st7: { fill: '#C4946C' },
    st8: { fill: '#D6A77C' },
    st9: { fill: '#F1F1F3' },
    st10: { fill: '#D23749' },
  }),
);

const PageNotFoundIcon: FC = () => {
  const classes = useStyle();

  return (
    <SvgIcon viewBox="0 0 993.9 540.7" className={classes.root}>
      <g>
        <path
          className={classes.st0}
          d="M690.6,322l-175.3-47.5l-292.6,76.7l282.7,32.2c-25.7,3.9-49.7,7.7-51,8c-2.8,0.7,52.1,30.3,52.1,30.3l43-33.2
		l48.2,5.5L690.6,322z"
        />
        <g>
          <polygon className={classes.st1} points="332.5,527.7 205.9,497.8 473.8,394.6 601.7,420.4 		" />
          <path
            className={classes.st1}
            d="M480.5,179l-6.2,216.1l-0.1,0L206.6,497.7c0,0-137.7-170.7-134.6-175.8c3.1-5.2,203.2-163,203.2-163l0.7,0.1
			L480.5,179z"
          />
          <polygon className={classes.st2} points="480.5,179 474.3,395.1 474.2,395.1 276.6,353.5 275.9,159 		" />
          <polygon className={classes.st3} points="276.2,154.8 277.3,353.9 66.9,426.1 124.6,242.5 		" />
          <polygon
            className={classes.st4}
            points="206.6,264.1 206.6,497.8 11.7,445.2 11.2,368.6 10.7,308.7 10.1,225.5 		"
          />
          <polygon className={classes.st5} points="273.2,145 10.1,225.5 206.6,264.1 480.5,179 		" />
          <path
            className={classes.st6}
            d="M89,339.9c0,0-1.8,2.9-1.2,5.3c0.6,2.4,1.2,3.5-0.6,7.1c-1.8,3.5,0,0-1.2,2.9c-1.2,3,0.6,4.1,1.8,6.5
			c1.2,2.3,1.2,5.9,0.6,8.2c-0.6,2.4-1.8,7.7-1.2,10c0.6,2.4,1.8,11.2,1.8,11.2l-77.7-23.8l-0.5-68.4l81.7,23.9
			C92.5,322.8,91.4,334.6,89,339.9z"
          />
          <polygon className={classes.st7} points="206.6,497.8 306.8,467.6 310.7,237.1 206.6,264.1 		" />
          <polygon className={classes.st1} points="478.9,179 606.2,171.7 600,394.1 473.9,395 		" />
          <polygon className={classes.st8} points="262.7,407.2 535.4,307.8 480.5,179 206.6,264.1 		" />
        </g>
        <ellipse className={classes.st9} cx="801" cy="136" rx="181" ry="123.4" />
        <g>
          <path
            className={classes.st10}
            d="M768.8,181.4h-9.4v16.3h-16.2v-16.3h-33.5v-11.5l34.4-50.6h15.3v49.3h9.4L768.8,181.4L768.8,181.4z
			 M743.2,168.5v-13.3c0-2.2,0.1-5.4,0.3-9.6c0.2-4.2,0.3-6.7,0.4-7.4h-0.4c-1.3,2.9-2.9,5.8-4.8,8.6l-14.4,21.7H743.2z"
          />
          <path
            className={classes.st10}
            d="M829.3,158.5c0,13.7-2.2,23.8-6.7,30.4c-4.5,6.6-11.4,9.9-20.7,9.9c-9.1,0-15.9-3.4-20.5-10.2
			s-6.9-16.8-6.9-30.1c0-13.8,2.2-24,6.7-30.6c4.5-6.6,11.3-9.8,20.7-9.8c9.1,0,15.9,3.4,20.5,10.3
			C827,135.2,829.3,145.2,829.3,158.5z M791,158.5c0,9.6,0.8,16.5,2.5,20.7c1.7,4.2,4.5,6.3,8.4,6.3c3.9,0,6.7-2.1,8.4-6.3
			s2.6-11.1,2.6-20.6c0-9.6-0.9-16.5-2.6-20.7s-4.5-6.3-8.3-6.3c-3.9,0-6.7,2.1-8.4,6.3C791.8,141.9,791,148.8,791,158.5z"
          />
          <path
            className={classes.st10}
            d="M894.2,181.4h-9.4v16.3h-16.2v-16.3h-33.5v-11.5l34.4-50.6h15.3v49.3h9.4L894.2,181.4L894.2,181.4z
			 M868.6,168.5v-13.3c0-2.2,0.1-5.4,0.3-9.6c0.2-4.2,0.3-6.7,0.4-7.4h-0.4c-1.3,2.9-2.9,5.8-4.8,8.6l-14.4,21.7H868.6z"
          />
        </g>
        <g>
          <path
            className={classes.st10}
            d="M758.3,79.9c0,5.4-1.3,9.5-4,12.3c-2.7,2.9-6.5,4.3-11.4,4.3s-8.8-1.4-11.4-4.3c-2.7-2.9-4-7-4-12.4
			s1.3-9.5,4-12.3c2.7-2.8,6.5-4.3,11.4-4.3s8.8,1.4,11.4,4.3C757,70.5,758.3,74.6,758.3,79.9z M734.7,79.9c0,3.6,0.7,6.3,2.1,8.2
			c1.4,1.8,3.4,2.7,6.2,2.7c5.5,0,8.2-3.6,8.2-10.9c0-7.3-2.7-10.9-8.2-10.9c-2.7,0-4.8,0.9-6.2,2.8
			C735.4,73.6,734.7,76.3,734.7,79.9z"
          />
          <path
            className={classes.st10}
            d="M786.9,83.8c0,4-1.1,7.2-3.2,9.4s-5.1,3.4-8.9,3.4c-2.4,0-4.5-0.5-6.3-1.6c-1.8-1-3.2-2.5-4.2-4.5
			c-1-1.9-1.5-4.2-1.5-6.8c0-4,1.1-7.2,3.2-9.4s5.1-3.4,8.9-3.4c2.4,0,4.5,0.5,6.3,1.6s3.2,2.5,4.2,4.4
			C786.4,78.9,786.9,81.2,786.9,83.8z M769.8,83.8c0,2.4,0.4,4.3,1.2,5.6c0.8,1.3,2.1,1.9,3.9,1.9c1.8,0,3.1-0.6,3.9-1.9
			s1.2-3.1,1.2-5.6c0-2.4-0.4-4.3-1.2-5.5c-0.8-1.2-2.1-1.8-3.9-1.8c-1.8,0-3.1,0.6-3.9,1.8C770.2,79.5,769.8,81.3,769.8,83.8z"
          />
          <path
            className={classes.st10}
            d="M806.1,96.6c-2.9,0-5.2-1-6.8-3.2h-0.3c0.2,2.1,0.3,3.3,0.3,3.6v10h-6.7V71.4h5.5l0.9,3.2h0.3
			c1.6-2.4,3.9-3.7,7-3.7c2.9,0,5.2,1.1,6.8,3.4s2.5,5.4,2.5,9.4c0,2.6-0.4,4.9-1.2,6.9s-1.9,3.4-3.3,4.4
			C809.7,96.1,808,96.6,806.1,96.6z M804.1,76.4c-1.7,0-2.9,0.5-3.7,1.5c-0.8,1-1.2,2.7-1.2,5.1v0.7c0,2.6,0.4,4.6,1.2,5.7
			s2,1.7,3.8,1.7c3,0,4.5-2.5,4.5-7.5c0-2.4-0.4-4.3-1.1-5.5C806.8,77,805.7,76.4,804.1,76.4z"
          />
          <path
            className={classes.st10}
            d="M838.4,88.8c0,2.5-0.9,4.5-2.6,5.8c-1.8,1.3-4.4,2-7.9,2c-1.8,0-3.3-0.1-4.6-0.4c-1.3-0.2-2.4-0.6-3.6-1.1
			v-5.6c1.3,0.6,2.7,1.1,4.2,1.5c1.6,0.4,2.9,0.6,4.2,0.6c2.4,0,3.7-0.7,3.7-2.1c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.9-0.7-1.7-1.1
			s-1.9-0.9-3.2-1.5c-1.9-0.8-3.3-1.5-4.2-2.2s-1.5-1.5-1.9-2.3c-0.4-0.9-0.6-2-0.6-3.2c0-2.2,0.8-3.9,2.6-5.1
			c1.7-1.2,4.1-1.8,7.2-1.8c3,0,5.9,0.6,8.7,1.9l-2,4.9c-1.2-0.5-2.4-1-3.5-1.3c-1.1-0.3-2.2-0.5-3.3-0.5c-2,0-3,0.5-3,1.6
			c0,0.6,0.3,1.1,1,1.6s2,1.1,4.2,2c1.9,0.8,3.3,1.5,4.3,2.2c0.9,0.7,1.6,1.5,2,2.3C838.2,86.5,838.4,87.6,838.4,88.8z"
          />
          <path
            className={classes.st10}
            d="M842.7,93c0-1.2,0.3-2.2,1-2.8c0.7-0.6,1.6-0.9,2.9-0.9c1.2,0,2.2,0.3,2.8,1c0.7,0.6,1,1.6,1,2.8
			c0,1.2-0.3,2.1-1,2.8s-1.6,1-2.8,1c-1.2,0-2.2-0.3-2.9-1C843,95.1,842.7,94.2,842.7,93z"
          />
          <path
            className={classes.st10}
            d="M855.6,93c0-1.2,0.3-2.2,1-2.8c0.7-0.6,1.6-0.9,2.9-0.9c1.2,0,2.2,0.3,2.8,1c0.7,0.6,1,1.6,1,2.8
			c0,1.2-0.3,2.1-1,2.8s-1.6,1-2.8,1c-1.2,0-2.2-0.3-2.9-1C856,95.1,855.6,94.2,855.6,93z"
          />
          <path
            className={classes.st10}
            d="M868.5,93c0-1.2,0.3-2.2,1-2.8c0.7-0.6,1.6-0.9,2.9-0.9c1.2,0,2.2,0.3,2.8,1c0.7,0.6,1,1.6,1,2.8
			c0,1.2-0.3,2.1-1,2.8s-1.6,1-2.8,1c-1.2,0-2.2-0.3-2.9-1C868.9,95.1,868.5,94.2,868.5,93z"
          />
        </g>
        <polygon className={classes.st9} points="656,207.4 568,273.4 706,226.4 	" />
      </g>
    </SvgIcon>
  );
};
export default PageNotFoundIcon;
