const Func = {
  /**
   * 最後一次事件觸發後 (timeout) 時間，才會接著呼叫 func
   */
  debounce: (timeout: number) => {
    let inst: NodeJS.Timeout;

    return func => (...args) => {
      if (inst) clearTimeout(inst);

      inst = setTimeout(() => {
        func(...args);
      }, timeout);
    };
  },

  /**
   * 節流功能，設定 frequency 內僅會觸發一次, 可以用在 resize 事件上，讓其觸發頻率降低
   */
  throttle: (frequency: number) => {
    let inst: NodeJS.Timeout;
    let previous = 0;

    return func => (...args) => {
      const now = Date.now();
      const invoke = () => {
        func(...args);
        previous = now;
      };

      if (previous === 0 || now - previous > frequency) {
        invoke();
      } else {
        if (inst) clearTimeout(inst);

        // 等下次觸發時間在呼叫
        inst = setTimeout(() => {
          invoke();
        }, previous + frequency - now);
      }
    };
  },
};

export default Func;
