import React, { FC } from 'react';
import DialogMessage from 'site/bond/component/dialog/DialogMessage';

const LoginTrustActionMessage = {
  '1': '請重新登入，異常代碼(0x1)',
  '2': '逾時，請重新登入，異常代碼(0x2)',
  '3': '請重新登入，異常代碼(0x3)',
  '4': '查無複委託帳戶，異常代碼(0x4)',
  '5': '查無客戶帳戶，異常代碼(0x5)',
};

interface LoginTrustMessageProps {
  action: string;
  open: boolean;
  onClose: () => void;
}

const LoginTrustMessageDialog: FC<LoginTrustMessageProps> = ({ action, open, onClose }) => (
  <DialogMessage onClose={onClose} open={open} title="系統訊息" variant="default">
    {LoginTrustActionMessage[action]}
  </DialogMessage>
);

export default LoginTrustMessageDialog;
