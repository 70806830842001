import { Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Immutable, { Map } from 'immutable';
import { numberFormatter } from 'lib/number';
import React, { FC, useMemo, useState } from 'react';
import HolidayDialog from 'site/bond/component/dialog/HolidayDialog';
import ElectronicBuyTradingDisableDialog from 'site/bond/component/dialog/ElectronicBuyTradingDisableDialog';
import BuyPriceIsOutdateDialog from 'site/bond/component/dialog/BuyPriceIsOutdateDailog';
import CustomerAndBondPIRestrictDialog from 'site/bond/component/dialog/CustomerAndBondPIRestrictDailog';
import STATUS_CODE from 'constant/StatusCode';
import DATA from 'constant/data';
import TradeDayButCloseTimeDialog from 'site/bond/component/dialog/TradeDayButCloseTimeDailog';
import { BuyContentCellInput } from 'site/bond/component/page/Buy/BuyContentCellInput';

interface BuyContentProps {
  data?: { result: {}[]; statusCode: number; status: string };
  onSubmit: (price: number, faceValue: number) => void;
}

interface CellProps {
  classes: { [key: string]: string };
  content: string;
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      fontSize: 18,
      padding: '11px 0',
      backgroundColor: '#fff',
    },
    title: {
      marginBottom: 10,
      fontSize: 16,
    },
    wrapper: {
      textAlign: 'center',
      color: '#000',
      lineHeight: 1,
      fontWeight: 'bold',
      backgroundColor: '#ffefee',
      border: `1px solid ${theme.palette.primary.light}`,
      padding: '11px 14px',
      borderRadius: 3,
      margin: `${theme.spacing(2)}px 0 0`,
      width: '100%',
    },
    addOrSubtract: {
      padding: 0,
      minWidth: '33px',
      boxShadow: 'none',
    },
    add: {
      borderRadius: '0 3px 3px 0',
    },
    subtract: {
      borderRadius: '3px 0 0 3px',
    },
    input: {
      color: 'inherit',
      width: '100%',
      padding: '5px 2px 4px',
    },
    inputWrapper: {
      fontSize: 18,
      backgroundColor: '#fff',
      '& .MuiInputBase-input': {
        textAlign: 'center',
      },
    },
  }),
);

const Cell: FC<CellProps> = ({ classes, title, content }) => (
  <Grid item xs={6} sm>
    <div className={classes.title}>{title}</div>
    <div className={classes.content}>{content}</div>
  </Grid>
);

const BuyContent: FC<BuyContentProps> = ({ data, onSubmit }) => {
  const classes = useStyles();
  const d = Immutable.fromJS(data).getIn(['result', 0], Map());
  const electronicMinFaceValue = parseFloat(d.get('electronicMinFaceValue', 0));
  const [state, setstate] = useState(electronicMinFaceValue);
  const electronicMinIncrease = d.get('electronicMinIncrease', 0);
  const lessThenMin = state < electronicMinFaceValue;
  const valueIsNotMultiple = !Number.isInteger((state - electronicMinFaceValue) / electronicMinIncrease);
  const accruedInterestAmount = useMemo(() => {
    return (state / 10000) * d.get('accruedInterest', 0);
  }, [state, d]);
  const tradeAmount = useMemo(() => (state * d.get('price', 0)) / 100 + accruedInterestAmount, [
    accruedInterestAmount,
    d,
    state,
  ]);
  const statusCode = data !== undefined ? data.statusCode : -1;
  const status = data !== undefined ? data.status : '';
  const isTradingTime = statusCode === STATUS_CODE.SUCCESS;

  const handleClick = () => {
    onSubmit(parseFloat(d.get('price')), state);
  };

  const isTradable = statusCode === STATUS_CODE.SUCCESS;

  function handleChange(val) {
    setstate(val);
  }

  return (
    <>
      <HolidayDialog statusCode={statusCode} />
      <TradeDayButCloseTimeDialog statusCode={statusCode} status={status} />
      <ElectronicBuyTradingDisableDialog statusCode={statusCode} />
      <BuyPriceIsOutdateDialog statusCode={statusCode} />
      <CustomerAndBondPIRestrictDialog statusCode={statusCode} />

      <Grid container spacing={2} className={classes.wrapper} alignItems="flex-end">
        <Cell classes={classes} title="交易日期" content={d.get('tradeDate', DATA.NULL)} />
        <Cell classes={classes} title="申購價格" content={d.get('price', DATA.NULL)} />
        <BuyContentCellInput
          classes={classes}
          min={electronicMinFaceValue}
          value={state}
          onChange={handleChange}
          minStep={electronicMinIncrease}
          lessThenMin={lessThenMin}
          valueIsNotMultiple={valueIsNotMultiple}
          isTradingTime={isTradingTime}
        />
        <Cell classes={classes} title="應付前手息" content={numberFormatter(accruedInterestAmount, 2, false)} />
        <Cell classes={classes} title="應付金額總計" content={numberFormatter(tradeAmount, 2, false)} />
        <Grid item xs={12} sm>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleClick}
            disabled={!isTradable || valueIsNotMultiple || lessThenMin}
          >
            買進下單
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default BuyContent;
