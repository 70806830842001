import STATUS_CODE from 'constant/StatusCode';
import { fetchGet } from 'lib/url';
import React from 'react';
import { UserState } from 'site/bond/reducer/userReducer';
import DialogMessage from '../dialog/DialogMessage';
import Sensors from 'site/bond/lib/sensor';

interface Props extends StateToProps, DispatchToProps {
  children: JSX.Element;
}

export interface StateToProps {
  auth: boolean;
  pathname: string;
}

export interface DispatchToProps {
  loginSuccess: (arg0: UserState) => void;
  push: (path: string) => void;
}

interface AuthResult {
  result?: { pi: boolean; dvp: boolean; account: string; identification: string; name: string; permissionList: any }[];
}

const CHECK_INTERVAL_TIME = 10 * 60 * 1000;

export default class Authorization extends React.Component<Props> {
  private timeoutInst: NodeJS.Timeout | undefined;
  private init = false;
  public state = { open: false };

  public componentDidMount() {
    this.checkAuthStatus();
  }

  public componentWillUnmount() {
    if (this.timeoutInst !== undefined) clearTimeout(this.timeoutInst);
  }

  public handleClose = () => {
    this.backToLogin();
  };

  private checkAuthStatus() {
    fetchGet<AuthResult>('/login').then((v): void => {
      if (v.statusCode === STATUS_CODE.SUCCESS && 'result' in v && v.result && v.result.length === 1) {
        Sensors.signIn(v.result[0].identification);

        if (!this.props.auth) {
          this.props.loginSuccess({ auth: true, ...v.result[0] });
        }

        this.timeoutInst = setTimeout(() => {
          this.checkAuthStatus();
        }, CHECK_INTERVAL_TIME);
      } else {
        // case 1. 客戶未登入，直接導向登入畫面
        // case 2. 客戶已登入，逾時，跳出 dialog 並導向登入畫面
        if (this.init) {
          this.setState({ open: true });
        } else {
          this.backToLogin();
        }
      }

      this.init = true;
    });
  }

  private backToLogin() {
    this.props.push(`/login?redirect=${encodeURIComponent(this.props.pathname)}`);
  }

  public render() {
    return this.props.auth ? (
      <>
        <DialogMessage title="系統訊息" onClose={this.handleClose} open={this.state.open} variant="warning">
          使用逾時，請重新登入
        </DialogMessage>
        {this.props.children}
      </>
    ) : null;
  }
}
