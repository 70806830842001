import { connect } from 'react-redux';
import { Map } from 'immutable';
import BuyDialog, { BuyDialogDispatchToProps, BuyDialogProps, BuyDialogStatesToProps } from './BuyDialog';
import { push } from 'connected-react-router';

export default connect<BuyDialogStatesToProps, BuyDialogDispatchToProps, BuyDialogProps, Map<string, string>>(
  state => ({
    account: state.getIn(['user', 'account'], ''),
  }),

  { push },
)(BuyDialog);
