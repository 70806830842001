import React from 'react';
import { Cell } from 'fixed-data-table-2';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { SortingNoneIcon, SortingAscIcon, SortingDescIcon } from 'component/icon/SortingIcon';

export enum SortingDirection {
  Asc = 1,
  Desc = -1,
  None = 0,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortableCell: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontSize: 15,
      position: 'relative',
      textAlign: 'left',
      cursor: 'pointer',
    },
    cell: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontSize: 15,
      position: 'relative',
    },
    iconWrapper: {
      // padding: `0 ${theme.spacing(1)}px`,
      marginLeft: theme.spacing(1),
    },
  }),
);

interface SortHeaderCellProps {
  onSortChange: (columnKey: string, sortDir: SortingDirection) => void;
  sortDir: SortingDirection;
  rowIndex?: number;
  columnKey?: string;
  className?: string;
  showSortIcon?: boolean;
}

export const SortHeaderCell: React.FC<SortHeaderCellProps> = ({
  onSortChange,
  sortDir,
  children,
  className,
  columnKey = '',
  showSortIcon = true,
  ...props
}) => {
  const classes = useStyles();
  let classNames = clsx(classes.sortableCell, className);
  let icon: JSX.Element;

  switch (sortDir) {
    case SortingDirection.None:
      classNames = clsx(classNames, 'sortingNone');
      icon = <SortingNoneIcon />;
      break;
    case SortingDirection.Asc:
      classNames = clsx(classNames, 'sortingAsc');
      icon = <SortingAscIcon />;
      break;
    default:
      classNames = clsx(classNames, 'sortingDesc');
      icon = <SortingDescIcon />;
  }

  return (
    <Cell
      {...props}
      className={classNames}
      columnKey={columnKey}
      onClick={() => {
        onSortChange(columnKey, sortDir);
      }}
    >
      <div>{children}</div>
      {showSortIcon ? <div className={classes.iconWrapper}>{icon}</div> : <span />}
    </Cell>
  );
};

interface HeaderCellProps {
  className?: string;
  columnKey: string;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({ children, className = '', columnKey = '', ...props }) => {
  const classes = useStyles();
  let classNames = clsx(classes.cell, className);

  return (
    <Cell {...props} className={classNames} columnKey={columnKey}>
      {children}
    </Cell>
  );
};

interface HeaderContentProps {
  header: string | string[] | JSX.Element;
}
export const HeaderContent: React.FC<HeaderContentProps> = ({ header }) => {
  var content = header;
  if (Array.isArray(header)) {
    content = (
      <div>
        {header.map(el => {
          return <div key={el.toString()}>{el}</div>;
        })}
      </div>
    );
  }
  return <>{content}</>;
};
