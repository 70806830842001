import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Embedded, {
  EmbeddedDispatchToProps,
  EmbeddedOwnProps,
  EmbeddedStateProps,
} from 'site/bond/component/page/Embedded';
import { Map } from 'immutable';

const EmbeddedContainer = connect<EmbeddedStateProps, EmbeddedDispatchToProps, EmbeddedOwnProps, Map<string, string>>(
  state => ({
    pathname: state.getIn(['router', 'location', 'pathname']),
  }),
  {
    push,
  },
)(Embedded);

export default EmbeddedContainer;
