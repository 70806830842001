import { connect } from 'react-redux';
import { Map } from 'immutable';
import SellDialog, { SellDialogDispatchToProps, SellDialogProps, SellDialogStatesToProps } from './SellDialog';
import { push } from 'connected-react-router';

export default connect<SellDialogStatesToProps, SellDialogDispatchToProps, SellDialogProps, Map<string, string>>(
  state => ({
    account: state.getIn(['user', 'account'], ''),
  }),
  { push },
)(SellDialog);
