import { createStyles, Dialog, DialogTitle, makeStyles, Slide, Theme } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { IsWindowWidthLessThan } from 'lib/width';
import Breakpoints from 'constant/breakpoints';
import Func from 'lib/func';
import { fetchPost } from 'lib/url';
import STATUS_CODE from 'constant/StatusCode';
import DialogStepLoading from 'site/bond/component/dialog/DialogStepLoading';
import DialogStepResult from 'site/bond/component/dialog/DialogStepResult';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OnConfirm = (pathname: string, fetchEvent: { body?: { [key: string]: any }; onSuccess?: () => void }) => void;

interface EditableDialogProps {
  onClose: () => void;
  renderConfirm: (params: { onConfirm: OnConfirm; isMobileWidth: boolean }) => JSX.Element;
  title: string;
}

enum Step {
  Edit = '編輯',
  Loading = '更新中',
  Result = '更新結果',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontSize: 21,
      paddingTop: 4,
      paddingBottom: 4,
      textAlign: 'center',
    },
    button: {
      fontWeight: 'normal',
    },
    content: {
      overflowX: 'hidden',
      padding: '19px 20px',
    },
    textBold: {
      fontWeight: 'bold',
    },
    action: {
      justifyContent: 'center',
      overflowX: 'hidden',
    },
    paper: {
      overflowX: 'hidden',
    },
  }),
);
const EditableDialog: FC<EditableDialogProps> = ({ title, onClose, renderConfirm }) => {
  const classes = useStyles();
  const [step, setstep] = useState<Step>(Step.Edit);
  const [isMobileWidth, setIsMobileWidth] = useState(IsWindowWidthLessThan(Breakpoints.sm));
  const [result, setresult] = useState();

  // 偵測寬度，並顯示對應的申購確認視窗
  useEffect(() => {
    const handleResize = Func.debounce(1000)(() => {
      const v = IsWindowWidthLessThan(Breakpoints.sm);

      if (isMobileWidth !== v) {
        setIsMobileWidth(v);
      }
    });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileWidth]);

  const handleCloseDialog = () => {
    if (step !== Step.Loading) {
      onClose();
    }
  };

  const SendRequest: OnConfirm = (pathname, { body = {}, onSuccess }) => {
    setstep(Step.Loading);

    fetchPost({ body, pathname }).then(v => {
      if (v.statusCode === STATUS_CODE.SUCCESS && onSuccess !== undefined) {
        onSuccess();
      }

      setresult(v);
      setstep(Step.Result);
    });
  };

  return (
    <Dialog
      PaperProps={{ className: classes.paper }}
      open
      fullWidth
      maxWidth="sm"
      onClose={handleCloseDialog}
      fullScreen={isMobileWidth}
    >
      <DialogTitle className={classes.title}>{`${title} - ${step}`}</DialogTitle>
      {step === Step.Edit && renderConfirm({ onConfirm: SendRequest, isMobileWidth })}
      {step === Step.Loading && (
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <div>
            <DialogStepLoading classes={classes} />
          </div>
        </Slide>
      )}
      {step === Step.Result && (
        <DialogStepResult
          classes={classes}
          onClose={handleCloseDialog}
          isMobileWidth={isMobileWidth}
          result={result}
          successText="更新成功"
        />
      )}
    </Dialog>
  );
};
export default EditableDialog;

export interface EditableStepDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  isMobileWidth: boolean;
}
