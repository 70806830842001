import { createStyles, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import clsx from 'clsx';

const useStyle = makeStyles(() =>
  createStyles({
    wrapper: {
      overflow: 'hidden',
      display: 'inline-block',
    },
    root: {
      width: 80,
      height: 80,
      border: '4px solid #4cae4c',
      borderRadius: '50%',
      position: 'relative',
      boxSizing: 'content-box',
      // overflow: 'hidden',

      '&:before, &:after': {
        content: "''",
        borderRadius: '50%',
        position: 'absolute',
        width: 60,
        height: 120,
        background: '#ffffff',
        transform: 'rotate(45deg)',
      },
      '&:before': {
        borderRadius: '120px 0 0 120px',
        top: -7,
        left: -33,
        transform: 'rotate(-45deg)',
        transformOrigin: '60px 60px',
      },
      '&:after': {
        borderRadius: '0 120px 120px 0',
        top: -11,
        left: 30,
        transform: 'rotate(-45deg)',
        transformOrigin: '0px 60px',
        animation: '$rotatePlaceholder 4.25s ease-in',
      },
    },
    line: {
      height: 5,
      backgroundColor: '#5cb85c',
      display: 'block',
      borderRadius: 2,
      position: 'absolute',
      zIndex: 2,
    },
    tip: {
      width: 25,
      left: 14,
      top: 46,
      transform: 'rotate(45deg)',
      animation: '$animateSuccessTip 0.75s',
    },
    long: {
      width: 47,
      right: 8,
      top: 38,
      transform: 'rotate(-45deg)',
      animation: '$animateSuccessLong 0.75s',
    },
    placeholder: {
      width: 80,
      height: 80,
      border: '4px solid rgba(92, 184, 92, 0.2)',
      borderRadius: '50%',
      boxSizing: 'content-box',
      position: 'absolute',
      left: -4,
      top: -4,
      zIndex: 2,
    },
    fix: {
      width: 5,
      height: 90,
      backgroundColor: '#ffffff',
      position: 'absolute',
      left: 28,
      top: 8,
      zIndex: 1,
      transform: 'rotate(-45deg)',
    },
    '@keyframes rotatePlaceholder': {
      '0%': {
        transform: 'rotate(-45deg)',
      },
      '5%': {
        transform: 'rotate(-45deg)',
      },
      '12%': {
        transform: 'rotate(-405deg)',
      },
      '100%': {
        transform: 'rotate(-405deg)',
      },
    },
    '@keyframes animateSuccessTip': {
      '0%': {
        width: 0,
        left: 1,
        top: 19,
      },
      '54%': {
        width: 0,
        left: 1,
        top: 19,
      },
      '70%': {
        width: 50,
        left: -8,
        top: 37,
      },
      '84%': {
        width: 17,
        left: 21,
        top: 48,
      },
      '100%': {
        width: 25,
        left: 14,
        top: 45,
      },
    },
    '@keyframes animateSuccessLong': {
      '0%': {
        width: 0,
        right: 46,
        top: 54,
      },
      '65%': {
        width: 0,
        right: 46,
        top: 54,
      },
      '84%': {
        width: 55,
        right: 0,
        top: 35,
      },
      '100%': {
        width: 47,
        right: 8,
        top: 38,
      },
    },
  }),
);

const SuccessIcon: FC = () => {
  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <span className={clsx(classes.line, classes.tip)} />
        <span className={clsx(classes.line, classes.long)} />
        <div className={classes.placeholder} />
        <div className={classes.fix} />
      </div>
    </div>
  );
};

export default SuccessIcon;
