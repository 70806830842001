import { Box, createStyles, Grid, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Result } from 'lib/url';
import { RenameWatchList } from 'site/bond/component/page/WatchList/RenameWatchList';
import Immutable, { List } from 'immutable';
import { DeleteWatchListButton } from 'site/bond/component/page/WatchList/DeleteWatchListButton';
import { CopyWatchListButton } from 'site/bond/component/page/WatchList/CopyWatchListButton';
import clsx from 'clsx';

const indicateHeight = 5;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      minWidth: 'auto',
      fontWeight: 'bold',
      fontSize: 17,
      color: '#000',
      minHeight: 'auto',
      lineHeight: 1,
      padding: '0 0 15px 0',
      margin: `0 ${theme.spacing(2)}px`,
    },
    floor: {
      borderTop: `${indicateHeight}px solid #eee`,
      position: 'relative',
      top: -1 * indicateHeight,
      zIndex: -1,
    },
    button: {
      marginRight: theme.spacing(1),
      color: '#5f5f5f',
      [theme.breakpoints.down('xs')]: {
        width: `calc(50% - ${theme.spacing(0.5)}px)`,
      },
    },
    tabWrapper: {
      flex: 2,
      display: 'flex',
      alignItems: 'flex-end',
    },
    marginRight0: {
      marginRight: 0,
    },
    tabScroller: {
      marginBottom: '0 !important',
    },
  }),
);

const useTabStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      alignItems: 'flex-start',
    },
  }),
);

const useIndicatorStyles = makeStyles(() =>
  createStyles({
    root: {
      height: indicateHeight,
    },
  }),
);

interface WatchListTabs {
  onChangeTab: (tabIndex: number) => void;
  onUpdate: () => void;
  value: number;
  data?: Result<{
    result: string[];
  }>;
  selectedIsinList: List<string>;
}

const WatchListTabs: FC<WatchListTabs> = ({ selectedIsinList, value, onChangeTab, onUpdate, data }) => {
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const indicatorClasses = useIndicatorStyles();
  const listId = value + 1;
  const [tabList, setTabList] = useState(Immutable.fromJS(data ? data.result : []));

  const handleRename = (newName: string) => {
    setTabList(tabList.set(value, newName));
  };

  return data ? (
    <>
      <Grid container spacing={1}>
        <Grid item className={classes.tabWrapper}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={(...args) => onChangeTab(args[1])}
            classes={{ scroller: classes.tabScroller }}
            TabIndicatorProps={{ classes: indicatorClasses }}
          >
            {tabList.map(el => (
              <Tab key={el} disableTouchRipple label={el} classes={tabClasses} className={classes.tab} />
            ))}
          </Tabs>
        </Grid>
        <Grid item>
          <RenameWatchList key={value} text={tabList.get(value)} listId={listId} onChange={handleRename} />
        </Grid>
      </Grid>
      <div className={classes.floor} />

      <Box mt={2}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={12} sm={9} md={10}>
            <Box textAlign="left">
              <DeleteWatchListButton
                className={classes.button}
                isinList={selectedIsinList}
                listId={listId}
                onUpdate={onUpdate}
              />
              <CopyWatchListButton
                className={clsx(classes.button, classes.marginRight0)}
                watchListNames={tabList}
                listId={listId}
                isinList={selectedIsinList}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  ) : null;
};

export { WatchListTabs };
