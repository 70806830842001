import { createStyles, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > div:nth-child(1)': {
        width: '20%',
      },
      '& > div:nth-child(2)': {
        width: '80%',
      },
      '& > div:nth-child(3)': {
        width: '50%',
      },
    },
    line: {
      height: 10,
      margin: '10px 0',
      animation: '$wording-pulse 1s infinite ease-in-out',
    },
    '@keyframes wording-pulse': {
      '0%': {
        backgroundColor: 'rgba(165, 165, 165, 0.1)',
      },
      '50%': {
        backgroundColor: 'rgba(165, 165, 165, 0.3)',
      },
      '100%': {
        backgroundColor: 'rgba(165, 165, 165, 0.1)',
      },
    },
  }),
);

const WordingSkeleton: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.line}></div>
      <div className={classes.line}></div>
      <div className={classes.line}></div>
    </div>
  );
};

export default WordingSkeleton;
