import { connect } from 'react-redux';
import Sensor, { SensorStatesToProps } from 'site/bond/component/Sensor/Sensor';
import { Map } from 'immutable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SensorContainer = connect<SensorStatesToProps, {}, {}, Map<string, any>>(state => ({
  routeKey: state.getIn(['router', 'location', 'key']),
  pathname: state.getIn(['router', 'location', 'pathname']),
}))(Sensor);

export default SensorContainer;
