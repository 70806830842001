import { BondSuggestionItem } from 'component/suggestion/BondSuggestion/BondSuggestionList';

export interface BondSuggestionProps {
  data?: {
    result: BondSuggestionItem[];
  };
  api?: string;
  disabledVerify?: boolean;
  onChange: (bond: BondSuggestionChangeBond | null, word: string) => void;
  searchPayload: BondSuggestionSearchPayload;
  classes?: {
    desktopInput?: string;
    desktopRoot?: string;
  };
  searchRule: BondSuggestionSearchRule;
}

export interface BondSuggestionSearchPayload {
  word: string;
  isin: string;
}

export interface BondSuggestionChangeBond {
  isin: string;
  bondName: string;
  bondCode: string;
}

export enum BondSuggestionSearchRule {
  BondCodeAndIsin = '代碼',
  NameAndIsin = '名稱',
}
