import { createStyles, makeStyles, Portal } from '@material-ui/core';
import React, { FC } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  }),
);

interface Props {
  onClick: () => void;
  className?: string;
}

const PortalMask: FC<Props> = ({ className, onClick }) => {
  const classes = useStyles();
  const body = document.querySelector('#root');

  return (
    <Portal container={body}>
      <div className={clsx(classes.root, className)} onClick={onClick} />
    </Portal>
  );
};

export default PortalMask;
