import { Button, createStyles, makeStyles, PropTypes } from '@material-ui/core';
import { fetchGet } from 'lib/url';
import React, { FC } from 'react';
import Sensors from 'site/bond/lib/sensor';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: '50px',
      fontWeight: 700,
      padding: '3px 20px;',
      letterSpacing: '2px',
    },
  }),
);
export interface DispatchToProps {
  push: (pathname: string) => void;
  logout: () => void;
}

export interface OwnProps {
  color?: PropTypes.Color;
  fullWidth?: boolean;
}

const LogoutButton: FC<OwnProps & DispatchToProps> = ({
  fullWidth = false,
  color = 'primary',
  push,
  logout,
}): JSX.Element => {
  const classes = useStyles();
  const handleClick = (): void => {
    fetchGet('/login/signout');

    Sensors.logout();

    logout();
    push('/login');
  };

  return (
    <Button variant="outlined" color={color} onClick={handleClick} className={classes.root} fullWidth={fullWidth}>
      登出
    </Button>
  );
};

export default LogoutButton;
