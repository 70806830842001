import { connect } from 'react-redux';
import Authorization, { DispatchToProps, StateToProps } from './Authorization';
import { Map } from 'immutable';
import { login } from 'site/bond/action/userAction';
import { push } from 'connected-react-router';

export default connect(
  (state: Map<string, string>): StateToProps => ({
    auth: state.getIn(['user', 'auth']),
    pathname: state.getIn(['router', 'location', 'pathname']),
  }),
  (dispatch): DispatchToProps => ({
    push: (path): void => {
      dispatch(push(path));
    },
    loginSuccess: (profile): void => {
      dispatch(login(profile));
    },
  }),
)(Authorization);
