import DataType from 'constant/datatype';

const DATA = {
  NULL: '--',
  ALIGN: {
    [DataType.Number]: 'right',
    [DataType.NumberWithMantissa2]: 'right',
    [DataType.NumberWithMantissa2AndPercentageSign]: 'right',
    [DataType.NumberWithMantissa2AndPreserveZero]: 'right',
    [DataType.Date]: 'center',
    [DataType.TimeWithSecond]: 'center',
    [DataType.Boolean]: 'center',
    [DataType.String]: 'left',
    [DataType.Action]: 'center',
  },
  WIDTH: {
    [DataType.Date]: 120,
    [DataType.Action]: 86,
  },
};

export default DATA;
