import { connect } from 'react-redux';
import { Map } from 'immutable';
import WebCASign, { WebCASignOwnProps, WebCASignStateToProps } from 'site/bond/component/WebCA/WebCASign';

const WebCASignContainer = connect<WebCASignStateToProps, {}, WebCASignOwnProps, Map<string, string>>(
  states => ({
    identification: states.getIn(['user', 'identification']),
  }),
  {},
)(WebCASign);

export default WebCASignContainer;
