import { Box, makeStyles, Theme, createStyles } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import 'moment/locale/zh-tw';
import React, { FC } from 'react';
import MuiKeyboardDatePicker from './MuiKeyboardDatePicker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    picker: {
      [theme.breakpoints.down('sm')]: {
        width: 180,
      },
      [`@media (max-width: 1360px) and (min-width: ${theme.breakpoints.values.md}px)`]: {
        width: 180,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    box: {
      [theme.breakpoints.down('xs')]: {
        width: `calc(50% - ${theme.spacing(0.5)}px)`,
      },
    },
  }),
);

interface Props {
  onChange: (start: MaterialUiPickersDate, end: MaterialUiPickersDate) => void;
  start: MaterialUiPickersDate;
  end: MaterialUiPickersDate;
}

// 排序日期後再回傳
const OrderDates = (date: MaterialUiPickersDate, date1: MaterialUiPickersDate, callback) => {
  if (date === null || date1 === null) return callback(date, date1);

  return date.isAfter(date1) ? callback(date1, date) : callback(date, date1);
};

const StartEndDatePicker: FC<Props> = ({ start, end, onChange }) => {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box mr={1} className={classes.box}>
        <MuiKeyboardDatePicker
          className={classes.picker}
          inputVariant="outlined"
          label="開始日期"
          value={start}
          onChange={date => OrderDates(date, end, onChange)}
        />
      </Box>
      <Box className={classes.box}>
        <MuiKeyboardDatePicker
          className={classes.picker}
          inputVariant="outlined"
          label="結束日期"
          value={end}
          onChange={date => OrderDates(start, date, onChange)}
        />
      </Box>
    </Box>
  );
};

export default StartEndDatePicker;
