import { connect } from 'react-redux';
import { Map } from 'immutable';
import { UserNameAndAccount, UserNameAndAccountStateToProps } from 'site/bond/component/page/Shell/UserNameAndAccount';

export const UserNameAndAccountContainer = connect<UserNameAndAccountStateToProps, {}, {}, Map<string, string>>(
  state => ({
    name: state.getIn(['user', 'name']),
    account: state.getIn(['user', 'account']),
  }),
)(UserNameAndAccount);
