import { createStyles, makeStyles, Tooltip } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { CellDefaultProps } from 'component/common/fxTable/Cell';
import DATA from 'constant/data';
import { Cell } from 'fixed-data-table-2';
import React, { FC } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      marginRight: 5,
      fontSize: '14px',
    },
  }),
);

const OrderStatusCell: FC<CellDefaultProps> = ({ rowIndex, data, ...props }) => {
  const status = data.getIn([rowIndex, 'status'], DATA.NULL);
  const errorDesc = data.getIn([rowIndex, 'errorDesc'], DATA.NULL);
  const classes = useStyles();

  return (
    <Cell {...props}>
      <div className={classes.content}>
        <span className={classes.text}>{status}</span>
        {errorDesc && (
          <Tooltip title={errorDesc}>
            <ErrorOutline />
          </Tooltip>
        )}
      </div>
    </Cell>
  );
};

export default OrderStatusCell;
