import { createStyles, makeStyles } from '@material-ui/core';
import { CellDefaultProps } from 'component/common/fxTable/Cell';
import DATA from 'constant/data';
import { Cell } from 'fixed-data-table-2';
import React, { FC, useState } from 'react';
import palette from 'style/palette';
import BondBasicDialog from './BondBasicDialog';
import SNBasicDialog from './SNBasicDialog';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      color: palette.link,
      textDecoration: 'none',
      fontSize: '14px',
    },
  }),
);

const BondCodeCell: FC<CellDefaultProps> = ({ rowIndex, data, ...props }) => {
  const classes = useStyles();
  const text = data.getIn([rowIndex, 'bondCode'], DATA.NULL);
  const isin = data.getIn([rowIndex, 'isin'], '');
  const isBondProduct: boolean = data.getIn([rowIndex, 'isBondProduct']);
  const [opensn, setopensn] = useState(false);
  const [openbond, setopenbond] = useState(false);
  return (
    <Cell {...props}>
      {isBondProduct ? (
        <>
          <a href="javascript:void(0)" className={classes.link} onClick={() => setopenbond(true)}>
            {text}
          </a>
          <BondBasicDialog open={openbond} onClose={() => setopenbond(false)} isin={isin} />
        </>
      ) : (
        <>
          <a href="javascript:void(0)" className={classes.link} onClick={() => setopensn(true)}>
            {text}
          </a>
          <SNBasicDialog open={opensn} onClose={() => setopensn(false)} isin={isin} />
        </>
      )}
    </Cell>
  );
};

const createBondCodeCell = data => <BondCodeCell data={data} />;

export default createBondCodeCell;
