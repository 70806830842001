import blue from '@material-ui/core/colors/blue';

const palette = {
  primary:
    process.env.NODE_ENV !== 'production' && process.env['REACT_APP_SITE'] === 'management'
      ? {
          light: blue[200],
          main: blue[500],
          dark: blue[900],
          contrastText: '#fff',
        }
      : {
          light: '#E71A0F',
          main: '#D23749',
          dark: '#9E110A',
          contrastText: '#fff',
        },
  secondary: {
    light: '#0066CC',
    main: '#5B7EA8',
    dark: '#5B7EA8',
    contrastText: '#fff',
  },
  green: '#01a477',
  link: '#0066CC',
};

export default palette;
