const STATUS_CODE = {
  FAIL: 0,
  SUCCESS: 1,
  FAIL_INFO: 2,
  HOLIDAY: 10,
  TRADE_DAY_BUT_CLOSE_TIME: 11,
  SOCKET_OFFLINE_PERIOD: 12,
  FTP_IMPORT_PARTIAL_SUCCESS: 61,
  FTP_IMPORT_FILE_PARSE_ERROR: 62,
  ELECTRONIC_TRADING_BUY_DISABLE: 71,
  ELECTRONIC_TRADING_RESTRICT_REDEEM: 72,
  MANAGEMENT_RESTRICT_SUBSCRIBE: 73,
  MANAGEMENT_RESTRICT_REDEEM: 74,
  BUY_PRICE_IS_OUTDATE: 75,
  SELL_PRICE_IS_OUTDATE: 76,
  CUSTOMER_AND_BOND_PI_RESTRICT_DIALOG: 77,
  CannotFindBondCode: 78,
  // 100 開頭的錯誤碼為 permission platform 發出
  P_FAIL: 100,
  P_FAIL_INFO: 102,
  P_EXPIRE: 104,
  BTS_ILLEGAL_REQUEST: 107,
  // 200 開頭為 fbf
  F_OFFLINE: 202,
  F_LOGIN_ACCOUNT_LOCK: 230,
  F_LOGIN_FIRST_LOGIN_MUST_CHANGE_PASSWORD: 231,
  F_LOGIN_IS_DVP: 233,
  F_LOGIN_IS_US: 234,
  F_LOGIN_AGREEMENT_UNSIGNED: 235, //風險預告書簽署尚未簽屬
  F_LOGIN_RR_EFFECTIVE_DATE_EXPIRED: 236, //KYC過期
  F_LOGIN_UNSIGNED_AND_EXPIRED: 238, //風險預告書簽署尚未簽屬+KYC過期
  F_LOGIN_HAVE_NO_CERT: 240, // 登入時檢查不到憑證
  F_LOGIN_CHECK_BIRTHDAT: 241, //需加驗生日
  F_LOGIN_CHECK_BIRTHDAT_ERROR: 242, // 驗證生日錯誤
  F_LOGIN_CHECK_BIRTHDAT_ERROR3: 243, // 驗證生日錯誤三次
  F_LOGIN_CHECK_CERT_SIGN: 244, //憑證簽屬
  F_LOGIN_CHECK_CERT_SIGN_FAIL: 245, //憑證簽屬失敗
  F_LOGIN_CHECK_RECAPTCHA_FAIL: 250, //reCAPTCHA 失敗
  NETWORK_ERROR: 99999,
};

export default STATUS_CODE;

export function isStatusCodeOK(statusCode: number, otherOkStatusCodes: number[] = []) {
  return (
    statusCode === STATUS_CODE.SUCCESS ||
    statusCode === STATUS_CODE.HOLIDAY ||
    otherOkStatusCodes.some(el => statusCode === el)
  );
}
