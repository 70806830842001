import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { BondSuggestionChangeBond, BondSuggestionSearchRule } from 'component/suggestion/BondSuggestion/BondSuggestion';
import React, { FC, useState } from 'react';
import ShellGap from 'site/bond/component/page/Shell/ShellGap';
import BondSuggestionRWD from 'component/suggestion/BondSuggestion/BondSuggestionRWD';
import { Push } from 'connected-react-router';
import AddToWatchListButton from 'site/bond/component/button/AddToWatchListButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    right: {
      textAlign: 'right',
      marginBottom: theme.spacing(2),

      [theme.breakpoints.down('xs')]: { marginTop: theme.spacing(3) },
    },
    suggestionButtonWrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flex: 1,
      },
    },
  }),
);

export interface AnalyticsSearchDispatchToProps {
  push: Push;
}

export interface AnalyticsSearchStateToProps {
  pathname: string;
}

export const AnalyticsSearch: FC<AnalyticsSearchDispatchToProps & AnalyticsSearchStateToProps> = ({
  push,
  pathname,
}) => {
  const classes = useStyles();
  const match = pathname.match(/\/(\w+)$/);
  const [searchPayload, setSearchPayload] = useState({ isin: match === null ? '' : match[1], word: '' });

  const handleChange = (bond: BondSuggestionChangeBond | null, word: string) => {
    if (bond) {
      setSearchPayload({ isin: bond.isin, word: '' });
      push(`/analytics/${bond.isin}`);
    } else {
      setSearchPayload({ isin: '', word });
    }
  };

  return (
    <ShellGap>
      <Grid container>
        <Grid xs={12} sm item className={classes.right} container justify="flex-end" spacing={1} alignItems="center">
          <Grid item>
            <AddToWatchListButton isin={searchPayload.isin} />
          </Grid>
          <Grid item className={classes.suggestionButtonWrapper}>
            <BondSuggestionRWD
              api="/product/list"
              onChange={handleChange}
              searchPayload={searchPayload}
              searchRule={BondSuggestionSearchRule.NameAndIsin}
            />
          </Grid>
        </Grid>
      </Grid>
    </ShellGap>
  );
};
