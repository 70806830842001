import { IsBondSite } from 'constant/site';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, createStyles, makeStyles, Tab, Tabs, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import WebCACertContainer from 'site/bond/component/WebCA/WebCACertContainer';
import SellDialogContainer from 'site/bond/component/page/Sell/SellDialogContainer';
import DialogMessage from 'site/bond/component/dialog/DialogMessage';
import DataType from 'constant/datatype';
import { BOND_FIELDS } from 'config/field';
import createBondCodeCell from 'site/management/component/page/bond/Account/Inventory/BondCodeCell';
import Immutable, { List, Map } from 'immutable';
import STATUS_CODE from 'constant/StatusCode';
import ElectronicSellTradingDisableDialog from 'site/bond/component/dialog/ElectronicSellTradingDisableDialog';
import SellPriceIsOutdateDailog from 'site/bond/component/dialog/SellPriceIsOutdateDailog';
import SocketOfflinePeriodDialog from 'site/bond/component/dialog/SocketOfflinePeriodDialog';
import ComplexTable from 'component/table/ComplexTable';
import palette from 'style/palette';
import { fade } from '@material-ui/core/styles';
import { Cell } from 'fixed-data-table-2';
import { InventoryTradable } from '../../../../../../../enum/InventoryTradable';

const Category = {
  all: '全部',
  bond: '海外債',
  sn: '結構債',
};

const InventoryNoneTradableText = {
  [InventoryTradable.PriceOutDate]: '今日因當地市場休市，未開放交易',
  [InventoryTradable.UnableElectronicTrading]: '限人工交易，請致電您所屬營業員或理財專員',
  [InventoryTradable.HadBeRedeem]: '今日已委託賣出',
  [InventoryTradable.NotInTradeDate]: '今日未開放交易，請於下一營業日下單',
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderColor: palette.green,
      color: palette.green,
      paddingTop: 0,
      paddingBottom: 0,
      '&:hover': {
        borderColor: palette.green,
        backgroundColor: fade(palette.green, 0.078),
      },
    },
    cell: {
      margin: '0 auto',
    },
  }),
);

interface SellButtonCell {
  data: List<Map<string, string>>;
  rowIndex?: number;
  closeTime: boolean;
  closeMessage: string;
}

const SellButtonCell: FC<SellButtonCell> = ({ data, rowIndex, closeTime, closeMessage }) => {
  const classes = useStyles();
  const [open, setopen] = useState(false);
  const [showCloseMessage, setShowCloseMessage] = useState(false);
  // 如果外部傳遞為不可交易，表示所有庫存皆不可交易
  // 如此之外，則檢查單檔可不可交易
  const tradable: InventoryTradable = data.getIn([rowIndex, 'tradable']);

  function handleClick() {
    if (closeTime) setShowCloseMessage(true);
    else setopen(true);
  }

  return (
    <Cell className={classes.cell}>
      {tradable === InventoryTradable.Yes ? (
        <Button variant="outlined" color="primary" size="small" className={classes.root} onClick={handleClick}>
          賣出
        </Button>
      ) : (
        <div>
          <Tooltip title={InventoryNoneTradableText[tradable]}>
            <span>
              <Button variant="outlined" color="primary" size="small" disabled className={classes.root}>
                賣出
              </Button>
            </span>
          </Tooltip>
        </div>
      )}
      {open && (
        <WebCACertContainer onFailed={() => setopen(false)}>
          <SellDialogContainer orderNumber={data.getIn([rowIndex, 'orderNumber'])} onClose={() => setopen(false)} />
        </WebCACertContainer>
      )}
      <DialogMessage
        onClose={() => setShowCloseMessage(false)}
        open={showCloseMessage}
        title="系統訊息"
        variant="default"
      >
        {closeMessage}
      </DialogMessage>
    </Cell>
  );
};

const createRedeemComponent = (closeTime: boolean, closeMessage: string) =>
  function GetRedeemComponent(data) {
    return IsBondSite ? <SellButtonCell data={data} closeTime={closeTime} closeMessage={closeMessage} /> : <Cell />;
  };
const columns = [
  {
    header: '動作',
    columnKey: 'redeem',
    dataType: DataType.Action,
    sortable: false,
  },
  BOND_FIELDS.ISIN,
  { ...BOND_FIELDS.BOND_CODE, cellRender: createBondCodeCell, width: 150 },
  BOND_FIELDS.COUPON,
  BOND_FIELDS.MATURITY_DATE,
  BOND_FIELDS.CONFIRM_DATE,
  BOND_FIELDS.CONFIRM_PRICE,
  BOND_FIELDS.CURRENCY,
  { ...BOND_FIELDS.FACE_VALUE, header: '持有面額' },
  //{ header: '可用庫存', columnKey: 'availableStock', dataType: DataType.Number },
  { ...BOND_FIELDS.COST, header: '持有面額成本' },
  { ...BOND_FIELDS.BuyCleanPrice, header: '持有面額買進前手息' },
  BOND_FIELDS.REF_PRICE_DATE,
  BOND_FIELDS.REF_PRICE,
  BOND_FIELDS.MARKET_VALUE,
  { ...BOND_FIELDS.SellCleanPrice, header: '預估賣出前手息' },
  BOND_FIELDS.EXPIRY_PRICE,
  { ...BOND_FIELDS.TOTAL_INTEREST, header: '持有面額累積債息' },
  BOND_FIELDS.PROFIT,
  BOND_FIELDS.ROI,
  BOND_FIELDS.PROFIT_WITH_INT,
  BOND_FIELDS.ROI_WITH_INT,
  //BOND_FIELDS.RATING,
  BOND_FIELDS.RATINGBBG,
];

const summaryColumns = [
  BOND_FIELDS.CURRENCY,
  { ...BOND_FIELDS.FACE_VALUE, header: '持有面額' },
  BOND_FIELDS.COST,
  BOND_FIELDS.MARKET_VALUE,
  BOND_FIELDS.TOTAL_INTEREST,
  { ...BOND_FIELDS.PROFIT, header: '總損益(不含債息)' },
  { ...BOND_FIELDS.ROI, header: '總報酬率%(不含債息)' },
  { ...BOND_FIELDS.PROFIT_WITH_INT, header: '總損益(含債息)' },
  { ...BOND_FIELDS.ROI_WITH_INT, header: '總報酬率%(含債息)' },
];

interface InventoryTableProps {
  data?: {
    result: {}[];
    statusCode: number;
    status: string;
  };
  onLoaded?: (data: object | string) => void;
}

export const InventoryTable: FC<InventoryTableProps> = ({
  data = { result: [], statusCode: -1, status },
  onLoaded,
}) => {
  const [tab, setTab] = useState(Object.keys(Category)[0]);
  //const idata = useMemo(() => Immutable.fromJS(data).getIn(['result'], []), [data]);
  const [detail, setDetail] = useState(Immutable.List());
  const [summary, setSummary] = useState(Immutable.List());
  const theme = useTheme();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('xs'));

  columns[0].cellRender = createRedeemComponent(data.statusCode === STATUS_CODE.TRADE_DAY_BUT_CLOSE_TIME, data.status);

  useEffect(() => {
    let detailData: any[];
    let detailTmp: any = [];
    let summaryData: any;
    if (tab === 'all') {
      detailData = data['result'][0]['detail'];
      summaryData = data['result'][0]['summary'];
    } else if (tab === 'bond') {
      for (let i = 0; i < data['result'][0]['detail'].length; i++) {
        if (data['result'][0]['detail'][i]['isBondProduct'] == true) {
          detailTmp.push(data['result'][0]['detail'][i]);
        }
      }
      detailData = detailTmp;
      summaryData = data['result'][0]['summaryBond'];
    } else {
      for (let i = 0; i < data['result'][0]['detail'].length; i++) {
        if (data['result'][0]['detail'][i]['isBondProduct'] == false) {
          detailTmp.push(data['result'][0]['detail'][i]);
        }
      }
      detailData = detailTmp;
      summaryData = data['result'][0]['summarySN'];
    }

    if (onLoaded) {
      onLoaded({
        columns: columns,
        data: detailData,
      });
    }

    let immDetail = Immutable.fromJS(detailData);
    let immSummary = Immutable.fromJS(summaryData);
    setDetail(immDetail);
    setSummary(immSummary);
  }, [data, onLoaded, tab]);

  return (
    <>
      <ElectronicSellTradingDisableDialog statusCode={data.statusCode} />
      <SellPriceIsOutdateDailog statusCode={data.statusCode} />
      <SocketOfflinePeriodDialog statusCode={data.statusCode} />

      <Box mb={2}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          onChange={(e, v): void => {
            setTab(v);
          }}
        >
          {Object.entries(Category).map(
            ([k, v]): JSX.Element => (
              <Tab key={k} label={v} value={k} />
            ),
          )}
        </Tabs>
        <ComplexTable data={detail} columns={columns} fixedLen={isMobileWidth ? 1 : 2} />
      </Box>
      <ComplexTable data={summary} columns={summaryColumns} />
    </>
  );
};
