import React from 'react';
import DialogMessage from './DialogMessage';

export interface StatusCodeCheckDialogWrapperProps {
  statusCode: number;
  status?: string;
}

interface StatusCodeCheckDialogProps extends StatusCodeCheckDialogWrapperProps {
  validStatusCode: number;
  warning: string | React.ReactElement;
}

export default class StatusCodeCheckDialog extends React.Component<StatusCodeCheckDialogProps> {
  public state = { open: false };

  public constructor(props: StatusCodeCheckDialogProps) {
    super(props);

    this.state = {
      open: process.env['REACT_APP_SITE'] !== 'management' && props.statusCode === props.validStatusCode,
    };
  }

  public render() {
    return (
      <DialogMessage
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        title="系統訊息"
        variant="warning"
      >
        {this.props.warning}
      </DialogMessage>
    );
  }
}
