import { Box, createStyles, DialogContent, Divider, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { EditableStepDialogProps } from 'site/bond/component/dialog/EditableDialog';
import { EditableDialogActions } from 'site/bond/component/page/WatchList/EditableDialogActions';

interface RenameWatchListDialogContentProps extends Omit<EditableStepDialogProps, 'onConfirm'> {
  name: string;
  onConfirm: (newName: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      fontWeight: 'normal',
    },
    textBold: {
      fontWeight: 'bold',
    },
    action: {
      justifyContent: 'center',
    },
  }),
);

const RenameWatchListDialogContent: FC<RenameWatchListDialogContentProps> = ({
  onClose,
  name,
  onConfirm,
  isMobileWidth,
}) => {
  const classes = useStyles();
  const [newName, setNewName] = useState(name);
  const [textFieldProps, setTextFieldProps] = useState({
    error: false,
    helperText: '',
  });

  const handleSubmit = () => {
    onConfirm(newName);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length > 25) {
      setTextFieldProps({ error: true, helperText: '輸入內容不得超過25個字' });
      return;
    }

    setTextFieldProps({ error: false, helperText: '' });
    setNewName(value);
  };

  return (
    <>
      <DialogContent>
        <Box my="2px">
          <Typography display="block" variant="h6" gutterBottom className={classes.textBold} component="span">
            重新命名
          </Typography>
          <Box my={2}>
            <TextField
              label="觀察名單顯示名稱"
              {...textFieldProps}
              value={newName}
              onChange={handleChange}
              fullWidth
              autoFocus
            />
          </Box>
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
      </DialogContent>
      <EditableDialogActions
        onClose={onClose}
        fullWidth={isMobileWidth}
        onSubmit={handleSubmit}
        disableSubmitButton={textFieldProps.error}
      />
    </>
  );
};

export default RenameWatchListDialogContent;
